import {GoMortarBoard} from 'react-icons/go'
import {FaUserGraduate} from 'react-icons/fa'
import {MdNoteAlt} from 'react-icons/md'

let AcademicData = [
    {
        title:"Undergraduate Programmes",
        icon:<GoMortarBoard size={26} />,
        link:'/undergraduate',
        direction:"left",
        delay:1500,
        Desc:"Our undergraduate programmes are intensive educational pathway designed to train aspiring healthcare professionals in the foundational knowledge and skills required to embark on a career in medical programmes. This program typically spans a duration of five to six years, depending on the degree programme",
       
    },
    {
        title:"Postgraduate Programmes",
        icon:<FaUserGraduate size={26}  />,
        link:'/postgraduate',
        direction:"right",
        delay:1000,
        Desc:"Lorem ipsum dolor sit amet consectetur ad piscing elit ut elit tellus luctus nec dolor sit amet consec teturul",
       
    },
    {
        title:"Distance Learning",
        icon:<MdNoteAlt size={26}  />,
        link:'/distance-learning',
        direction:"right",
        delay:500,
        Desc:"Lorem ipsum dolor sit amet consectetur ad piscing elit ut elit tellus luctus nec dolor sit amet consec teturul",
       
    },
]

export default AcademicData