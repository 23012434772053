import React from 'react'
import Layout1 from '../../../components/College/Layout1'
import Faculty_Staff_Layout from '../../../components/College/Faculty_Staff_Layout'
import { bmsNonAcademicStaff } from '../../../utilities/Non-Academic-Staff'
import { academicStaff } from '../../../utilities/Academic-Staff'
import { useEffect } from 'react'
export default function Dentistry_Staff() {

  useEffect(()=>{
    document.title="College of Health Sciences - Faculty of Dentistry Staff, Obafemi Awolwo University, Ile-Ife "
    window.scrollTo({
      top:0
    })


 

  },[])
  return (
    <div>
    <Layout1 title="Faculty of Dentistry Staff" text="Staff">
    <Faculty_Staff_Layout
              academicStaff={academicStaff.den}
              nonAcademicStaff={[]}
              faculty="Dentistry"
              text="Dentistry"
          />
    </Layout1>
  </div>
  )
}
