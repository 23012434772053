import React, {useState} from 'react'
import { BiChevronRight } from 'react-icons/bi'
import { Link,useLocation } from 'react-router-dom'
import CollegeDesktopTabletSublink2 from './CollegeDesktopTabletSublink2'

export default function CollegeDesktopTabletSublink1({submenu}) {
  let para = useLocation()

    return (
    <div className="submenu_wrapper">
      <div>
      <ul className='sublinks'>
        
        {
            submenu.map((item,index)=>{
                return(
                <React.Fragment key={index}>
                {
                    item.submenu? <li className='has_sublink_2'>
                    <Link to={item.path}><p>{item.link}<BiChevronRight size={25} /></p></Link>
                    <CollegeDesktopTabletSublink2 submenu={item.submenu} />
                    </li>:
                    <li>
                      {
                        
                        item.external? <a href={item.external} target="_blank">{item.link}</a>:
                      <Link to={item.path} className={para.pathname==item.path? 'activ':'' }>{item.link}</Link>
                    }
                    </li>
               
            }
               </React.Fragment>
                )
                
            })
          
        }
    </ul>
      </div>

    </div>
  )
}

{/* <li><Link className={para.pathname==item.path? 'activ':'' }>{item.link}</Link></li>)  */}