const  Links = [
    {
        tag:'Event',
        to:'#'
    },
    {
        tag:'Study',
        to:'#'
    },
    {
        tag:'Inaugural',
        to:'#'
    },
    {
        tag:'Campus',
        to:'#'
    }
]

export default Links