let NavLinks = [
    {
        link:"Home",
        path:"/"
    },
    {
        link:"About",
        // path:"",
        submenu:[
            {
                link:"History About The College",
                path:"/history"
            },
            {
                link:"Facts About The College",
                path:"/fact"
            },
            // {
            //     link:"Our Anthem",
            //     path:"/anthem"
            // },
            // {
            //     link:"Awards",
            //     path:"/award"
            // },
            {
                link:"Past Leaders",
                // path:"",
                submenu:[
                    {
                        link:"Provost",
                        path:"/past-provost"
                    },
                    // {
                    //     link:"Deputy Provost",
                    //     path:"/deptutyProvost"
                    // },
                    // {
                    //     link:"Director",
                    //     path:"/director"
                    // },
                    {
                        link:"Dean",
                        path:"/past-dean"
                    },
                    // {
                    //     link:"Head of Department",
                    //     path:"/hod"
                    // },
                    // {
                    //     link:"College Secretary's Office",
                    //     path:"/secretary"
                    // },
                ]
                
            }, 
        ]
  
    },
    {
        link:"Academics",
        // path:"/",
        submenu:[
            {
                link:"Academic Prizes",
                path:"/academic-prize"
            },
            // {
            //     link:"Academic Calendar",
            //     path:"/calendar"
            // },
            // {
            //     link:"Courses Offered",
            //     path:"/courses" 
            // },
            {
                link:"Education Perspective",
                path:"/education-perspective"
            },
            // {
            //     link:"Distance Learning Program",
            //     path:"/distance-learning"
                
            // }, 
        ]
  
    },
    {
        link:"Staff",
        // path:"/",
        submenu:[
            {
                link:"Faculty & Institute",
               
                submenu:[
                    {
                        link:"Basic Medical Sciences",
                        path:"/bms"
                    },
                    {
                        link:"Clinical Sciences",
                        path:"/cls"
                    },
                    {
                        link:"Dentistry",
                        path:"/den"
                    },
                    // {
                    //     link:"Institute of Public Health",
                    //     path:"/iph"
                    // },
                ]
            },
            // {
            //     link:"Provost's Office",
            //     // path:"/",
            //     submenu:[
            //         {
            //             link:"Medical Illustration",
            //             path:"/miu"
            //         },
            //         {
            //             link:"Biomedical Engineering",
            //             path:"/bme"
            //         },
            //         {
            //             link:"College Secretary's Office",
            //             path:"/chs-sec"
            //         },
            //         {
            //             link:"College Library",
            //             path:"/chs-lib"
            //         },
            //     ]
            // }
        ]
    },
    {
        link:"Student",
        // path:"/",
        submenu:[
            {
                link:"Stundents' Association",
                // path:"/",
                submenu:[
                    {
                        link:"IFUMSA",
                        path:"/ifumsa"
                    },
                    // {
                    //     link:"IFUDSA",
                    //     path:"/ifudsa"
                    // },
                    // {
                    //     link:"IFUTSA",
                    //     path:"/ifutsa"
                    // },
                    // {
                    //     link:"NUNSA",
                    //     path:"/nunsa"
                    // },
                ]
            },
            // {
            //     link:"Students' life",
            //     path:"/std-life"
            // },
            // {
            //     link:"Prospective Student",
            //     path:"/pros-std"
            // }
           
        ]
  
    },
    {
        link:"Health Journal",
        external:"https://nigerianhsjournal.tech/"
    },
    // {
    //     link:"Resources",
    //     // path:"/",
    //     submenu:[
    //         {
    //             link:"College Handbook",
    //             path:"/chs-handbook"
    //         },
    //         {
    //             link:"News Letter",
    //             path:"/news-letter"
    //         },
    //         {
    //             link:"College Journal",
    //             path:"/chs-journal"
    //         },
    //         {
    //             link:"Public Lecture",
    //             path:"/public-lecture"
    //         },
    //         {
    //             link:"Grillo Lecture",
    //             path:"/grillo-lecture"
    //         },
    //         {
    //             link:"Downloads",
    //             path:"/download"
    //         }
    //     ]
  
    // },
    {
        link:"Alumni",
        external:"https://chsalumni.oauife.edu.ng"
    },
    {
        link:"Quick Links",
        // path:"/",
        submenu:[
            {
                link:"Give CHS",
                path:"/donation"
            },
            {
                link:"Staff",
                // path:"",
                submenu:[
                    {
                        link:"Login",
                        path:"/sign-in"
                    },
                    // {
                    //     link:"Register",
                    //     path:"/sign-up"
                    // },
                    // {
                    //     link:"Recover Password",
                    //     path:"/pass-recovery"
                    // }
                  
                ]
                
            }, 
            
            // {
            //     link:"Gallery",
            //     path:"/gallery"
            // },
            // {
            //     link:"FAQs",
            //     path:"/faqs"
            // },
            // {
            //     link:"Privacy Policy",
            //     path:"/policy"
            // },
            {
                link:"OAU Website",
                external:"https://oauife.edu.ng"
            },
        ]
    }




]

export default NavLinks