import React from 'react'
import Layout1 from '../../../components/College/Layout1'

export default function College_Journal() {
  return (
    <div>
      <Layout1 title="College Journal" text="Journal">

      </Layout1>
    </div>
  )
}
