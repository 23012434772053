import React,{useState} from 'react'
import {IoIosCheckmark} from 'react-icons/io'
import AnimatedNumbers from "react-animated-numbers";
import { Link } from 'react-router-dom';
import {BsArrowRight} from 'react-icons/bs'
export default function AboutUs() {

  return (
    <div className='about_container'>
    <div className='about_wrapper container padding_1'>
            <div className="left_item">
                    <h3 className='heading_1'>About Us</h3>
                    {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. A rem voluptatem ratione ullam iusto nobis esse sint cumque eum atque numquam placeat, laborum error distinctio odio dolor illum excepturi illo!</p> */}
                    {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. A rem voluptatem ratione ullam iusto nobis esse sint cumque eum atque numquam placeat, laborum error distinctio odio dolor illum excepturi illo!</p> */}
                    <p>
                    {/* Welcome to the College of Health Sciences, OAU! Our Institution is committed to providing students with the knowledge and skills needed to become successful health professionals. We offer a wide range of courses and programs in the health sciences, from nursing to medical science. Our faculty and staff are dedicated to helping our students reach their potential and excel in their chosen field. We look forward to helping you achieve your educational goals. */}
                    Over the years, the College has enjoyed the service of senior faculty members who are committed to its core aims. The educational and administrative policy of the College fully embraces the traditional tripod of education, research and service with greater emphasis placed on service. To this end, faculty members have done more than just teaching. They have improved health care through novel roles as scholars and physicians. 

                    </p>
                    <div className="list_items_wrapper">
                    <div className="list_items left">
                      <ul>
                        <li><div><span><IoIosCheckmark size={26} /></span> Postgradute Programs</div></li>
                        <li><div><span><IoIosCheckmark size={26} /></span> Sports</div></li>
                        <li><div><span><IoIosCheckmark size={26} /></span> Alumni & Giving</div></li>
                        <li><div><span><IoIosCheckmark size={26} /></span> Workshop</div></li>
                        
                      </ul>
                    </div>
                    <div className="list_items right">
                      <ul>
                        <li><div><span><IoIosCheckmark size={26} /></span> Undergraduate Programs</div></li>
                        <li><div><span><IoIosCheckmark size={26} /></span> Global Students</div></li>
                        <li><div><span><IoIosCheckmark size={26} /></span> Leadership trainning</div></li>
                        <li><div><span><IoIosCheckmark size={26} /></span> Conferences</div></li>
                       
                      </ul>
                    </div>
                    </div>

                    <div className="counter_container">
                    <div className="counter_wrapper">
                        <div className="experience">
                            <div className="counter">
                            <AnimatedNumbers
        // includeComma
        animateToNumber={50}
        fontStyle={{ fontSize: '1.4rem' }}
        configs={(number, index) => {
          return { mass: 1, tension: 600 * (index + 1), friction: 140 };
        }}
      ></AnimatedNumbers>
                            
                            <span>+</span></div>
                            <div className="text">Years of Experience</div>
                        </div>
                        <div className="alumni">
                            <div className="counter">
                            <AnimatedNumbers
       
        animateToNumber={20}
        fontStyle={{ fontSize: '1.4rem' }}
        configs={(number, index) => {
          return { mass: 1, tension: 600 * (index + 1), friction: 140 };
        }}
      ></AnimatedNumbers>k<span>+</span></div>
                            <div className="text">Alumni</div>
                        </div>
                        <div className="alumni">
                            <div className="counter">
                            <AnimatedNumbers
       
        animateToNumber={3}
        fontStyle={{ fontSize: '1.4rem' }}
        configs={(number, index) => {
          return { mass: 1, tension: 600 * (index + 1), friction: 140 };
        }}
      ></AnimatedNumbers></div>
                            <div className="text">Faculties</div>
                        </div>
                        <div className="department">
                            <div className="counter">
                            <AnimatedNumbers
        
        animateToNumber={22}
        fontStyle={{ fontSize: '1.4rem' }}
        configs={(number, index) => {
          return { mass: 1, tension: 600 * (index + 1), friction: 140 };
        }}
      ></AnimatedNumbers>
                            </div>
                            <div className="text">Departments</div>
                        </div>
                    </div>

                    </div>

                    <div className="call_to_action_container">
                        <Link to="history"><p>Read More</p> <span><BsArrowRight /></span></Link>
                    </div>
                   
            </div>
            <div className="right_item">
                
            </div>
    </div>

    </div>
  )
}
