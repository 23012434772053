import React,{useEffect, useState} from 'react'
import { Link } from 'react-router-dom'
import {BsArrowRight} from 'react-icons/bs'
import AcademicData from '../../utilities/AcademicsSections'
import {Slide, Fade }from 'react-reveal';
export default function Academics() {
    let [fade1, setFade] = useState(false)
    let [srHeight, setSrHeight] = useState(0)
    console.log(AcademicData)
    useEffect(()=>{
            window.addEventListener('scroll',()=>{
                // let h = document.querySelector('.item_container').scrollTop
                let h = window.pageYOffset || (document.documentElement || document.body.parentNode || document.body).scrollTop
                // let h =window.pageYOffset + document.querySelector('.item_container').getBoundingClientRect().top
                    let scrollHeight = window.scrollY
                   
                    if(h >= 390){
                        
                        setFade(true)
                    }else{
                        setFade(false)
                    } 
                })
            window.removeEventListener('scroll',()=>{
                // let h = document.querySelector('.item_container').scrollTop
                let h = window.pageYOffset || (document.documentElement || document.body.parentNode || document.body).scrollTop
                // let h =window.pageYOffset + document.querySelector('.item_container').getBoundingClientRect().top
                    let scrollHeight = window.scrollY
                  
                    if(h >= 940){
                        
                        setFade(true)
                    }
                })
    },[])
  return (
    <div className='academic_container'>
    <div className='academic_wrapper container padding_1'>
            <h3>Programmes </h3>
            <p className='sub_heading'>

            We offer a comprehensive range of academic courses and resources to help students reach their full potential. 
            {/* Through our cutting-edge technology and experienced teaching staff, we strive to provide a quality education to all of our students. */}
            </p>
            {/* <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. ipsum dolor sit, amet consectetur adipisicing elit.</p> */}
            <div className="item_container">
            {
                AcademicData.map((item,index)=>{
                    return(
                        // <Fade left delay={item.delay}  when={fade1}>
            <div className="item_wrapper">
                <div className="title">
                    <h5><span>{item.icon}</span><Link to={item.link}>{item.title}</Link></h5>
                </div>
                <p style={{lineHeight:'2rem'}}>{item.Desc}</p>
                <div className="read_more">
                <Link to={item.link}>Read More <span><BsArrowRight /></span></Link>

                </div>
            </div>
                        // </Fade>
           
                    )
                })
            }
            </div>
           
            
    
    </div>

    </div>
  )
}
