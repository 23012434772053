import React,{useEffect} from 'react'
import Banner from '../../../components/College/Banner'
import Footer from '../../../components/College/Footer'
import BannerImage from '../../../assets/images/banner/banner1.jpg'

import RightBarSearchBox from '../../../components/College/RightBarSearchBox'
import RightBarCategories from '../../../components/College/RightBarCategories'
import PopularTags from '../../../components/College/PopularTags'
import {RightData, LeftData} from '../../../utilities/PastProvostContent'

console.log(Object.assign(LeftData))

function PastDean(){
  return(<>
    <h3 className='heading'>Past Dean of the then Faculty of Health Sciences</h3>
    <div className='past_provost_wrapper'>
    {
                   LeftData.map(item=>item.status&& <div className="item">
                   <div className="image_wrapper">
                         <img src={item.image} alt="" />
                   </div>
                   <div className="name">
                       <p>{item.name}</p>
                       {item.status&& <p>{item.status}</p>}
                       <p>({item.Year})</p>
                   </div>
               </div>)
   
    }
  </div>
  </>
  )
}




export default function Past_Provost() {
    useEffect(()=>{
        document.title="College of Health Sciences - Past Provost, Obafemi Awolwo University, Ile-Ife "
        window.scrollTo({
          top:0
        })
      },[])
     console.log(LeftData)
  return (
    <div className='past_provost_container chs_top'>
        <Banner image={BannerImage} text="Provost" title="PAST PROVOST"/>

    <div className=' wrapper'>
    <div className="content_container">
            <div className="content_wrapper container">

            <div className="left_bar">

              <h3>Past Provost of College of Health Sciences</h3>
              <div className="past_provost_wrapper">

                {
                    LeftData.map((item, index)=>!item.status&&
                      
                        <div className="item">
                      <div className="image_wrapper">
                            <img src={item.image} alt="" />
                      </div>
                      <div className="name">
                          <p>{item.name}</p>
                          {item.status&& <p>{item.status}</p>}
                          <p>({item.Year})</p>
                      </div>
                  </div>
                      
                    )
                }
                 
                  {/* <div className="item">

                  </div>
                  <div className="item">

                  </div>
                  <div className="item">

                  </div>
                  <div className="item">

                  </div> */}
              </div>
                    <PastDean />
            </div>
             
                  <div className="right_bar">
                      <div className="right_bar_wrapper">
                          <RightBarSearchBox />
                          <RightBarCategories title="Related Links" links={RightData} />
                          <PopularTags link={RightData[RightData.length-1].tags}   />
                      </div>

                  </div>
            </div>
                
            </div>
    </div>
      <Footer />
  </div>
  )
}
