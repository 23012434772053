import React,{useEffect} from 'react'
import { Link } from 'react-router-dom'


export default function Banner({title,text,image}) {
   
  return (
    <div className='chs_banner_container'  style={{backgroundImage:`url(${image})`}}>
            <div className="chs_banner_wrapper">
                {/* <h5>{title}</h5> */}
                <h5>{title.toUpperCase()}</h5>
                <p>Home / <Link to="/"><span>{text}</span></Link></p>
            </div>

    </div>
  )
} 
