import React,{useEffect} from 'react'
import Layout1 from '../../../components/College/Layout1'

export default function News() {
  useEffect(()=>{
    document.title="College of Health Sciences - News, Obafemi Awolwo University, Ile-Ife "
    window.scrollTo({
      top:0
    })
  },[])
  return (
    <div>
      <Layout1 
      
      title="News"
      text="News"



      
      >
        Left
      </Layout1>
      </div>
  )
}
