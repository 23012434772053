const Links = [
    {
        title:'Our Campus',
        link:[
            {
                name:'Academic',
                path:'/'
            },
            {
                name:'Events',
                path:'/'
            },
            {
                name:'Campus Life',
                path:'/'
            },
            {
                name:'Facility Services',
                path:'/'
            },
        ]
    },
    {
        title:'Featured Links',
        link:[
            {
                name:'FAQs',
                path:'/'
            },
            {
                name:'Gallery',
                path:'/'
            },
            {
                name:'Latest News',
                path:'/'
            },
            {
                name:'E-Library',
                path:'/'
            },
        ]
    },
    {
        title:'Unit',
        link:[
            {
                name:'Medical Illustration',
                path:'/'
            },
            {
                name:'Biomedical Engineering',
                path:'/'
            },
            {
                name:'College Libray',
                path:'/'
            },
            {
                name:'Journal',
                path:'/'
            },
            {
                name:'Transcript',
                path:'/'
            },
        ]
    }
]

export default Links