import React,{useEffect} from 'react'




export default function StaffProfileTeaching({data}) {


    
function Undergraduate(){
    return(
            <ul>
                {
                    Object.values(data)[0].map((item,index)=> <li key={index} >
                        <h3>{item.title}</h3>
                        <p>{item.description}</p>
                    </li>)
                }
            </ul>
    )
}
function Postgraduate(){
    return(
            <ul>
                {
                    Object.values(data)[1].map((item, index)=> <li key={index}>
                        <h3>{item.title}</h3>
                        <p>{item.description}</p>
                    </li>)
                }
            </ul>
    )
}

    useEffect(()=>{
        let btns = [...document.querySelectorAll('.btn>li')]
        let items = [...document.querySelectorAll('.graduate_type_wrapper>.item')]

       let btnIndex =  btns.findIndex(item=>item.classList.contains('.active'))
       let itemsIndex =  items.findIndex(item=>item.classList.contains('.active'))
       if(btnIndex < 0 && itemsIndex < 0){
        btnIndex = 0;
        itemsIndex = 0;
        btns[btnIndex].classList.add('active')
        items[itemsIndex].classList.add('active')
       }

       btns.forEach(btn=>{
            btn.addEventListener('click', (e)=>{
                let isActive = e.currentTarget.classList.contains('active')
                // console.log(isActive)
            if(!isActive){
                btns.find((el)=>el.classList.remove('active'))
                e.currentTarget.classList.add('active')
                // console.log("Yes Is active")
            }

            let isMatched =  e.currentTarget.textContent.slice(0,5).trim()
                if(isMatched == 'Post'){
                    items[0].classList.remove('active')
                    items[1].classList.add('active')
                 }
                    if(isMatched == 'Under'){
                        items[1].classList.remove('active')
                    items[0].classList.add('active')
                        
                }
                // console.log(e.currentTarget)
            })
       })
       
    }, [])
  return (
    <div className='staff_teaching_container'>
    <div className='staff_teaching_wrapper'>
            <ul className="btn">
                <li>Under Graduate</li>
                <li>Post Graduate</li>
            </ul>


        <div className="graduate_type_wrapper">
            <div className="item undergraduate ">

                <Undergraduate />
                
            </div>
            <div className="item postgraduate ">
                <Postgraduate />
            </div>
        </div>
           
    </div>

    </div>
  )
}
