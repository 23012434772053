import React,{useState} from 'react'
import ButtonSlideBg from './ButtonSlideBg'
import MiniHeaderBar from './MiniHeaderBar'
export default function SearchBox() {
let [val, setVal] = useState()
  let handleOnchange = (e)=>{
    let {value} = e.target
    setVal(value)
  }

  if(val == undefined){
    setVal('')
  }
  console.log(val)
  return (
    <div className='search search_wrapper'>
           <MiniHeaderBar text="Search" />
            <div className="input_wrapper">
                    <input type="text" value={val} onChange={handleOnchange} placeholder='Type Here...' />
                    <ButtonSlideBg text="Search" to={`/search-result/${val}`}  className="search_btn"/>
            </div>
    </div>
  )
}
