import React, {useEffect, useRef} from 'react'
import ScrollReveal from   'scrollreveal'
import {FiAward} from 'react-icons/fi'
export default function StaffAwardHonour({data}) {
    let el = useRef()
    useEffect(()=>{
        
        // let item = document.querySelector('.box')
        // ScrollReveal.reveal(el.current, {reset:true, interval:16})
    })


  return (
    <div className='staff_award_honour_container'>
       <h3>Award & Honour</h3> 
    <div className='staff_award_honour_wrapper'>

        {
            data.map((item, index)=>
            <div className="box item" key={index}>
                <span><FiAward /></span>
              {item.totalAward?<p>{item.totalAward}</p>: item.totalHonour? <p>{item.totalHonour}</p>: <p>{item.totalOther}</p>}  {/* <p>{item.totalAwrd</p> */}
           {item.totalAward? <h5>Total Award</h5>: item.totalHonour?<h5>Total Honour</h5>: <h5>Others</h5> } 
        </div>)
        }
       
    </div>

    </div>
  )
}
