

 function btnToggler(btns, contents){
   
    let findBtnIndex = btns.findIndex(el=>el.classList.contains('active'));

    if(findBtnIndex< 0){
        let findBtnIndex = 0
        btns[findBtnIndex].classList.add('active')
    }

    let findContentsIndex = contents.findIndex(el=>el.classList.contains('active'))
   
    if(findContentsIndex< 0){
        let findContentsIndex = 0
        contents[findContentsIndex].classList.add('active')
    }
// let check;
    btns.forEach(btn=>{
        btn.addEventListener('click',(e)=>{
            let check = btns.find(item=>item.classList.contains('active'))
            let name = e.target.textContent
            if(check){
                check.classList.remove('active')
                e.target.classList.add('active')
              }

              if(name == 'Basic Sciences' && e.target.classList.contains('active')){
              
                let check = contents.find(item=>item.classList.contains('active'))
                  check.classList.remove('active')
                  contents[0].classList.add('active')
        
               
              }
              if(name == 'Clinical Sciences' && e.target.classList.contains('active')){
                let check =contents.find(item=>item.classList.contains('active'))
                check.classList.remove('active')
                contents[1].classList.add('active')
              }
              if(name == 'Dentistry' && e.target.classList.contains('active')){
                let check =contents.find(item=>item.classList.contains('active'))
                check.classList.remove('active')
                contents[2].classList.add('active')
              }
        })
      
    })
}



function staffBtnToggler(btns, contents){
   
  let findBtnIndex = btns.findIndex(el=>el.classList.contains('active'));

  if(findBtnIndex< 0){
      let findBtnIndex = 0
      btns[findBtnIndex].classList.add('active')
  }

  let findContentsIndex = contents.findIndex(el=>el.classList.contains('active'))
 
  if(findContentsIndex< 0){
      let findContentsIndex = 0
      contents[findContentsIndex].classList.add('active')
  }
// let check;
  btns.forEach(btn=>{
      btn.addEventListener('click',(e)=>{
          let check = btns.find(item=>item.classList.contains('active'))
          let name = e.target.textContent
          if(check){
              check.classList.remove('active')
              e.target.classList.add('active')
            }

            if(name == 'Academic' && e.target.classList.contains('active')){
            
              let check =contents.find(item=>item.classList.contains('active'))
                check.classList.remove('active')
                contents[0].classList.add('active')
      
             
            }
            if(name == 'Non-academic' && e.target.classList.contains('active')){
              let check =contents.find(item=>item.classList.contains('active'))
              check.classList.remove('active')
              contents[1].classList.add('active')
            }
           
      })
    
  })
}


export {btnToggler,staffBtnToggler}