import React,{useEffect} from 'react'
import { useParams } from 'react-router-dom'
import Footer from '../../../components/College/Footer'
import StaffProfileBanner from '../../../components/College/StaffProfileBanner'
import Data from '../../../utilities/StaffGallery'
import sample from '../../../assets/images/staff gallery/sample.jpg'
import {FcAbout} from 'react-icons/fc'
import { SlSocialFacebook } from 'react-icons/sl'
import { SiResearchgate } from 'react-icons/si'
import { RxTwitterLogo } from 'react-icons/rx'
import { GoMortarBoard } from 'react-icons/go'
import { GiArchiveResearch, GiTeacher } from 'react-icons/gi'
import { MdArticle, MdLocationPin } from 'react-icons/md'
import { FaAward } from 'react-icons/fa'
import { RiRefund2Line} from 'react-icons/ri'

import RemoveDesignation from '../../../utilities/RemoveDesig'
import Timeline from '../../../components/College/timeline'
import StaffResearch from '../../../components/College/StaffResearch'
import Publication from '../../../components/College/Publication'
import StaffAwardHonour from '../../../components/College/StaffAwardHonour'
import StaffProfileTeaching from '../../../components/College/StaffProfileTeaching'
import StaffContact from '../../../components/College/StaffContact'


export default function Staff_Profile() {
   let {id} = useParams()

   let data = Data.filter(item=>item.link == id)
   useEffect(()=>{
    document.title=`College of Health Sciences - ${id}, Obafemi Awolwo University, Ile-Ife `
    window.scrollTo({
      top:0
    })
  },[])
 
  let [record] = data

  let name = RemoveDesignation(record.name)
  
  return (
    <div className='staff_profile_page_container chs_top'>
        <div className="staff_profile_page_wrapper">
          <StaffProfileBanner image = {record.image} dept={record.dept} name = {record.name}/>

        
        
      

        <div className="staff_profile_content_wrapper container">
            <div className="about_me_wrapper">
                <div className="content_1">
                    <div className="item">
                        <img src={record.altImg} alt="" />
                    </div>
                    <div className="item">
                        <div className="heading">
                            <span><FcAbout /></span>
                            <span>About Me</span>
                        </div>
                      
                        <h3>{name}</h3>
                        <p className='dept'>{record.dept}</p>
                        
                        
                        
                        <div className='social_media'>
                            <a>
                              <SlSocialFacebook  />
                            </a>
                            <a>
                              <SiResearchgate  />
                            </a>
                            <a>
                              <RxTwitterLogo />
                            </a>
                      </div>

                      
                        
                        
                        <p className='text'>
                          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Similique minus aliquid omnis quidem autem exercitationem facere iste ullam, ad nostrum, optio voluptas atque animi reprehenderit, reiciendis assumenda tenetur illum beatae.
                        </p>
                        <div className="download_cv">
                            <a >Download CV</a>
                      </div>

                      <div className="latest_pub">
                        <h5>Latest Publications:</h5>
                        <ul>
                          <li><a>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla  qui officia deserunt mollit anim id est laborum.</a></li>
                          <li><a>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla  qui officia deserunt mollit anim id est laborum.</a></li>
                          <li><a>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla  qui officia deserunt mollit anim id est laborum.</a></li>
                        </ul>
                      </div>
                      {/* <h3>About Me</h3> */}
                     
                    </div>
                </div>

                <div className="content_2 content">
                <div className="heading">
                            <span><GoMortarBoard /></span>
                            <span>Education</span>
                  </div>

                  <div>
                 
               <div className="item timeline">
               
                 
                     <Timeline data={record.experience} />
                 
               </div>
                    </div>

                  

                   
                </div>

                <div className="content_3 content">
                <div className="heading">
                            <span><GiArchiveResearch /></span>
                            <span>Research Intrest</span>
                            
                  </div>

                    <StaffResearch data={record.research} />

                  </div>

                  
                {record.publication&&   <div className="content_4 content">
                <div className="heading">
                            <span><MdArticle /></span>
                            <span>Publications</span>
                  </div>

                    <Publication data={record.publication}/>

                  </div>}
                


                  {record.award&&  <div className="content_5 content">
                {/* <div className="heading">
                            <span><FaAward /></span>
                            <span>Awards and Honour</span>
                  </div> */}

                    
                  <StaffAwardHonour  data={record.award}/>
                  </div> }



                {/* <div className="content_6 content">
                <div className="heading">
                            <span><RiRefund2Line /></span>
                            <span>Research Grant and Funding</span>
                  </div>

                    <StaffResearch data={record.research} />

                  </div> */}
         {record.teaching&&        <div className="content_7 content">
                <div className="heading">
                            <span><GiTeacher /></span>
                            <span>Teaching</span>
                  </div>

                    {/* <StaffResearch data={record.research} /> */}
                      <StaffProfileTeaching data={record.teaching} />
                  </div>
}
                <div className="content_8 content">
                <div className="heading">
                            <span><MdLocationPin /></span>
                            <span>Contact</span>
                  </div>

                    <StaffContact phone={record.phone} />

                  </div>
            </div>
        </div>
        </div>
        
        <Footer />
        </div>
  )
}
