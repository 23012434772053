import React from 'react'
import Logo from '../../../assets/images/oau-logo.png'
import { Link } from 'react-router-dom'
export default function Login() {
  return (
    <div className="login_page_container">
    <div className="wrapper_page">
        <div className="inner">
            <div className="banner item right">
                <div className="logo_wrapper">
                        <div className="image">
                            <img src={Logo} alt="" />
                        </div>
                        <div className="content">
                            <h4>College of Health Sciences</h4>
                            <p>Obafemi Awolowo University,Ile-Ife</p>
                        </div>
                </div>
            </div> 

            <div className="form_wrapper item left">
            <div className="form_inner">

                <div className="content">

                    <h4>Login Page</h4>
                </div>

                <form action="">
                    <div className="input_field">
                        <input type="text" placeholder='Email' />
                    </div>
                    <div className="input_field">
                        <input type="text" placeholder='Password' />
                    </div>
                    <div className="content_2">
                        <div className="remember_me">
                            <input type="checkbox"  />
                            <label htmlFor="">Remember me</label>
                        </div>
                        <div className="password">
                            <Link>Forgotten password?</Link>
                        </div>
                    </div>
                    <div className="btn_wrapper">
                        <button type='submit'>Login</button>
                    </div>

                  
                    <div className="copyright">
                        <p>&copy; 2023 College of Health Sciences</p>
                    </div>
                </form>
                
            </div>

            </div>
        </div>
    </div>
</div>
  )
}
