import React,{useState} from 'react'

export default function Harmbugger({toggle,handleToggle}) {

  return (
    <div className={`chs_hambugger_navbar ${toggle?'active':''}`} onClick={handleToggle}>
        <span></span>
        <span></span>
        <span></span>
    </div>
  )
}
