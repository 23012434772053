import React from 'react'
import ButtonSlideBg from './ButtonSlideBg'
import {IoMdCall} from 'react-icons/io'
export default function StaffContact({phone}) {
  return (
    <div className='staff_contact_container'>
    <div className='staff_contact_wrapper'>
            <div className="right item">
                <div className='inner_wrapper'>
                <h3>Contact Me</h3>
                <form>
                    <input type="text" placeholder='Enter your name'  />
                    <input type="email" placeholder='Enter your valid email'  />

                    <textarea placeholder='Enter your Message here' name="message" id="" ></textarea>
                      <ButtonSlideBg text="Submit" />
                </form> 
                </div>
            
        
            </div>
            <div className="left item">
                <div>
                    <div className='content'>
                        <h3>
                            <span><IoMdCall /></span> Call Me
                        </h3>
                        <p>{phone}</p>
                    </div>
                    <div className='content'>
                        <h3>
                            <span><IoMdCall /></span> Location
                        </h3>
                        <p>Room 4 wing 2 Medicine Department</p>
                    </div>
                    <div className='content'>
                        <h3>
                            <span><IoMdCall /></span> Visitation Hour
                        </h3>
                        <p>Mon - Fri 10am - 4pm</p>
                    </div>
                </div>
               
            </div>
    </div>

    </div>
  )
}
