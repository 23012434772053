import React, {useEffect} from 'react'
import BannerImage from '../../../assets/images/oau-building.jpg'
import Banner from '../../../components/College/Banner'
import Footer from '../../../components/College/Footer'

export default function ViewAllStaff() {
      useEffect(()=>{
        document.title="College of Health Sciences - All Academic Staffs, Obafemi Awolwo University, Ile-Ife "
        window.scrollTo({
          top:0
        })
    })
  return (
    <div className='all_staff_container chs_top'>
        <Banner image={BannerImage} text="Staff" title="ACADEMIC STAFF"/>
        <div className="wrapper">

        </div>
    <Footer />
    </div>
  )
}
