import React,{useEffect} from 'react'

export default function FacultyStaffPagination({items}) {
   
    useEffect(()=>{
        let currentPage = 1;
        let limit = 6;
        let AcademicStaffItems = document.querySelectorAll('.academic > .items');
        let NonAcademicStaffItems = document.querySelectorAll('.non_academic > .items');

        function loadAcademicItem(){
            let startPage = limit * (currentPage - 1);
            let endPage = limit * currentPage - 1;
            AcademicStaffItems.forEach((item, key)=>{
                if(key>= startPage && key<= endPage){
                    item.style.display = "block"
                }else{
                    item.style.display = 'none';
                }
            })
            AcademicListPage()
         }
         loadAcademicItem()

         function AcademicListPage(){
            let totalPages = Math.ceil(items.academic.length / limit);
            document.querySelector('.btn_wrapper >ul').innerHTML = '';
          
            if(currentPage !=1){
                let prev = document.createElement('li');
                prev.innerText = 'Prev';
                // prev.setAttribute('onclick', 'pageController('+ (currentPage - 1) + ')');
                prev.addEventListener('click',()=>{
                    AcademicpageController(currentPage - 1)
                })
                document.querySelector('.btn_wrapper >ul').appendChild(prev);
            }
           
            for(let i = 1; i<= totalPages; i++){
                let newPage = document.createElement('li');
                newPage.innerText = i;
                if(i == currentPage){
                    newPage.classList.add('active')
                }
                // newPage.setAttribute('onclick', "AcademicpageController("+ i +")");
                newPage.addEventListener('click',()=>{
                    AcademicpageController(i)
                })
                document.querySelector('.btn_wrapper >ul').appendChild(newPage)
            }
        
            if(currentPage != totalPages){
                let next = document.createElement('li');
                next.innerText = 'Next';
                next.addEventListener('click',()=>{
                    AcademicpageController(currentPage + 1)
                })
                document.querySelector('.btn_wrapper >ul').appendChild(next);
            }
         
        }
            // pageController()
          function  AcademicpageController(i){
                currentPage = i;
                loadAcademicItem()
    
          }






        function loadNonAcademicItem(){
            let startPage = limit * (currentPage - 1);
            let endPage = limit * currentPage - 1;
            NonAcademicStaffItems.forEach((item, key)=>{
                if(key>= startPage && key<= endPage){
                    item.style.display = "block"
                }else{
                    item.style.display = 'none';
                }
            })
            NonAcademicListPage()
         }
         loadNonAcademicItem()

         function NonAcademicListPage(){
            let totalPages = Math.ceil(items.nonAcademic.length / limit);
            document.querySelector('.non_academic.btn_wrapper >ul').innerHTML = '';
          
            if(currentPage !=1){
                let prev = document.createElement('li');
                prev.innerText = 'Prev';
                // prev.setAttribute('onclick', 'pageController('+ (currentPage - 1) + ')');
                prev.addEventListener('click',()=>{
                    NonAcademicpageController(currentPage - 1)
                })
                document.querySelector('.non_academic.btn_wrapper >ul').appendChild(prev);
            }
           
            for(let i = 1; i<= totalPages; i++){
                let newPage = document.createElement('li');
                newPage.innerText = i;
                if(i == currentPage){
                    newPage.classList.add('active')
                }
                // newPage.setAttribute('onclick', "AcademicpageController("+ i +")");
                newPage.addEventListener('click',()=>{
                    NonAcademicpageController(i)
                })
                document.querySelector('.non_academic.btn_wrapper >ul').appendChild(newPage)
            }
        
            if(currentPage != totalPages){
                let next = document.createElement('li');
                next.innerText = 'Next';
                next.addEventListener('click',()=>{
                    NonAcademicpageController(currentPage + 1)
                })
                document.querySelector('.non_academic.btn_wrapper >ul').appendChild(next);
            }
         
        }
            // pageController()
          function  NonAcademicpageController(i){
                currentPage = i;
               
                loadNonAcademicItem()
          }

        
    },[])


    return (
        
        <ul>

          {/* ACADEMIC STAFF*/}
          <li>
          <h4 style={{marginTop:'2rem'}}>Academic Staff</h4>

          
          <div className="other_staffs academic" style={{marginTop:'1.4rem'}}>

            {
              items.academic.map((item,i)=>
                <div data-aos="fade-down" data-aos-delay={200 + (i*10)} className="items">
                <div className="image_wrapper">
                    <img src={item.image} alt="" />
                    
                </div>
                <div className="names">
                    <h4>{item.name} </h4>
                    <p>{item.dept}</p>
                <h5>{item.desig}</h5>
                </div>
                
          </div>
                
                )
            }
            
              </div>
              <div className="pagination btn_wrapper">
                <ul>
                    <li >1</li>
                    <li>2</li>
                    <li>3</li>
                    <li>4</li>
                </ul>
            </div>  
          </li>

          {/* NON-ACADEMIC STAFF */}
          <li>
            <h4 style={{marginTop:'2rem'}}>Non-Academic Staff</h4>
          <div className="other_staffs non_academic" style={{marginTop:'1.4rem'}}>
            {
              items.nonAcademic.map(item=>
                <div className="items">
                <div className="image_wrapper">
                    <img src={item.image} alt="" />
                    
                </div>
                <div className="names">
                    <h4>{item.name} </h4>
                    <p>{item.desig}</p>
                {/* <h5>Faculty Secretary</h5> */}
                </div>
                
        </div>
                
                
                )
            }
          
                   
            </div> 
            <div className="pagination non_academic btn_wrapper">
                <ul>
                    <li >1</li>
                    <li>2</li>
                    <li>3</li>
                    <li>4</li>
                </ul>
            </div>
            </ li>
        </ul>
    
      );
}
