import React,{ useEffect }  from 'react'

import { useParams } from 'react-router-dom'
import Footer from '../../../components/College/Footer'

export default function PopularTag() {
    let {id}= useParams()
    useEffect(()=>{
        document.title="College of Health Sciences - Popular Tags, Obafemi Awolwo University, Ile-Ife "
        window.scrollTo({
          top:0
        })
      },[])
  return (
    <div className='popular-tag chs_top'>
    <div className='popular-tag-wrapper'>

            {id}
    </div>

     <Footer />       
    </div>
  )
}
