import React,{useEffect} from 'react'
import Layout1 from '../../../components/College/Layout1'
import { cliNonAcademicStaff } from '../../../utilities/Non-Academic-Staff'
import { academicStaff } from '../../../utilities/Academic-Staff'
import Faculty_Staff_Layout from '../../../components/College/Faculty_Staff_Layout'
export default function Clinical_Sciences_Staff() {
  useEffect(()=>{
    document.title="College of Health Sciences - Faculty of Clinical Sciences, Obafemi Awolwo University, Ile-Ife "
    window.scrollTo({
      top:0
    })
  },[])
  return (
    <div>
      <Layout1 title="Faculty of Clinical Sciences Staff" text="Staff">
          <Faculty_Staff_Layout
              academicStaff={academicStaff.cli}
              nonAcademicStaff={cliNonAcademicStaff}
              faculty="Clinical Sciences"
              text="Clinical Sciences"
          />
      </Layout1>
    </div>
  )
}
