import {GiChemicalDrop, GiArtificialIntelligence} from 'react-icons/gi'
import {BiData} from 'react-icons/bi'

import pub1 from '../assets/images/staff publication/1.jpg'
import pub2 from '../assets/images/staff publication/2.jpg'
import pub3 from '../assets/images/staff publication/3.jpg'




// BASIC MEDICAL SCIENCES IMAGES

import bmsImg1 from '../assets/images/staff gallery/academic/bms/academic/img1.jpg'
import bmsAltImg1 from '../assets/images/staff gallery/academic/bms/academic/alt/alt_img1.jpg'
import bmsImg2 from '../assets/images/staff gallery/academic/bms/academic/vdean3.jpg'
import bmsAltImg2 from '../assets/images/staff gallery/academic/bms/academic/alt/vdean_alt.jpg'
import bmsImg4 from '../assets/images/staff gallery/academic/bms/academic/bmsAcad2.jpg'
import bmsAltImg4 from '../assets/images/staff gallery/academic/bms/academic/alt/alt2.JPG'
import bmsImg5 from '../assets/images/staff gallery/academic/bms/academic/bmsAcad3.jpg'
import bmsAltImg5 from '../assets/images/staff gallery/academic/bms/academic/alt/alt3.JPG'
import bmsImg6 from '../assets/images/staff gallery/academic/bms/academic/bmsAcad4.jpg'
import bmsAltImg6 from '../assets/images/staff gallery/academic/bms/academic/alt/alt4.JPG'
import bmsImg7 from '../assets/images/staff gallery/academic/bms/academic/bmsAcad5.jpg'
import bmsImg8 from '../assets/images/staff gallery/academic/bms/academic/bmsAcad6.jpg'
import bmsImg9 from '../assets/images/staff gallery/academic/bms/academic/bmsAcad7.jpg'
// import bmsAltImg6 from '../assets/images/staff gallery/academic/bms/academic/alt/alt4.JPG'


// CLINICAL SCIENCES IMAGES

import Cli_dean from '../assets/images/staff gallery/academic/clinical/cli_dean.jpg'
import CliVice_dean from '../assets/images/staff gallery/academic/clinical/cliAcada18.jpg'
import cliImg1 from '../assets/images/staff gallery/academic/clinical/cliAcada1.jpg'
import cliImg2 from '../assets/images/staff gallery/academic/clinical/cliAcada2.jpg'
import cliImg3 from '../assets/images/staff gallery/academic/clinical/cliAcada3.jpg'
import cliImg4 from '../assets/images/staff gallery/academic/clinical/cliAcada4.jpg'
import cliImg5 from '../assets/images/staff gallery/academic/clinical/cliAcada5.jpg'
import cliImg6 from '../assets/images/staff gallery/academic/clinical/cliAcada6.jpg'
import cliImg7 from '../assets/images/staff gallery/academic/clinical/cliAcada7.jpg'
import cliImg8 from '../assets/images/staff gallery/academic/clinical/cliAcada8.jpg'
import cliImg9 from '../assets/images/staff gallery/academic/clinical/cliAcada9.jpg'
import cliImg10 from '../assets/images/staff gallery/academic/clinical/cliAcada10.jpg'
import cliImg11 from '../assets/images/staff gallery/academic/clinical/cliAcada11.jpg'
import cliImg12 from '../assets/images/staff gallery/academic/clinical/cliAcada12.jpg'
import cliImg13 from '../assets/images/staff gallery/academic/clinical/cliAcada13.jpg'
import cliImg14 from '../assets/images/staff gallery/academic/clinical/cliAcada14.jpg'
import cliImg15 from '../assets/images/staff gallery/academic/clinical/cliAcada15.jpg'
import cliImg16 from '../assets/images/staff gallery/academic/clinical/cliAcada16.jpg'
import cliImg17 from '../assets/images/staff gallery/academic/clinical/cliAcada17.jpg'
import cliImg18 from '../assets/images/staff gallery/academic/clinical/cliAcada18.jpg'
import cliImg19 from '../assets/images/staff gallery/academic/clinical/cliAcada19.jpg'
import cliImg20 from '../assets/images/staff gallery/academic/clinical/cliAcada20.jpg'
import cliImg21 from '../assets/images/staff gallery/academic/clinical/cliAcada21.jpg'
import cliImg22 from '../assets/images/staff gallery/academic/clinical/cliAcada22.jpg'
import cliImg23 from '../assets/images/staff gallery/academic/clinical/cliAcada23.jpg'
import cliImg24 from '../assets/images/staff gallery/academic/clinical/cliAcada24.jpg'
import cliImg25 from '../assets/images/staff gallery/academic/clinical/cliAcada25.jpg'
import cliImg26 from '../assets/images/staff gallery/academic/clinical/cliAcada26.jpg'
import cliImg27 from '../assets/images/staff gallery/academic/clinical/cliAcada27.jpg'


// DENTISTRY IMAGES

import denImg1 from '../assets/images/staff gallery/academic/dentistry/denAcada9.jpg'
import denImg2 from '../assets/images/staff gallery/academic/dentistry/denAcada1.jpg'
import denImg3 from '../assets/images/staff gallery/academic/dentistry/denAcada2.jpg'
import denImg4 from '../assets/images/staff gallery/academic/dentistry/denAcada4.jpg'
import denImg5 from '../assets/images/staff gallery/academic/dentistry/denAcada5.jpg'
import denImg6 from '../assets/images/staff gallery/academic/dentistry/denAcada6.jpg'
import denImg7 from '../assets/images/staff gallery/academic/dentistry/denAcada3.jpg'
import denImg8 from '../assets/images/staff gallery/academic/dentistry/img4.png'
import denImg9 from '../assets/images/staff gallery/academic/dentistry/denAcada4.jpg'
import denImg10 from '../assets/images/staff gallery/academic/dentistry/denAcada7.jpg'
import denImg11 from '../assets/images/staff gallery/academic/dentistry/denAcada8.jpg'



















let academicStaff ={
// FACULTY OF BASIC MEDICAL SCIENCES
    bms:[
        {
            name:'Prof. Lattef Salawu',
            image:bmsImg1,
            altImg:bmsAltImg1,
            dept:'Haematology & Immunology',
            socialLinks:'',
            link:'lateef-salawu',
            phone:'09034897594',
            faculty:'Basic Medical Sciences',
            desig:'Dean',
            experience:[
                {
                    year:'2021 - Present',
                    institution:'Computer Science Department, Obafemi Awolowo University,Ile-Ife, Osun State',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                // {
                //     year:'2020 - 2021',
                //     institution:'National Youth Service Corps (NYSC), Infomation Technology and Communication Unit (INTECU), Obafemi Awolowo University,Ile-Ife, Osun State',
                //     desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                // },
                {
                    year:'2014 - 2019',
                    institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    year:'2005 - 2013',
                    institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
             
               
            ],
            research:[
                {
                    icon:<GiArtificialIntelligence />,
                    title:'Artificial Intelligence',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    icon:<BiData />,
                    title:'Data Science',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    icon:<GiChemicalDrop />,
                    title:'Machine Learning',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
            ],
            publication:[
                {
                    img:pub1,
                    title:'How can we prevent a glaciers melting?',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                },
                {
                    img:pub2,
                    title:'Global warming: how to prevent a catastrophe. Arguments and conclusions.',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                },
                {
                    img:pub3,
                    title:'What type of proteins are useful?',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                }
            ],
            award:[
                {
                    totalAward:10
                },
                {
                    totalHonour:20
                },
                {
                    totalOther:15
                }
            ],
            teaching:{
                undergraduate:[
                    {
                        title:'CSC201 - Introduction to Java Programming Language',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC315 - Introduction to Information System',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC419 - Foundation of Programming Language',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    }
                ],
                postgraduate:[
                    {
                        title:'CSC601 - Introduction to Python Programming Language 1',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC619 - Introduction to Python Programming Language 2',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC791 - Introduction to Python Programming Language 3',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    }
                ]
            }
        },
        {
            name:'Dr. Samson Odukoya',
            image:bmsImg2,
            altImg:bmsAltImg2,
            dept:'Haematology & Immunology',
            socialLinks:'',
            link:'samson-odukoya',
            phone:'09034897594',
            faculty:'Basic Medical Sciences',
            desig:'Vice Dean',
            experience:[
                {
                    year:'2021 - Present',
                    institution:'Computer Science Department, Obafemi Awolowo University,Ile-Ife, Osun State',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                // {
                //     year:'2020 - 2021',
                //     institution:'National Youth Service Corps (NYSC), Infomation Technology and Communication Unit (INTECU), Obafemi Awolowo University,Ile-Ife, Osun State',
                //     desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                // },
                {
                    year:'2014 - 2019',
                    institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    year:'2005 - 2013',
                    institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
             
               
            ],
            research:[
                {
                    icon:<GiArtificialIntelligence />,
                    title:'Artificial Intelligence',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    icon:<BiData />,
                    title:'Data Science',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    icon:<GiChemicalDrop />,
                    title:'Machine Learning',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
            ],
            publication:[
                {
                    img:pub1,
                    title:'How can we prevent a glaciers melting?',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                },
                {
                    img:pub2,
                    title:'Global warming: how to prevent a catastrophe. Arguments and conclusions.',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                },
                {
                    img:pub3,
                    title:'What type of proteins are useful?',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                }
            ],
            award:[
                {
                    totalAward:10
                },
                {
                    totalHonour:20
                },
                {
                    totalOther:15
                }
            ],
            teaching:{
                undergraduate:[
                    {
                        title:'CSC201 - Introduction to Java Programming Language',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC315 - Introduction to Information System',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC419 - Foundation of Programming Language',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    }
                ],
                postgraduate:[
                    {
                        title:'CSC601 - Introduction to Python Programming Language 1',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC619 - Introduction to Python Programming Language 2',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC791 - Introduction to Python Programming Language 3',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    }
                ]
            }
        },
        
        
        {
            name:'Dr. B.O. Olopade',
            image:bmsImg4,
            altImg:bmsAltImg4,
            dept:'Haematology & Immunology',
            socialLinks:'',
            link:'samson-odukoya',
            phone:'09034897594',
            faculty:'Basic Medical Sciences',
            desig:'',
            experience:[
                {
                    year:'2021 - Present',
                    institution:'Computer Science Department, Obafemi Awolowo University,Ile-Ife, Osun State',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                // {
                //     year:'2020 - 2021',
                //     institution:'National Youth Service Corps (NYSC), Infomation Technology and Communication Unit (INTECU), Obafemi Awolowo University,Ile-Ife, Osun State',
                //     desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                // },
                {
                    year:'2014 - 2019',
                    institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    year:'2005 - 2013',
                    institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
             
               
            ],
            research:[
                {
                    icon:<GiArtificialIntelligence />,
                    title:'Artificial Intelligence',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    icon:<BiData />,
                    title:'Data Science',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    icon:<GiChemicalDrop />,
                    title:'Machine Learning',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
            ],
            publication:[
                {
                    img:pub1,
                    title:'How can we prevent a glaciers melting?',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                },
                {
                    img:pub2,
                    title:'Global warming: how to prevent a catastrophe. Arguments and conclusions.',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                },
                {
                    img:pub3,
                    title:'What type of proteins are useful?',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                }
            ],
            award:[
                {
                    totalAward:10
                },
                {
                    totalHonour:20
                },
                {
                    totalOther:15
                }
            ],
            teaching:{
                undergraduate:[
                    {
                        title:'CSC201 - Introduction to Java Programming Language',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC315 - Introduction to Information System',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC419 - Foundation of Programming Language',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    }
                ],
                postgraduate:[
                    {
                        title:'CSC601 - Introduction to Python Programming Language 1',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC619 - Introduction to Python Programming Language 2',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC791 - Introduction to Python Programming Language 3',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    }
                ]
            }
        },
        
        {
            name:'Dr. B.W. Odetoyin',
            image:bmsImg5,
            altImg:bmsAltImg4,
            dept:'Haematology & Immunology',
            socialLinks:'',
            link:'samson-odukoya',
            phone:'09034897594',
            faculty:'Basic Medical Sciences',
            desig:'',
            experience:[
                {
                    year:'2021 - Present',
                    institution:'Computer Science Department, Obafemi Awolowo University,Ile-Ife, Osun State',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                // {
                //     year:'2020 - 2021',
                //     institution:'National Youth Service Corps (NYSC), Infomation Technology and Communication Unit (INTECU), Obafemi Awolowo University,Ile-Ife, Osun State',
                //     desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                // },
                {
                    year:'2014 - 2019',
                    institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    year:'2005 - 2013',
                    institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
             
               
            ],
            research:[
                {
                    icon:<GiArtificialIntelligence />,
                    title:'Artificial Intelligence',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    icon:<BiData />,
                    title:'Data Science',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    icon:<GiChemicalDrop />,
                    title:'Machine Learning',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
            ],
            publication:[
                {
                    img:pub1,
                    title:'How can we prevent a glaciers melting?',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                },
                {
                    img:pub2,
                    title:'Global warming: how to prevent a catastrophe. Arguments and conclusions.',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                },
                {
                    img:pub3,
                    title:'What type of proteins are useful?',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                }
            ],
            award:[
                {
                    totalAward:10
                },
                {
                    totalHonour:20
                },
                {
                    totalOther:15
                }
            ],
            teaching:{
                undergraduate:[
                    {
                        title:'CSC201 - Introduction to Java Programming Language',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC315 - Introduction to Information System',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC419 - Foundation of Programming Language',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    }
                ],
                postgraduate:[
                    {
                        title:'CSC601 - Introduction to Python Programming Language 1',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC619 - Introduction to Python Programming Language 2',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC791 - Introduction to Python Programming Language 3',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    }
                ]
            }
        },
        
        {
            name:'Prof. Michael Egwu',
            image:bmsImg6,
            altImg:bmsAltImg4,
            dept:'Haematology & Immunology',
            socialLinks:'',
            link:'samson-odukoya',
            phone:'09034897594',
            faculty:'Basic Medical Sciences',
            desig:'',
            experience:[
                {
                    year:'2021 - Present',
                    institution:'Computer Science Department, Obafemi Awolowo University,Ile-Ife, Osun State',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                // {
                //     year:'2020 - 2021',
                //     institution:'National Youth Service Corps (NYSC), Infomation Technology and Communication Unit (INTECU), Obafemi Awolowo University,Ile-Ife, Osun State',
                //     desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                // },
                {
                    year:'2014 - 2019',
                    institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    year:'2005 - 2013',
                    institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
             
               
            ],
            research:[
                {
                    icon:<GiArtificialIntelligence />,
                    title:'Artificial Intelligence',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    icon:<BiData />,
                    title:'Data Science',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    icon:<GiChemicalDrop />,
                    title:'Machine Learning',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
            ],
            publication:[
                {
                    img:pub1,
                    title:'How can we prevent a glaciers melting?',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                },
                {
                    img:pub2,
                    title:'Global warming: how to prevent a catastrophe. Arguments and conclusions.',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                },
                {
                    img:pub3,
                    title:'What type of proteins are useful?',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                }
            ],
            award:[
                {
                    totalAward:10
                },
                {
                    totalHonour:20
                },
                {
                    totalOther:15
                }
            ],
            teaching:{
                undergraduate:[
                    {
                        title:'CSC201 - Introduction to Java Programming Language',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC315 - Introduction to Information System',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC419 - Foundation of Programming Language',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    }
                ],
                postgraduate:[
                    {
                        title:'CSC601 - Introduction to Python Programming Language 1',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC619 - Introduction to Python Programming Language 2',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC791 - Introduction to Python Programming Language 3',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    }
                ]
            }
        },
        
        {
            name:'Dr. Ayoola Olaoye',
            image:bmsImg7,
            altImg:bmsAltImg4,
            dept:'Haematology & Immunology',
            socialLinks:'',
            link:'samson-odukoya',
            phone:'09034897594',
            faculty:'Basic Medical Sciences',
            desig:'',
            experience:[
                {
                    year:'2021 - Present',
                    institution:'Computer Science Department, Obafemi Awolowo University,Ile-Ife, Osun State',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                // {
                //     year:'2020 - 2021',
                //     institution:'National Youth Service Corps (NYSC), Infomation Technology and Communication Unit (INTECU), Obafemi Awolowo University,Ile-Ife, Osun State',
                //     desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                // },
                {
                    year:'2014 - 2019',
                    institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    year:'2005 - 2013',
                    institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
             
               
            ],
            research:[
                {
                    icon:<GiArtificialIntelligence />,
                    title:'Artificial Intelligence',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    icon:<BiData />,
                    title:'Data Science',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    icon:<GiChemicalDrop />,
                    title:'Machine Learning',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
            ],
            publication:[
                {
                    img:pub1,
                    title:'How can we prevent a glaciers melting?',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                },
                {
                    img:pub2,
                    title:'Global warming: how to prevent a catastrophe. Arguments and conclusions.',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                },
                {
                    img:pub3,
                    title:'What type of proteins are useful?',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                }
            ],
            award:[
                {
                    totalAward:10
                },
                {
                    totalHonour:20
                },
                {
                    totalOther:15
                }
            ],
            teaching:{
                undergraduate:[
                    {
                        title:'CSC201 - Introduction to Java Programming Language',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC315 - Introduction to Information System',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC419 - Foundation of Programming Language',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    }
                ],
                postgraduate:[
                    {
                        title:'CSC601 - Introduction to Python Programming Language 1',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC619 - Introduction to Python Programming Language 2',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC791 - Introduction to Python Programming Language 3',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    }
                ]
            }
        },
        {
            name:'Prof. A.O. Onipede',
            image:bmsImg8,
            altImg:bmsAltImg4,
            dept:'Medical Biology & Parasitology',
            socialLinks:'',
            link:'samson-odukoya',
            phone:'09034897594',
            faculty:'Basic Medical Sciences',
            desig:'',
            experience:[
                {
                    year:'2021 - Present',
                    institution:'Computer Science Department, Obafemi Awolowo University,Ile-Ife, Osun State',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                // {
                //     year:'2020 - 2021',
                //     institution:'National Youth Service Corps (NYSC), Infomation Technology and Communication Unit (INTECU), Obafemi Awolowo University,Ile-Ife, Osun State',
                //     desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                // },
                {
                    year:'2014 - 2019',
                    institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    year:'2005 - 2013',
                    institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
             
               
            ],
            research:[
                {
                    icon:<GiArtificialIntelligence />,
                    title:'Artificial Intelligence',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    icon:<BiData />,
                    title:'Data Science',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    icon:<GiChemicalDrop />,
                    title:'Machine Learning',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
            ],
            publication:[
                {
                    img:pub1,
                    title:'How can we prevent a glaciers melting?',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                },
                {
                    img:pub2,
                    title:'Global warming: how to prevent a catastrophe. Arguments and conclusions.',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                },
                {
                    img:pub3,
                    title:'What type of proteins are useful?',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                }
            ],
            award:[
                {
                    totalAward:10
                },
                {
                    totalHonour:20
                },
                {
                    totalOther:15
                }
            ],
            teaching:{
                undergraduate:[
                    {
                        title:'CSC201 - Introduction to Java Programming Language',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC315 - Introduction to Information System',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC419 - Foundation of Programming Language',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    }
                ],
                postgraduate:[
                    {
                        title:'CSC601 - Introduction to Python Programming Language 1',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC619 - Introduction to Python Programming Language 2',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC791 - Introduction to Python Programming Language 3',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    }
                ]
            }
        },
        {
            name:'Dr. A.K. Ajeigbe',
            image:bmsImg9,
            altImg:bmsAltImg4,
            dept:'Chemical Pathology ',
            socialLinks:'',
            link:'samson-odukoya',
            phone:'09034897594',
            faculty:'Basic Medical Sciences',
            // desig:'Senior Lecturer',
            desig:'',
            experience:[
                {
                    year:'2021 - Present',
                    institution:'Computer Science Department, Obafemi Awolowo University,Ile-Ife, Osun State',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                // {
                //     year:'2020 - 2021',
                //     institution:'National Youth Service Corps (NYSC), Infomation Technology and Communication Unit (INTECU), Obafemi Awolowo University,Ile-Ife, Osun State',
                //     desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                // },
                {
                    year:'2014 - 2019',
                    institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    year:'2005 - 2013',
                    institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
             
               
            ],
            research:[
                {
                    icon:<GiArtificialIntelligence />,
                    title:'Artificial Intelligence',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    icon:<BiData />,
                    title:'Data Science',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    icon:<GiChemicalDrop />,
                    title:'Machine Learning',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
            ],
            publication:[
                {
                    img:pub1,
                    title:'How can we prevent a glaciers melting?',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                },
                {
                    img:pub2,
                    title:'Global warming: how to prevent a catastrophe. Arguments and conclusions.',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                },
                {
                    img:pub3,
                    title:'What type of proteins are useful?',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                }
            ],
            award:[
                {
                    totalAward:10
                },
                {
                    totalHonour:20
                },
                {
                    totalOther:15
                }
            ],
            teaching:{
                undergraduate:[
                    {
                        title:'CSC201 - Introduction to Java Programming Language',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC315 - Introduction to Information System',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC419 - Foundation of Programming Language',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    }
                ],
                postgraduate:[
                    {
                        title:'CSC601 - Introduction to Python Programming Language 1',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC619 - Introduction to Python Programming Language 2',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC791 - Introduction to Python Programming Language 3',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    }
                ]
            }
        },
        
      

        
    



    ],



// FACULTY OF CLINICAL  SCIENCES
    cli:[
        {
            name:'Prof. Oludayo Sowande',
            image:Cli_dean,
            altImg:bmsAltImg4,
            dept:'Surgery',
            socialLinks:'',
            link:'oludayo-sowande',
            phone:'09034897594',
            faculty:'Clinical Sciences',
            desig:'Dean',
            experience:[
                {
                    year:'2021 - Present',
                    institution:'Computer Science Department, Obafemi Awolowo University,Ile-Ife, Osun State',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                // {
                //     year:'2020 - 2021',
                //     institution:'National Youth Service Corps (NYSC), Infomation Technology and Communication Unit (INTECU), Obafemi Awolowo University,Ile-Ife, Osun State',
                //     desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                // },
                {
                    year:'2014 - 2019',
                    institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    year:'2005 - 2013',
                    institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
             
               
            ],
            research:[
                {
                    icon:<GiArtificialIntelligence />,
                    title:'Artificial Intelligence',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    icon:<BiData />,
                    title:'Data Science',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    icon:<GiChemicalDrop />,
                    title:'Machine Learning',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
            ],
            publication:[
                {
                    img:pub1,
                    title:'How can we prevent a glaciers melting?',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                },
                {
                    img:pub2,
                    title:'Global warming: how to prevent a catastrophe. Arguments and conclusions.',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                },
                {
                    img:pub3,
                    title:'What type of proteins are useful?',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                }
            ],
            award:[
                {
                    totalAward:10
                },
                {
                    totalHonour:20
                },
                {
                    totalOther:15
                }
            ],
            teaching:{
                undergraduate:[
                    {
                        title:'CSC201 - Introduction to Java Programming Language',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC315 - Introduction to Information System',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC419 - Foundation of Programming Language',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    }
                ],
                postgraduate:[
                    {
                        title:'CSC601 - Introduction to Python Programming Language 1',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC619 - Introduction to Python Programming Language 2',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC791 - Introduction to Python Programming Language 3',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    }
                ]
            }
        },
        {
            name:'Dr. Esan Oluwadare',
            image:CliVice_dean,
            altImg:bmsAltImg4,
            dept:'',
            socialLinks:'',
            link:'oludayo-sowande',
            phone:'09034897594',
            faculty:'Clinical Sciences',
            desig:'Vice Dean',
            experience:[
                {
                    year:'2021 - Present',
                    institution:'Computer Science Department, Obafemi Awolowo University,Ile-Ife, Osun State',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                // {
                //     year:'2020 - 2021',
                //     institution:'National Youth Service Corps (NYSC), Infomation Technology and Communication Unit (INTECU), Obafemi Awolowo University,Ile-Ife, Osun State',
                //     desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                // },
                {
                    year:'2014 - 2019',
                    institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    year:'2005 - 2013',
                    institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
             
               
            ],
            research:[
                {
                    icon:<GiArtificialIntelligence />,
                    title:'Artificial Intelligence',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    icon:<BiData />,
                    title:'Data Science',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    icon:<GiChemicalDrop />,
                    title:'Machine Learning',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
            ],
            publication:[
                {
                    img:pub1,
                    title:'How can we prevent a glaciers melting?',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                },
                {
                    img:pub2,
                    title:'Global warming: how to prevent a catastrophe. Arguments and conclusions.',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                },
                {
                    img:pub3,
                    title:'What type of proteins are useful?',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                }
            ],
            award:[
                {
                    totalAward:10
                },
                {
                    totalHonour:20
                },
                {
                    totalOther:15
                }
            ],
            teaching:{
                undergraduate:[
                    {
                        title:'CSC201 - Introduction to Java Programming Language',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC315 - Introduction to Information System',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC419 - Foundation of Programming Language',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    }
                ],
                postgraduate:[
                    {
                        title:'CSC601 - Introduction to Python Programming Language 1',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC619 - Introduction to Python Programming Language 2',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC791 - Introduction to Python Programming Language 3',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    }
                ]
            }
        },
        {
            name:'Prof. B.M. Mapayi',
            image:cliImg16,
            altImg:bmsAltImg4,
            dept:'Mental Health',
            socialLinks:'',
            link:'boladale-mapayi',
            phone:'09034897594',
            faculty:'Clinical Sciences',
            desig:'',
            experience:[
                {
                    year:'2021 - Present',
                    institution:'Computer Science Department, Obafemi Awolowo University,Ile-Ife, Osun State',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                // {
                //     year:'2020 - 2021',
                //     institution:'National Youth Service Corps (NYSC), Infomation Technology and Communication Unit (INTECU), Obafemi Awolowo University,Ile-Ife, Osun State',
                //     desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                // },
                {
                    year:'2014 - 2019',
                    institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    year:'2005 - 2013',
                    institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
             
               
            ],
            research:[
                {
                    icon:<GiArtificialIntelligence />,
                    title:'Artificial Intelligence',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    icon:<BiData />,
                    title:'Data Science',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    icon:<GiChemicalDrop />,
                    title:'Machine Learning',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
            ],
            publication:[
                {
                    img:pub1,
                    title:'How can we prevent a glaciers melting?',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                },
                {
                    img:pub2,
                    title:'Global warming: how to prevent a catastrophe. Arguments and conclusions.',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                },
                {
                    img:pub3,
                    title:'What type of proteins are useful?',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                }
            ],
            award:[
                {
                    totalAward:10
                },
                {
                    totalHonour:20
                },
                {
                    totalOther:15
                }
            ],
            teaching:{
                undergraduate:[
                    {
                        title:'CSC201 - Introduction to Java Programming Language',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC315 - Introduction to Information System',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC419 - Foundation of Programming Language',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    }
                ],
                postgraduate:[
                    {
                        title:'CSC601 - Introduction to Python Programming Language 1',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC619 - Introduction to Python Programming Language 2',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC791 - Introduction to Python Programming Language 3',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    }
                ]
            }
        },
        {
            name:'Dr. S.A. Adegoke',
            image:cliImg2,
            altImg:bmsAltImg4,
            dept:'Paediatrics and Child Health',
            socialLinks:'',
            link:'samuel-adegoke',
            phone:'09034897594',
            faculty:'Clinical Sciences',
            desig:'',
            experience:[
                {
                    year:'2021 - Present',
                    institution:'Computer Science Department, Obafemi Awolowo University,Ile-Ife, Osun State',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                // {
                //     year:'2020 - 2021',
                //     institution:'National Youth Service Corps (NYSC), Infomation Technology and Communication Unit (INTECU), Obafemi Awolowo University,Ile-Ife, Osun State',
                //     desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                // },
                {
                    year:'2014 - 2019',
                    institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    year:'2005 - 2013',
                    institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
             
               
            ],
            research:[
                {
                    icon:<GiArtificialIntelligence />,
                    title:'Artificial Intelligence',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    icon:<BiData />,
                    title:'Data Science',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    icon:<GiChemicalDrop />,
                    title:'Machine Learning',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
            ],
            publication:[
                {
                    img:pub1,
                    title:'How can we prevent a glaciers melting?',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                },
                {
                    img:pub2,
                    title:'Global warming: how to prevent a catastrophe. Arguments and conclusions.',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                },
                {
                    img:pub3,
                    title:'What type of proteins are useful?',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                }
            ],
            award:[
                {
                    totalAward:10
                },
                {
                    totalHonour:20
                },
                {
                    totalOther:15
                }
            ],
            teaching:{
                undergraduate:[
                    {
                        title:'CSC201 - Introduction to Java Programming Language',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC315 - Introduction to Information System',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC419 - Foundation of Programming Language',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    }
                ],
                postgraduate:[
                    {
                        title:'CSC601 - Introduction to Python Programming Language 1',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC619 - Introduction to Python Programming Language 2',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC791 - Introduction to Python Programming Language 3',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    }
                ]
            }
        },
        {
            name:'Dr. C.A. Adepiti',
            image:cliImg3,
            altImg:bmsAltImg4,
            dept:'Obstetrics, Gynaecology and Perinatology ',
            socialLinks:'',
            link:'oludayo-sowande',
            phone:'09034897594',
            faculty:'Clinical Sciences',
            desig:'',
            experience:[
                {
                    year:'2021 - Present',
                    institution:'Computer Science Department, Obafemi Awolowo University,Ile-Ife, Osun State',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                // {
                //     year:'2020 - 2021',
                //     institution:'National Youth Service Corps (NYSC), Infomation Technology and Communication Unit (INTECU), Obafemi Awolowo University,Ile-Ife, Osun State',
                //     desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                // },
                {
                    year:'2014 - 2019',
                    institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    year:'2005 - 2013',
                    institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
             
               
            ],
            research:[
                {
                    icon:<GiArtificialIntelligence />,
                    title:'Artificial Intelligence',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    icon:<BiData />,
                    title:'Data Science',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    icon:<GiChemicalDrop />,
                    title:'Machine Learning',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
            ],
            publication:[
                {
                    img:pub1,
                    title:'How can we prevent a glaciers melting?',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                },
                {
                    img:pub2,
                    title:'Global warming: how to prevent a catastrophe. Arguments and conclusions.',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                },
                {
                    img:pub3,
                    title:'What type of proteins are useful?',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                }
            ],
            award:[
                {
                    totalAward:10
                },
                {
                    totalHonour:20
                },
                {
                    totalOther:15
                }
            ],
            teaching:{
                undergraduate:[
                    {
                        title:'CSC201 - Introduction to Java Programming Language',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC315 - Introduction to Information System',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC419 - Foundation of Programming Language',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    }
                ],
                postgraduate:[
                    {
                        title:'CSC601 - Introduction to Python Programming Language 1',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC619 - Introduction to Python Programming Language 2',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC791 - Introduction to Python Programming Language 3',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    }
                ]
            }
        },
        {
            name:'Dr. B.P. Kuti',
            image:cliImg4,
            altImg:bmsAltImg4,
            dept:'Paediatrics and Child Health',
            socialLinks:'',
            link:'bankole-peter',
            phone:'09034897594',
            faculty:'Clinical Sciences',
            desig:'',
            experience:[
                {
                    year:'2021 - Present',
                    institution:'Computer Science Department, Obafemi Awolowo University,Ile-Ife, Osun State',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                // {
                //     year:'2020 - 2021',
                //     institution:'National Youth Service Corps (NYSC), Infomation Technology and Communication Unit (INTECU), Obafemi Awolowo University,Ile-Ife, Osun State',
                //     desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                // },
                {
                    year:'2014 - 2019',
                    institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    year:'2005 - 2013',
                    institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
             
               
            ],
            research:[
                {
                    icon:<GiArtificialIntelligence />,
                    title:'Artificial Intelligence',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    icon:<BiData />,
                    title:'Data Science',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    icon:<GiChemicalDrop />,
                    title:'Machine Learning',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
            ],
            publication:[
                {
                    img:pub1,
                    title:'How can we prevent a glaciers melting?',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                },
                {
                    img:pub2,
                    title:'Global warming: how to prevent a catastrophe. Arguments and conclusions.',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                },
                {
                    img:pub3,
                    title:'What type of proteins are useful?',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                }
            ],
            award:[
                {
                    totalAward:10
                },
                {
                    totalHonour:20
                },
                {
                    totalOther:15
                }
            ],
            teaching:{
                undergraduate:[
                    {
                        title:'CSC201 - Introduction to Java Programming Language',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC315 - Introduction to Information System',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC419 - Foundation of Programming Language',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    }
                ],
                postgraduate:[
                    {
                        title:'CSC601 - Introduction to Python Programming Language 1',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC619 - Introduction to Python Programming Language 2',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC791 - Introduction to Python Programming Language 3',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    }
                ]
            }
        },
        {
            name:'Dr. A.A. Adeomi',
            image:cliImg5,
            altImg:bmsAltImg4,
            dept:'Community Health',
            socialLinks:'',
            link:'oludayo-sowande',
            phone:'09034897594',
            faculty:'Clinical Sciences',
            desig:'',
            experience:[
                {
                    year:'2021 - Present',
                    institution:'Computer Science Department, Obafemi Awolowo University,Ile-Ife, Osun State',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                // {
                //     year:'2020 - 2021',
                //     institution:'National Youth Service Corps (NYSC), Infomation Technology and Communication Unit (INTECU), Obafemi Awolowo University,Ile-Ife, Osun State',
                //     desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                // },
                {
                    year:'2014 - 2019',
                    institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    year:'2005 - 2013',
                    institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
             
               
            ],
            research:[
                {
                    icon:<GiArtificialIntelligence />,
                    title:'Artificial Intelligence',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    icon:<BiData />,
                    title:'Data Science',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    icon:<GiChemicalDrop />,
                    title:'Machine Learning',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
            ],
            publication:[
                {
                    img:pub1,
                    title:'How can we prevent a glaciers melting?',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                },
                {
                    img:pub2,
                    title:'Global warming: how to prevent a catastrophe. Arguments and conclusions.',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                },
                {
                    img:pub3,
                    title:'What type of proteins are useful?',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                }
            ],
            award:[
                {
                    totalAward:10
                },
                {
                    totalHonour:20
                },
                {
                    totalOther:15
                }
            ],
            teaching:{
                undergraduate:[
                    {
                        title:'CSC201 - Introduction to Java Programming Language',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC315 - Introduction to Information System',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC419 - Foundation of Programming Language',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    }
                ],
                postgraduate:[
                    {
                        title:'CSC601 - Introduction to Python Programming Language 1',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC619 - Introduction to Python Programming Language 2',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC791 - Introduction to Python Programming Language 3',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    }
                ]
            }
        },
        {
            name:'Dr. T.O. Ojo',
            image:cliImg6,
            altImg:bmsAltImg4,
            dept:'Community Health',
            socialLinks:'',
            link:'oludayo-sowande',
            phone:'09034897594',
            faculty:'Clinical Sciences',
            // desig:'Senior Lecturer',
            desig:'',
            experience:[
                {
                    year:'2021 - Present',
                    institution:'Computer Science Department, Obafemi Awolowo University,Ile-Ife, Osun State',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                // {
                //     year:'2020 - 2021',
                //     institution:'National Youth Service Corps (NYSC), Infomation Technology and Communication Unit (INTECU), Obafemi Awolowo University,Ile-Ife, Osun State',
                //     desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                // },
                {
                    year:'2014 - 2019',
                    institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    year:'2005 - 2013',
                    institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
             
               
            ],
            research:[
                {
                    icon:<GiArtificialIntelligence />,
                    title:'Artificial Intelligence',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    icon:<BiData />,
                    title:'Data Science',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    icon:<GiChemicalDrop />,
                    title:'Machine Learning',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
            ],
            publication:[
                {
                    img:pub1,
                    title:'How can we prevent a glaciers melting?',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                },
                {
                    img:pub2,
                    title:'Global warming: how to prevent a catastrophe. Arguments and conclusions.',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                },
                {
                    img:pub3,
                    title:'What type of proteins are useful?',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                }
            ],
            award:[
                {
                    totalAward:10
                },
                {
                    totalHonour:20
                },
                {
                    totalOther:15
                }
            ],
            teaching:{
                undergraduate:[
                    {
                        title:'CSC201 - Introduction to Java Programming Language',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC315 - Introduction to Information System',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC419 - Foundation of Programming Language',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    }
                ],
                postgraduate:[
                    {
                        title:'CSC601 - Introduction to Python Programming Language 1',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC619 - Introduction to Python Programming Language 2',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC791 - Introduction to Python Programming Language 3',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    }
                ]
            }
        },
        {
            name:'Dr. O.F. Olatunde',
            image:cliImg7,
            altImg:bmsAltImg4,
            dept:'Dermatology / Venereology ',
            socialLinks:'',
            link:'oludayo-sowande',
            phone:'09034897594',
            faculty:'Clinical Sciences',
            desig:'',
            experience:[
                {
                    year:'2021 - Present',
                    institution:'Computer Science Department, Obafemi Awolowo University,Ile-Ife, Osun State',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                // {
                //     year:'2020 - 2021',
                //     institution:'National Youth Service Corps (NYSC), Infomation Technology and Communication Unit (INTECU), Obafemi Awolowo University,Ile-Ife, Osun State',
                //     desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                // },
                {
                    year:'2014 - 2019',
                    institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    year:'2005 - 2013',
                    institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
             
               
            ],
            research:[
                {
                    icon:<GiArtificialIntelligence />,
                    title:'Artificial Intelligence',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    icon:<BiData />,
                    title:'Data Science',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    icon:<GiChemicalDrop />,
                    title:'Machine Learning',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
            ],
            publication:[
                {
                    img:pub1,
                    title:'How can we prevent a glaciers melting?',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                },
                {
                    img:pub2,
                    title:'Global warming: how to prevent a catastrophe. Arguments and conclusions.',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                },
                {
                    img:pub3,
                    title:'What type of proteins are useful?',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                }
            ],
            award:[
                {
                    totalAward:10
                },
                {
                    totalHonour:20
                },
                {
                    totalOther:15
                }
            ],
            teaching:{
                undergraduate:[
                    {
                        title:'CSC201 - Introduction to Java Programming Language',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC315 - Introduction to Information System',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC419 - Foundation of Programming Language',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    }
                ],
                postgraduate:[
                    {
                        title:'CSC601 - Introduction to Python Programming Language 1',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC619 - Introduction to Python Programming Language 2',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC791 - Introduction to Python Programming Language 3',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    }
                ]
            }
        },
        {
            name:'Prof. A.B. Adeyemi',
            image:cliImg1,
            altImg:bmsAltImg4,
            dept:'Obstetrics, Gynaecology & Perinatology',
            socialLinks:'',
            link:'adebanjo-adeyemi',
            phone:'09034897594',
            faculty:'Clinical Sciences',
            desig:'',
            experience:[
                {
                    year:'2021 - Present',
                    institution:'Computer Science Department, Obafemi Awolowo University,Ile-Ife, Osun State',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                // {
                //     year:'2020 - 2021',
                //     institution:'National Youth Service Corps (NYSC), Infomation Technology and Communication Unit (INTECU), Obafemi Awolowo University,Ile-Ife, Osun State',
                //     desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                // },
                {
                    year:'2014 - 2019',
                    institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    year:'2005 - 2013',
                    institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
             
               
            ],
            research:[
                {
                    icon:<GiArtificialIntelligence />,
                    title:'Artificial Intelligence',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    icon:<BiData />,
                    title:'Data Science',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    icon:<GiChemicalDrop />,
                    title:'Machine Learning',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
            ],
            publication:[
                {
                    img:pub1,
                    title:'How can we prevent a glaciers melting?',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                },
                {
                    img:pub2,
                    title:'Global warming: how to prevent a catastrophe. Arguments and conclusions.',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                },
                {
                    img:pub3,
                    title:'What type of proteins are useful?',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                }
            ],
            award:[
                {
                    totalAward:10
                },
                {
                    totalHonour:20
                },
                {
                    totalOther:15
                }
            ],
            teaching:{
                undergraduate:[
                    {
                        title:'CSC201 - Introduction to Java Programming Language',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC315 - Introduction to Information System',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC419 - Foundation of Programming Language',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    }
                ],
                postgraduate:[
                    {
                        title:'CSC601 - Introduction to Python Programming Language 1',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC619 - Introduction to Python Programming Language 2',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC791 - Introduction to Python Programming Language 3',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    }
                ]
            }
        },
        {
            name:'Dr. Olufemi Adefehinti',
            image:cliImg8,
            altImg:bmsAltImg4,
            dept:'Pediatrics and Child Health',
            socialLinks:'',
            link:'olufemi-adefehinti',
            phone:'09034897594',
            faculty:'Clinical Sciences',
            desig:'',
            experience:[
                {
                    year:'2021 - Present',
                    institution:'Computer Science Department, Obafemi Awolowo University,Ile-Ife, Osun State',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                // {
                //     year:'2020 - 2021',
                //     institution:'National Youth Service Corps (NYSC), Infomation Technology and Communication Unit (INTECU), Obafemi Awolowo University,Ile-Ife, Osun State',
                //     desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                // },
                {
                    year:'2014 - 2019',
                    institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    year:'2005 - 2013',
                    institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
             
               
            ],
            research:[
                {
                    icon:<GiArtificialIntelligence />,
                    title:'Artificial Intelligence',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    icon:<BiData />,
                    title:'Data Science',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    icon:<GiChemicalDrop />,
                    title:'Machine Learning',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
            ],
            publication:[
                {
                    img:pub1,
                    title:'How can we prevent a glaciers melting?',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                },
                {
                    img:pub2,
                    title:'Global warming: how to prevent a catastrophe. Arguments and conclusions.',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                },
                {
                    img:pub3,
                    title:'What type of proteins are useful?',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                }
            ],
            award:[
                {
                    totalAward:10
                },
                {
                    totalHonour:20
                },
                {
                    totalOther:15
                }
            ],
            teaching:{
                undergraduate:[
                    {
                        title:'CSC201 - Introduction to Java Programming Language',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC315 - Introduction to Information System',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC419 - Foundation of Programming Language',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    }
                ],
                postgraduate:[
                    {
                        title:'CSC601 - Introduction to Python Programming Language 1',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC619 - Introduction to Python Programming Language 2',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC791 - Introduction to Python Programming Language 3',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    }
                ]
            }
        },
        {
            name:'Prof. K.S. Mosaku',
            image:cliImg9,
            altImg:bmsAltImg4,
            dept:'Mental Health',
            socialLinks:'',
            link:'kolawole-mosaku',
            phone:'09034897594',
            faculty:'Clinical Sciences',
            desig:'',
            experience:[
                {
                    year:'2021 - Present',
                    institution:'Computer Science Department, Obafemi Awolowo University,Ile-Ife, Osun State',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                // {
                //     year:'2020 - 2021',
                //     institution:'National Youth Service Corps (NYSC), Infomation Technology and Communication Unit (INTECU), Obafemi Awolowo University,Ile-Ife, Osun State',
                //     desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                // },
                {
                    year:'2014 - 2019',
                    institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    year:'2005 - 2013',
                    institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
             
               
            ],
            research:[
                {
                    icon:<GiArtificialIntelligence />,
                    title:'Artificial Intelligence',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    icon:<BiData />,
                    title:'Data Science',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    icon:<GiChemicalDrop />,
                    title:'Machine Learning',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
            ],
            publication:[
                {
                    img:pub1,
                    title:'How can we prevent a glaciers melting?',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                },
                {
                    img:pub2,
                    title:'Global warming: how to prevent a catastrophe. Arguments and conclusions.',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                },
                {
                    img:pub3,
                    title:'What type of proteins are useful?',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                }
            ],
            award:[
                {
                    totalAward:10
                },
                {
                    totalHonour:20
                },
                {
                    totalOther:15
                }
            ],
            teaching:{
                undergraduate:[
                    {
                        title:'CSC201 - Introduction to Java Programming Language',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC315 - Introduction to Information System',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC419 - Foundation of Programming Language',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    }
                ],
                postgraduate:[
                    {
                        title:'CSC601 - Introduction to Python Programming Language 1',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC619 - Introduction to Python Programming Language 2',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC791 - Introduction to Python Programming Language 3',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    }
                ]
            }
        },
        {
            name:'Dr. A.O. Adetoye',
            image:cliImg10,
            altImg:bmsAltImg4,
            dept:'Anaesthesia and Intensive Care',
            socialLinks:'',
            link:'adedapo-adetoye',
            phone:'09034897594',
            faculty:'Clinical Sciences',
            desig:'',
            experience:[
                {
                    year:'2021 - Present',
                    institution:'Computer Science Department, Obafemi Awolowo University,Ile-Ife, Osun State',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                // {
                //     year:'2020 - 2021',
                //     institution:'National Youth Service Corps (NYSC), Infomation Technology and Communication Unit (INTECU), Obafemi Awolowo University,Ile-Ife, Osun State',
                //     desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                // },
                {
                    year:'2014 - 2019',
                    institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    year:'2005 - 2013',
                    institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
             
               
            ],
            research:[
                {
                    icon:<GiArtificialIntelligence />,
                    title:'Artificial Intelligence',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    icon:<BiData />,
                    title:'Data Science',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    icon:<GiChemicalDrop />,
                    title:'Machine Learning',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
            ],
            publication:[
                {
                    img:pub1,
                    title:'How can we prevent a glaciers melting?',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                },
                {
                    img:pub2,
                    title:'Global warming: how to prevent a catastrophe. Arguments and conclusions.',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                },
                {
                    img:pub3,
                    title:'What type of proteins are useful?',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                }
            ],
            award:[
                {
                    totalAward:10
                },
                {
                    totalHonour:20
                },
                {
                    totalOther:15
                }
            ],
            teaching:{
                undergraduate:[
                    {
                        title:'CSC201 - Introduction to Java Programming Language',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC315 - Introduction to Information System',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC419 - Foundation of Programming Language',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    }
                ],
                postgraduate:[
                    {
                        title:'CSC601 - Introduction to Python Programming Language 1',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC619 - Introduction to Python Programming Language 2',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC791 - Introduction to Python Programming Language 3',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    }
                ]
            }
        },
        {
            name:'Dr. O.I. Aaron',
            image:cliImg11,
            altImg:bmsAltImg4,
            dept:'Anaesthesia and Intensive Care',
            socialLinks:'',
            link:'olurotimi-aaron',
            phone:'09034897594',
            faculty:'Clinical Sciences',
            desig:'',
            experience:[
                {
                    year:'2021 - Present',
                    institution:'Computer Science Department, Obafemi Awolowo University,Ile-Ife, Osun State',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                // {
                //     year:'2020 - 2021',
                //     institution:'National Youth Service Corps (NYSC), Infomation Technology and Communication Unit (INTECU), Obafemi Awolowo University,Ile-Ife, Osun State',
                //     desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                // },
                {
                    year:'2014 - 2019',
                    institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    year:'2005 - 2013',
                    institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
             
               
            ],
            research:[
                {
                    icon:<GiArtificialIntelligence />,
                    title:'Artificial Intelligence',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    icon:<BiData />,
                    title:'Data Science',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    icon:<GiChemicalDrop />,
                    title:'Machine Learning',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
            ],
            publication:[
                {
                    img:pub1,
                    title:'How can we prevent a glaciers melting?',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                },
                {
                    img:pub2,
                    title:'Global warming: how to prevent a catastrophe. Arguments and conclusions.',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                },
                {
                    img:pub3,
                    title:'What type of proteins are useful?',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                }
            ],
            award:[
                {
                    totalAward:10
                },
                {
                    totalHonour:20
                },
                {
                    totalOther:15
                }
            ],
            teaching:{
                undergraduate:[
                    {
                        title:'CSC201 - Introduction to Java Programming Language',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC315 - Introduction to Information System',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC419 - Foundation of Programming Language',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    }
                ],
                postgraduate:[
                    {
                        title:'CSC601 - Introduction to Python Programming Language 1',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC619 - Introduction to Python Programming Language 2',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC791 - Introduction to Python Programming Language 3',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    }
                ]
            }
        },
        {
            name:'Dr. A.O. Adetoye ',
            image:cliImg13,
            altImg:bmsAltImg4,
            dept:'Anaesthesia and Intensive Care',
            socialLinks:'',
            link:'adedapo-adetoye',
            phone:'09034897594',
            faculty:'Clinical Sciences',
            desig:'',
            experience:[
                {
                    year:'2021 - Present',
                    institution:'Computer Science Department, Obafemi Awolowo University,Ile-Ife, Osun State',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                // {
                //     year:'2020 - 2021',
                //     institution:'National Youth Service Corps (NYSC), Infomation Technology and Communication Unit (INTECU), Obafemi Awolowo University,Ile-Ife, Osun State',
                //     desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                // },
                {
                    year:'2014 - 2019',
                    institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    year:'2005 - 2013',
                    institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
             
               
            ],
            research:[
                {
                    icon:<GiArtificialIntelligence />,
                    title:'Artificial Intelligence',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    icon:<BiData />,
                    title:'Data Science',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    icon:<GiChemicalDrop />,
                    title:'Machine Learning',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
            ],
            publication:[
                {
                    img:pub1,
                    title:'How can we prevent a glaciers melting?',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                },
                {
                    img:pub2,
                    title:'Global warming: how to prevent a catastrophe. Arguments and conclusions.',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                },
                {
                    img:pub3,
                    title:'What type of proteins are useful?',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                }
            ],
            award:[
                {
                    totalAward:10
                },
                {
                    totalHonour:20
                },
                {
                    totalOther:15
                }
            ],
            teaching:{
                undergraduate:[
                    {
                        title:'CSC201 - Introduction to Java Programming Language',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC315 - Introduction to Information System',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC419 - Foundation of Programming Language',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    }
                ],
                postgraduate:[
                    {
                        title:'CSC601 - Introduction to Python Programming Language 1',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC619 - Introduction to Python Programming Language 2',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC791 - Introduction to Python Programming Language 3',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    }
                ]
            }
        },
        {
            name:'Dr. A.E. Orimolade',
            image:cliImg14,
            altImg:bmsAltImg4,
            dept:'Orthopaedic Surgery and Traumatology',
            socialLinks:'',
            link:'ayodele-orimolade',
            phone:'09034897594',
            faculty:'Clinical Sciences',
            desig:'',
            experience:[
                {
                    year:'2021 - Present',
                    institution:'Computer Science Department, Obafemi Awolowo University,Ile-Ife, Osun State',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                // {
                //     year:'2020 - 2021',
                //     institution:'National Youth Service Corps (NYSC), Infomation Technology and Communication Unit (INTECU), Obafemi Awolowo University,Ile-Ife, Osun State',
                //     desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                // },
                {
                    year:'2014 - 2019',
                    institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    year:'2005 - 2013',
                    institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
             
               
            ],
            research:[
                {
                    icon:<GiArtificialIntelligence />,
                    title:'Artificial Intelligence',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    icon:<BiData />,
                    title:'Data Science',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    icon:<GiChemicalDrop />,
                    title:'Machine Learning',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
            ],
            publication:[
                {
                    img:pub1,
                    title:'How can we prevent a glaciers melting?',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                },
                {
                    img:pub2,
                    title:'Global warming: how to prevent a catastrophe. Arguments and conclusions.',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                },
                {
                    img:pub3,
                    title:'What type of proteins are useful?',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                }
            ],
            award:[
                {
                    totalAward:10
                },
                {
                    totalHonour:20
                },
                {
                    totalOther:15
                }
            ],
            teaching:{
                undergraduate:[
                    {
                        title:'CSC201 - Introduction to Java Programming Language',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC315 - Introduction to Information System',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC419 - Foundation of Programming Language',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    }
                ],
                postgraduate:[
                    {
                        title:'CSC601 - Introduction to Python Programming Language 1',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC619 - Introduction to Python Programming Language 2',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC791 - Introduction to Python Programming Language 3',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    }
                ]
            }
        },
        {
            name:'Prof. B.A. Kolawole',
            image:cliImg15,
            altImg:bmsAltImg4,
            dept:'Medicine',
            socialLinks:'',
            link:'babatope-kolawole',
            phone:'09034897594',
            faculty:'Clinical Sciences',
            desig:'',
            experience:[
                {
                    year:'2021 - Present',
                    institution:'Computer Science Department, Obafemi Awolowo University,Ile-Ife, Osun State',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                // {
                //     year:'2020 - 2021',
                //     institution:'National Youth Service Corps (NYSC), Infomation Technology and Communication Unit (INTECU), Obafemi Awolowo University,Ile-Ife, Osun State',
                //     desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                // },
                {
                    year:'2014 - 2019',
                    institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    year:'2005 - 2013',
                    institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
             
               
            ],
            research:[
                {
                    icon:<GiArtificialIntelligence />,
                    title:'Artificial Intelligence',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    icon:<BiData />,
                    title:'Data Science',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    icon:<GiChemicalDrop />,
                    title:'Machine Learning',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
            ],
            publication:[
                {
                    img:pub1,
                    title:'How can we prevent a glaciers melting?',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                },
                {
                    img:pub2,
                    title:'Global warming: how to prevent a catastrophe. Arguments and conclusions.',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                },
                {
                    img:pub3,
                    title:'What type of proteins are useful?',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                }
            ],
            award:[
                {
                    totalAward:10
                },
                {
                    totalHonour:20
                },
                {
                    totalOther:15
                }
            ],
            teaching:{
                undergraduate:[
                    {
                        title:'CSC201 - Introduction to Java Programming Language',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC315 - Introduction to Information System',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC419 - Foundation of Programming Language',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    }
                ],
                postgraduate:[
                    {
                        title:'CSC601 - Introduction to Python Programming Language 1',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC619 - Introduction to Python Programming Language 2',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC791 - Introduction to Python Programming Language 3',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    }
                ]
            }
        },
        {
            name:'Dr. O.O. Aloba',
            image:cliImg21,
            altImg:bmsAltImg4,
            dept:'Mental Health',
            socialLinks:'',
            link:'olutayo-aloba',
            phone:'09034897594',
            faculty:'Clinical Sciences',
            desig:'',
            experience:[
                {
                    year:'2021 - Present',
                    institution:'Computer Science Department, Obafemi Awolowo University,Ile-Ife, Osun State',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                // {
                //     year:'2020 - 2021',
                //     institution:'National Youth Service Corps (NYSC), Infomation Technology and Communication Unit (INTECU), Obafemi Awolowo University,Ile-Ife, Osun State',
                //     desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                // },
                {
                    year:'2014 - 2019',
                    institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    year:'2005 - 2013',
                    institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
             
               
            ],
            research:[
                {
                    icon:<GiArtificialIntelligence />,
                    title:'Artificial Intelligence',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    icon:<BiData />,
                    title:'Data Science',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    icon:<GiChemicalDrop />,
                    title:'Machine Learning',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
            ],
            publication:[
                {
                    img:pub1,
                    title:'How can we prevent a glaciers melting?',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                },
                {
                    img:pub2,
                    title:'Global warming: how to prevent a catastrophe. Arguments and conclusions.',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                },
                {
                    img:pub3,
                    title:'What type of proteins are useful?',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                }
            ],
            award:[
                {
                    totalAward:10
                },
                {
                    totalHonour:20
                },
                {
                    totalOther:15
                }
            ],
            teaching:{
                undergraduate:[
                    {
                        title:'CSC201 - Introduction to Java Programming Language',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC315 - Introduction to Information System',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC419 - Foundation of Programming Language',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    }
                ],
                postgraduate:[
                    {
                        title:'CSC601 - Introduction to Python Programming Language 1',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC619 - Introduction to Python Programming Language 2',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC791 - Introduction to Python Programming Language 3',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    }
                ]
            }
        },
        {
            name:'Prof. M.A. Komolafe',
            image:cliImg17,
            altImg:bmsAltImg4,
            dept:'Medicine',
            socialLinks:'',
            link:'morenikeji-komolafe',
            phone:'09034897594',
            faculty:'Clinical Sciences',
            desig:'',
            experience:[
                {
                    year:'2021 - Present',
                    institution:'Computer Science Department, Obafemi Awolowo University,Ile-Ife, Osun State',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                // {
                //     year:'2020 - 2021',
                //     institution:'National Youth Service Corps (NYSC), Infomation Technology and Communication Unit (INTECU), Obafemi Awolowo University,Ile-Ife, Osun State',
                //     desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                // },
                {
                    year:'2014 - 2019',
                    institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    year:'2005 - 2013',
                    institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
             
               
            ],
            research:[
                {
                    icon:<GiArtificialIntelligence />,
                    title:'Artificial Intelligence',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    icon:<BiData />,
                    title:'Data Science',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    icon:<GiChemicalDrop />,
                    title:'Machine Learning',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
            ],
            publication:[
                {
                    img:pub1,
                    title:'How can we prevent a glaciers melting?',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                },
                {
                    img:pub2,
                    title:'Global warming: how to prevent a catastrophe. Arguments and conclusions.',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                },
                {
                    img:pub3,
                    title:'What type of proteins are useful?',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                }
            ],
            award:[
                {
                    totalAward:10
                },
                {
                    totalHonour:20
                },
                {
                    totalOther:15
                }
            ],
            teaching:{
                undergraduate:[
                    {
                        title:'CSC201 - Introduction to Java Programming Language',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC315 - Introduction to Information System',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC419 - Foundation of Programming Language',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    }
                ],
                postgraduate:[
                    {
                        title:'CSC601 - Introduction to Python Programming Language 1',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC619 - Introduction to Python Programming Language 2',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC791 - Introduction to Python Programming Language 3',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    }
                ]
            }
        },
       
        {
            name:'Dr. M.D. Olodu',
            image:cliImg19,
            altImg:bmsAltImg4,
            dept:'Community Health',
            socialLinks:'',
            link:'monday-olodu',
            phone:'09034897594',
            faculty:'Clinical Sciences',
            desig:'',
            experience:[
                {
                    year:'2021 - Present',
                    institution:'Computer Science Department, Obafemi Awolowo University,Ile-Ife, Osun State',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                // {
                //     year:'2020 - 2021',
                //     institution:'National Youth Service Corps (NYSC), Infomation Technology and Communication Unit (INTECU), Obafemi Awolowo University,Ile-Ife, Osun State',
                //     desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                // },
                {
                    year:'2014 - 2019',
                    institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    year:'2005 - 2013',
                    institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
             
               
            ],
            research:[
                {
                    icon:<GiArtificialIntelligence />,
                    title:'Artificial Intelligence',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    icon:<BiData />,
                    title:'Data Science',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    icon:<GiChemicalDrop />,
                    title:'Machine Learning',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
            ],
            publication:[
                {
                    img:pub1,
                    title:'How can we prevent a glaciers melting?',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                },
                {
                    img:pub2,
                    title:'Global warming: how to prevent a catastrophe. Arguments and conclusions.',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                },
                {
                    img:pub3,
                    title:'What type of proteins are useful?',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                }
            ],
            award:[
                {
                    totalAward:10
                },
                {
                    totalHonour:20
                },
                {
                    totalOther:15
                }
            ],
            teaching:{
                undergraduate:[
                    {
                        title:'CSC201 - Introduction to Java Programming Language',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC315 - Introduction to Information System',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC419 - Foundation of Programming Language',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    }
                ],
                postgraduate:[
                    {
                        title:'CSC601 - Introduction to Python Programming Language 1',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC619 - Introduction to Python Programming Language 2',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC791 - Introduction to Python Programming Language 3',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    }
                ]
            }
        },
        {
            name:'Dr. A.T. Adenekan',
            image:cliImg20,
            altImg:bmsAltImg4,
            dept:'Anaesthesia',
            socialLinks:'',
            link:'anthony-adenekan',
            phone:'09034897594',
            faculty:'Clinical Sciences',
            desig:'',
            experience:[
                {
                    year:'2021 - Present',
                    institution:'Computer Science Department, Obafemi Awolowo University,Ile-Ife, Osun State',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                // {
                //     year:'2020 - 2021',
                //     institution:'National Youth Service Corps (NYSC), Infomation Technology and Communication Unit (INTECU), Obafemi Awolowo University,Ile-Ife, Osun State',
                //     desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                // },
                {
                    year:'2014 - 2019',
                    institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    year:'2005 - 2013',
                    institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
             
               
            ],
            research:[
                {
                    icon:<GiArtificialIntelligence />,
                    title:'Artificial Intelligence',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    icon:<BiData />,
                    title:'Data Science',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    icon:<GiChemicalDrop />,
                    title:'Machine Learning',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
            ],
            publication:[
                {
                    img:pub1,
                    title:'How can we prevent a glaciers melting?',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                },
                {
                    img:pub2,
                    title:'Global warming: how to prevent a catastrophe. Arguments and conclusions.',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                },
                {
                    img:pub3,
                    title:'What type of proteins are useful?',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                }
            ],
            award:[
                {
                    totalAward:10
                },
                {
                    totalHonour:20
                },
                {
                    totalOther:15
                }
            ],
            teaching:{
                undergraduate:[
                    {
                        title:'CSC201 - Introduction to Java Programming Language',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC315 - Introduction to Information System',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC419 - Foundation of Programming Language',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    }
                ],
                postgraduate:[
                    {
                        title:'CSC601 - Introduction to Python Programming Language 1',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC619 - Introduction to Python Programming Language 2',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC791 - Introduction to Python Programming Language 3',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    }
                ]
            }
        },
        {
            name:'Dr. D.O. Soyoye',
            image:cliImg22,
            altImg:bmsAltImg4,
            dept:'Medicine',
            socialLinks:'',
            link:'david-soyoye',
            phone:'09034897594',
            faculty:'Clinical Sciences',
            desig:'',
            experience:[
                {
                    year:'2021 - Present',
                    institution:'Computer Science Department, Obafemi Awolowo University,Ile-Ife, Osun State',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                // {
                //     year:'2020 - 2021',
                //     institution:'National Youth Service Corps (NYSC), Infomation Technology and Communication Unit (INTECU), Obafemi Awolowo University,Ile-Ife, Osun State',
                //     desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                // },
                {
                    year:'2014 - 2019',
                    institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    year:'2005 - 2013',
                    institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
             
               
            ],
            research:[
                {
                    icon:<GiArtificialIntelligence />,
                    title:'Artificial Intelligence',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    icon:<BiData />,
                    title:'Data Science',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    icon:<GiChemicalDrop />,
                    title:'Machine Learning',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
            ],
            publication:[
                {
                    img:pub1,
                    title:'How can we prevent a glaciers melting?',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                },
                {
                    img:pub2,
                    title:'Global warming: how to prevent a catastrophe. Arguments and conclusions.',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                },
                {
                    img:pub3,
                    title:'What type of proteins are useful?',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                }
            ],
            award:[
                {
                    totalAward:10
                },
                {
                    totalHonour:20
                },
                {
                    totalOther:15
                }
            ],
            teaching:{
                undergraduate:[
                    {
                        title:'CSC201 - Introduction to Java Programming Language',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC315 - Introduction to Information System',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC419 - Foundation of Programming Language',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    }
                ],
                postgraduate:[
                    {
                        title:'CSC601 - Introduction to Python Programming Language 1',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC619 - Introduction to Python Programming Language 2',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC791 - Introduction to Python Programming Language 3',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    }
                ]
            }
        },
        {
            name:'Dr. P.O. Obiajunwa',
            image:cliImg23,
            altImg:bmsAltImg4,
            dept:'Paediatrics and Child Health',
            socialLinks:'',
            link:'perpetua-obiajunwa',
            phone:'09034897594',
            faculty:'Clinical Sciences',
            desig:'',
            experience:[
                {
                    year:'2021 - Present',
                    institution:'Computer Science Department, Obafemi Awolowo University,Ile-Ife, Osun State',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                // {
                //     year:'2020 - 2021',
                //     institution:'National Youth Service Corps (NYSC), Infomation Technology and Communication Unit (INTECU), Obafemi Awolowo University,Ile-Ife, Osun State',
                //     desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                // },
                {
                    year:'2014 - 2019',
                    institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    year:'2005 - 2013',
                    institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
             
               
            ],
            research:[
                {
                    icon:<GiArtificialIntelligence />,
                    title:'Artificial Intelligence',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    icon:<BiData />,
                    title:'Data Science',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    icon:<GiChemicalDrop />,
                    title:'Machine Learning',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
            ],
            publication:[
                {
                    img:pub1,
                    title:'How can we prevent a glaciers melting?',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                },
                {
                    img:pub2,
                    title:'Global warming: how to prevent a catastrophe. Arguments and conclusions.',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                },
                {
                    img:pub3,
                    title:'What type of proteins are useful?',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                }
            ],
            award:[
                {
                    totalAward:10
                },
                {
                    totalHonour:20
                },
                {
                    totalOther:15
                }
            ],
            teaching:{
                undergraduate:[
                    {
                        title:'CSC201 - Introduction to Java Programming Language',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC315 - Introduction to Information System',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC419 - Foundation of Programming Language',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    }
                ],
                postgraduate:[
                    {
                        title:'CSC601 - Introduction to Python Programming Language 1',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC619 - Introduction to Python Programming Language 2',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC791 - Introduction to Python Programming Language 3',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    }
                ]
            }
        },
        {
            name:'Prof. W.A. Olowu',
            image:cliImg24,
            altImg:bmsAltImg4,
            dept:'Paediatrics and Child Health',
            socialLinks:'',
            link:'wasiu-olowu',
            phone:'09034897594',
            faculty:'Clinical Sciences',
            desig:'',
            experience:[
                {
                    year:'2021 - Present',
                    institution:'Computer Science Department, Obafemi Awolowo University,Ile-Ife, Osun State',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                // {
                //     year:'2020 - 2021',
                //     institution:'National Youth Service Corps (NYSC), Infomation Technology and Communication Unit (INTECU), Obafemi Awolowo University,Ile-Ife, Osun State',
                //     desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                // },
                {
                    year:'2014 - 2019',
                    institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    year:'2005 - 2013',
                    institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
             
               
            ],
            research:[
                {
                    icon:<GiArtificialIntelligence />,
                    title:'Artificial Intelligence',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    icon:<BiData />,
                    title:'Data Science',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    icon:<GiChemicalDrop />,
                    title:'Machine Learning',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
            ],
            publication:[
                {
                    img:pub1,
                    title:'How can we prevent a glaciers melting?',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                },
                {
                    img:pub2,
                    title:'Global warming: how to prevent a catastrophe. Arguments and conclusions.',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                },
                {
                    img:pub3,
                    title:'What type of proteins are useful?',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                }
            ],
            award:[
                {
                    totalAward:10
                },
                {
                    totalHonour:20
                },
                {
                    totalOther:15
                }
            ],
            teaching:{
                undergraduate:[
                    {
                        title:'CSC201 - Introduction to Java Programming Language',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC315 - Introduction to Information System',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC419 - Foundation of Programming Language',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    }
                ],
                postgraduate:[
                    {
                        title:'CSC601 - Introduction to Python Programming Language 1',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC619 - Introduction to Python Programming Language 2',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC791 - Introduction to Python Programming Language 3',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    }
                ]
            }
        },
        {
            name:'Dr. O.I. Ibigbami',
            image:cliImg25,
            altImg:bmsAltImg4,
            dept:'Mental Health',
            socialLinks:'',
            link:'olanrewaju-ibigbami',
            phone:'09034897594',
            faculty:'Clinical Sciences',
            desig:'',
            experience:[
                {
                    year:'2021 - Present',
                    institution:'Computer Science Department, Obafemi Awolowo University,Ile-Ife, Osun State',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                // {
                //     year:'2020 - 2021',
                //     institution:'National Youth Service Corps (NYSC), Infomation Technology and Communication Unit (INTECU), Obafemi Awolowo University,Ile-Ife, Osun State',
                //     desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                // },
                {
                    year:'2014 - 2019',
                    institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    year:'2005 - 2013',
                    institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
             
               
            ],
            research:[
                {
                    icon:<GiArtificialIntelligence />,
                    title:'Artificial Intelligence',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    icon:<BiData />,
                    title:'Data Science',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    icon:<GiChemicalDrop />,
                    title:'Machine Learning',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
            ],
            publication:[
                {
                    img:pub1,
                    title:'How can we prevent a glaciers melting?',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                },
                {
                    img:pub2,
                    title:'Global warming: how to prevent a catastrophe. Arguments and conclusions.',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                },
                {
                    img:pub3,
                    title:'What type of proteins are useful?',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                }
            ],
            award:[
                {
                    totalAward:10
                },
                {
                    totalHonour:20
                },
                {
                    totalOther:15
                }
            ],
            teaching:{
                undergraduate:[
                    {
                        title:'CSC201 - Introduction to Java Programming Language',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC315 - Introduction to Information System',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC419 - Foundation of Programming Language',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    }
                ],
                postgraduate:[
                    {
                        title:'CSC601 - Introduction to Python Programming Language 1',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC619 - Introduction to Python Programming Language 2',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC791 - Introduction to Python Programming Language 3',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    }
                ]
            }
        },
        {
            name:'Prof. J.A. Okeniyi',
            image:cliImg26,
            altImg:bmsAltImg4,
            dept:'Paediatrics and Child Health',
            socialLinks:'',
            link:'john-okeniyi',
            phone:'09034897594',
            faculty:'Clinical Sciences',
            desig:'',
            experience:[
                {
                    year:'2021 - Present',
                    institution:'Computer Science Department, Obafemi Awolowo University,Ile-Ife, Osun State',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                // {
                //     year:'2020 - 2021',
                //     institution:'National Youth Service Corps (NYSC), Infomation Technology and Communication Unit (INTECU), Obafemi Awolowo University,Ile-Ife, Osun State',
                //     desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                // },
                {
                    year:'2014 - 2019',
                    institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    year:'2005 - 2013',
                    institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
             
               
            ],
            research:[
                {
                    icon:<GiArtificialIntelligence />,
                    title:'Artificial Intelligence',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    icon:<BiData />,
                    title:'Data Science',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    icon:<GiChemicalDrop />,
                    title:'Machine Learning',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
            ],
            publication:[
                {
                    img:pub1,
                    title:'How can we prevent a glaciers melting?',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                },
                {
                    img:pub2,
                    title:'Global warming: how to prevent a catastrophe. Arguments and conclusions.',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                },
                {
                    img:pub3,
                    title:'What type of proteins are useful?',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                }
            ],
            award:[
                {
                    totalAward:10
                },
                {
                    totalHonour:20
                },
                {
                    totalOther:15
                }
            ],
            teaching:{
                undergraduate:[
                    {
                        title:'CSC201 - Introduction to Java Programming Language',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC315 - Introduction to Information System',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC419 - Foundation of Programming Language',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    }
                ],
                postgraduate:[
                    {
                        title:'CSC601 - Introduction to Python Programming Language 1',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC619 - Introduction to Python Programming Language 2',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC791 - Introduction to Python Programming Language 3',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    }
                ]
            }
        },
        {
            name:'Dr. O.T. Bamigboye',
            image:cliImg27,
            altImg:bmsAltImg4,
            dept:'Paediatrics and Child Health',
            socialLinks:'',
            link:'olukemi-bamigboye',
            phone:'09034897594',
            faculty:'Clinical Sciences',
            desig:'',
            experience:[
                {
                    year:'2021 - Present',
                    institution:'Computer Science Department, Obafemi Awolowo University,Ile-Ife, Osun State',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                // {
                //     year:'2020 - 2021',
                //     institution:'National Youth Service Corps (NYSC), Infomation Technology and Communication Unit (INTECU), Obafemi Awolowo University,Ile-Ife, Osun State',
                //     desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                // },
                {
                    year:'2014 - 2019',
                    institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    year:'2005 - 2013',
                    institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
             
               
            ],
            research:[
                {
                    icon:<GiArtificialIntelligence />,
                    title:'Artificial Intelligence',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    icon:<BiData />,
                    title:'Data Science',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    icon:<GiChemicalDrop />,
                    title:'Machine Learning',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
            ],
            publication:[
                {
                    img:pub1,
                    title:'How can we prevent a glaciers melting?',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                },
                {
                    img:pub2,
                    title:'Global warming: how to prevent a catastrophe. Arguments and conclusions.',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                },
                {
                    img:pub3,
                    title:'What type of proteins are useful?',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                }
            ],
            award:[
                {
                    totalAward:10
                },
                {
                    totalHonour:20
                },
                {
                    totalOther:15
                }
            ],
            teaching:{
                undergraduate:[
                    {
                        title:'CSC201 - Introduction to Java Programming Language',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC315 - Introduction to Information System',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC419 - Foundation of Programming Language',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    }
                ],
                postgraduate:[
                    {
                        title:'CSC601 - Introduction to Python Programming Language 1',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC619 - Introduction to Python Programming Language 2',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC791 - Introduction to Python Programming Language 3',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    }
                ]
            }
        },

        
        
    ],





// FACULTY OF DENTISTRY
    den:[
        {
            name:'Prof. Morenike Ukpong',
            image:denImg1,
            altImg:bmsAltImg4,
            dept:'Child Dental Health',
            socialLinks:'',
            link:'morenike-ukpong',
            phone:'09034897594',
            faculty:'Dentistry',
            desig:'Dean',
            experience:[
                {
                    year:'2021 - Present',
                    institution:'Computer Science Department, Obafemi Awolowo University,Ile-Ife, Osun State',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                // {
                //     year:'2020 - 2021',
                //     institution:'National Youth Service Corps (NYSC), Infomation Technology and Communication Unit (INTECU), Obafemi Awolowo University,Ile-Ife, Osun State',
                //     desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                // },
                {
                    year:'2014 - 2019',
                    institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    year:'2005 - 2013',
                    institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
             
               
            ],
            research:[
                {
                    icon:<GiArtificialIntelligence />,
                    title:'Artificial Intelligence',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    icon:<BiData />,
                    title:'Data Science',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    icon:<GiChemicalDrop />,
                    title:'Machine Learning',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
            ],
            publication:[
                {
                    img:pub1,
                    title:'How can we prevent a glaciers melting?',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                },
                {
                    img:pub2,
                    title:'Global warming: how to prevent a catastrophe. Arguments and conclusions.',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                },
                {
                    img:pub3,
                    title:'What type of proteins are useful?',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                }
            ],
            award:[
                {
                    totalAward:10
                },
                {
                    totalHonour:20
                },
                {
                    totalOther:15
                }
            ],
            teaching:{
                undergraduate:[
                    {
                        title:'CSC201 - Introduction to Java Programming Language',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC315 - Introduction to Information System',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC419 - Foundation of Programming Language',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    }
                ],
                postgraduate:[
                    {
                        title:'CSC601 - Introduction to Python Programming Language 1',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC619 - Introduction to Python Programming Language 2',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC791 - Introduction to Python Programming Language 3',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    }
                ]
            }
        },
        {
            name:'Dr. B.A. Aregbesola',
            image:denImg2,
            altImg:bmsAltImg4,
            dept:'Oral and Maxillofacial Surgery',
            socialLinks:'',
            link:'no-name',
            phone:'09034897594',
            faculty:'Dentistry',
            desig:'Vice Dean',
            experience:[
                {
                    year:'2021 - Present',
                    institution:'Computer Science Department, Obafemi Awolowo University,Ile-Ife, Osun State',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                // {
                //     year:'2020 - 2021',
                //     institution:'National Youth Service Corps (NYSC), Infomation Technology and Communication Unit (INTECU), Obafemi Awolowo University,Ile-Ife, Osun State',
                //     desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                // },
                {
                    year:'2014 - 2019',
                    institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    year:'2005 - 2013',
                    institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
             
               
            ],
            research:[
                {
                    icon:<GiArtificialIntelligence />,
                    title:'Artificial Intelligence',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    icon:<BiData />,
                    title:'Data Science',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    icon:<GiChemicalDrop />,
                    title:'Machine Learning',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
            ],
            publication:[
                {
                    img:pub1,
                    title:'How can we prevent a glaciers melting?',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                },
                {
                    img:pub2,
                    title:'Global warming: how to prevent a catastrophe. Arguments and conclusions.',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                },
                {
                    img:pub3,
                    title:'What type of proteins are useful?',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                }
            ],
            award:[
                {
                    totalAward:10
                },
                {
                    totalHonour:20
                },
                {
                    totalOther:15
                }
            ],
            teaching:{
                undergraduate:[
                    {
                        title:'CSC201 - Introduction to Java Programming Language',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC315 - Introduction to Information System',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC419 - Foundation of Programming Language',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    }
                ],
                postgraduate:[
                    {
                        title:'CSC601 - Introduction to Python Programming Language 1',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC619 - Introduction to Python Programming Language 2',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC791 - Introduction to Python Programming Language 3',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    }
                ]
            }
        },
        {
            name:'Prof. K.A. Kolawole',
            image:denImg3,
            altImg:bmsAltImg4,
            dept:'Child Dental Health',
            socialLinks:'',
            link:'kikelomo-kolawole',
            phone:'09034897594',
            faculty:'Dentistry',
            desig:'',
            experience:[
                {
                    year:'2021 - Present',
                    institution:'Computer Science Department, Obafemi Awolowo University,Ile-Ife, Osun State',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                // {
                //     year:'2020 - 2021',
                //     institution:'National Youth Service Corps (NYSC), Infomation Technology and Communication Unit (INTECU), Obafemi Awolowo University,Ile-Ife, Osun State',
                //     desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                // },
                {
                    year:'2014 - 2019',
                    institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    year:'2005 - 2013',
                    institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
             
               
            ],
            research:[
                {
                    icon:<GiArtificialIntelligence />,
                    title:'Artificial Intelligence',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    icon:<BiData />,
                    title:'Data Science',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    icon:<GiChemicalDrop />,
                    title:'Machine Learning',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
            ],
            publication:[
                {
                    img:pub1,
                    title:'How can we prevent a glaciers melting?',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                },
                {
                    img:pub2,
                    title:'Global warming: how to prevent a catastrophe. Arguments and conclusions.',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                },
                {
                    img:pub3,
                    title:'What type of proteins are useful?',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                }
            ],
            award:[
                {
                    totalAward:10
                },
                {
                    totalHonour:20
                },
                {
                    totalOther:15
                }
            ],
            teaching:{
                undergraduate:[
                    {
                        title:'CSC201 - Introduction to Java Programming Language',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC315 - Introduction to Information System',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC419 - Foundation of Programming Language',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    }
                ],
                postgraduate:[
                    {
                        title:'CSC601 - Introduction to Python Programming Language 1',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC619 - Introduction to Python Programming Language 2',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC791 - Introduction to Python Programming Language 3',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    }
                ]
            }
        },
        {
            name:'Dr. S.O. Nwhator',
            image:denImg4,
            altImg:bmsAltImg4,
            dept:'Preventive &Community Dentistry',
            socialLinks:'',
            link:'solomon-nwhator',
            phone:'09034897594',
            faculty:'Dentistry',
            desig:'',
            experience:[
                {
                    year:'2021 - Present',
                    institution:'Computer Science Department, Obafemi Awolowo University,Ile-Ife, Osun State',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                // {
                //     year:'2020 - 2021',
                //     institution:'National Youth Service Corps (NYSC), Infomation Technology and Communication Unit (INTECU), Obafemi Awolowo University,Ile-Ife, Osun State',
                //     desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                // },
                {
                    year:'2014 - 2019',
                    institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    year:'2005 - 2013',
                    institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
             
               
            ],
            research:[
                {
                    icon:<GiArtificialIntelligence />,
                    title:'Artificial Intelligence',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    icon:<BiData />,
                    title:'Data Science',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    icon:<GiChemicalDrop />,
                    title:'Machine Learning',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
            ],
            publication:[
                {
                    img:pub1,
                    title:'How can we prevent a glaciers melting?',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                },
                {
                    img:pub2,
                    title:'Global warming: how to prevent a catastrophe. Arguments and conclusions.',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                },
                {
                    img:pub3,
                    title:'What type of proteins are useful?',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                }
            ],
            award:[
                {
                    totalAward:10
                },
                {
                    totalHonour:20
                },
                {
                    totalOther:15
                }
            ],
            teaching:{
                undergraduate:[
                    {
                        title:'CSC201 - Introduction to Java Programming Language',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC315 - Introduction to Information System',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC419 - Foundation of Programming Language',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    }
                ],
                postgraduate:[
                    {
                        title:'CSC601 - Introduction to Python Programming Language 1',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC619 - Introduction to Python Programming Language 2',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC791 - Introduction to Python Programming Language 3',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    }
                ]
            }
        },
        {
            name:'Dr. O.E. Olufemi',
            image:denImg5,
            altImg:bmsAltImg4,
            dept:'Oral Medicine and Oral Pathology',
            socialLinks:'',
            link:'oyetola-olufemi',
            phone:'09034897594',
            faculty:'Dentistry',
            desig:'',
            experience:[
                {
                    year:'2021 - Present',
                    institution:'Computer Science Department, Obafemi Awolowo University,Ile-Ife, Osun State',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                // {
                //     year:'2020 - 2021',
                //     institution:'National Youth Service Corps (NYSC), Infomation Technology and Communication Unit (INTECU), Obafemi Awolowo University,Ile-Ife, Osun State',
                //     desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                // },
                {
                    year:'2014 - 2019',
                    institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    year:'2005 - 2013',
                    institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
             
               
            ],
            research:[
                {
                    icon:<GiArtificialIntelligence />,
                    title:'Artificial Intelligence',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    icon:<BiData />,
                    title:'Data Science',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    icon:<GiChemicalDrop />,
                    title:'Machine Learning',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
            ],
            publication:[
                {
                    img:pub1,
                    title:'How can we prevent a glaciers melting?',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                },
                {
                    img:pub2,
                    title:'Global warming: how to prevent a catastrophe. Arguments and conclusions.',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                },
                {
                    img:pub3,
                    title:'What type of proteins are useful?',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                }
            ],
            award:[
                {
                    totalAward:10
                },
                {
                    totalHonour:20
                },
                {
                    totalOther:15
                }
            ],
            teaching:{
                undergraduate:[
                    {
                        title:'CSC201 - Introduction to Java Programming Language',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC315 - Introduction to Information System',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC419 - Foundation of Programming Language',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    }
                ],
                postgraduate:[
                    {
                        title:'CSC601 - Introduction to Python Programming Language 1',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC619 - Introduction to Python Programming Language 2',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC791 - Introduction to Python Programming Language 3',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    }
                ]
            }
        },
        {
            name:'Dr. B.A. Famurewa',
            image:denImg6,
            altImg:bmsAltImg4,
            dept:'Oral and Maxillofacial Surgery',
            socialLinks:'',
            link:'bamidele-famurewa',
            phone:'09034897594',
            faculty:'Dentistry',
            desig:'',
            experience:[
                {
                    year:'2021 - Present',
                    institution:'Computer Science Department, Obafemi Awolowo University,Ile-Ife, Osun State',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                // {
                //     year:'2020 - 2021',
                //     institution:'National Youth Service Corps (NYSC), Infomation Technology and Communication Unit (INTECU), Obafemi Awolowo University,Ile-Ife, Osun State',
                //     desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                // },
                {
                    year:'2014 - 2019',
                    institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    year:'2005 - 2013',
                    institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
             
               
            ],
            research:[
                {
                    icon:<GiArtificialIntelligence />,
                    title:'Artificial Intelligence',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    icon:<BiData />,
                    title:'Data Science',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    icon:<GiChemicalDrop />,
                    title:'Machine Learning',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
            ],
            publication:[
                {
                    img:pub1,
                    title:'How can we prevent a glaciers melting?',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                },
                {
                    img:pub2,
                    title:'Global warming: how to prevent a catastrophe. Arguments and conclusions.',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                },
                {
                    img:pub3,
                    title:'What type of proteins are useful?',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                }
            ],
            award:[
                {
                    totalAward:10
                },
                {
                    totalHonour:20
                },
                {
                    totalOther:15
                }
            ],
            teaching:{
                undergraduate:[
                    {
                        title:'CSC201 - Introduction to Java Programming Language',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC315 - Introduction to Information System',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC419 - Foundation of Programming Language',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    }
                ],
                postgraduate:[
                    {
                        title:'CSC601 - Introduction to Python Programming Language 1',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC619 - Introduction to Python Programming Language 2',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC791 - Introduction to Python Programming Language 3',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    }
                ]
            }
        },
        {
            name:'Prof. F.J. Owotade',
            image:denImg7,
            altImg:bmsAltImg4,
            dept:'Oral Medicine and Oral Pathology',
            socialLinks:'',
            link:'foluso-owotade',
            phone:'09034897594',
            faculty:'Dentistry',
            desig:'',
            experience:[
                {
                    year:'2021 - Present',
                    institution:'Computer Science Department, Obafemi Awolowo University,Ile-Ife, Osun State',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                // {
                //     year:'2020 - 2021',
                //     institution:'National Youth Service Corps (NYSC), Infomation Technology and Communication Unit (INTECU), Obafemi Awolowo University,Ile-Ife, Osun State',
                //     desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                // },
                {
                    year:'2014 - 2019',
                    institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    year:'2005 - 2013',
                    institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
             
               
            ],
            research:[
                {
                    icon:<GiArtificialIntelligence />,
                    title:'Artificial Intelligence',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    icon:<BiData />,
                    title:'Data Science',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    icon:<GiChemicalDrop />,
                    title:'Machine Learning',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
            ],
            publication:[
                {
                    img:pub1,
                    title:'How can we prevent a glaciers melting?',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                },
                {
                    img:pub2,
                    title:'Global warming: how to prevent a catastrophe. Arguments and conclusions.',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                },
                {
                    img:pub3,
                    title:'What type of proteins are useful?',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                }
            ],
            award:[
                {
                    totalAward:10
                },
                {
                    totalHonour:20
                },
                {
                    totalOther:15
                }
            ],
            teaching:{
                undergraduate:[
                    {
                        title:'CSC201 - Introduction to Java Programming Language',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC315 - Introduction to Information System',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC419 - Foundation of Programming Language',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    }
                ],
                postgraduate:[
                    {
                        title:'CSC601 - Introduction to Python Programming Language 1',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC619 - Introduction to Python Programming Language 2',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC791 - Introduction to Python Programming Language 3',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    }
                ]
            }
        },
        {
            name:'Dr. O.K. Ogundipe',
            image:denImg8,
            altImg:bmsAltImg4,
            dept:'Oral Maxillofacial Surgery',
            socialLinks:'',
            link:'olufemi-ogundipe',
            phone:'09034897594',
            faculty:'Dentistry',
            desig:'',
            experience:[
                {
                    year:'2021 - Present',
                    institution:'Computer Science Department, Obafemi Awolowo University,Ile-Ife, Osun State',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                // {
                //     year:'2020 - 2021',
                //     institution:'National Youth Service Corps (NYSC), Infomation Technology and Communication Unit (INTECU), Obafemi Awolowo University,Ile-Ife, Osun State',
                //     desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                // },
                {
                    year:'2014 - 2019',
                    institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    year:'2005 - 2013',
                    institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
             
               
            ],
            research:[
                {
                    icon:<GiArtificialIntelligence />,
                    title:'Artificial Intelligence',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    icon:<BiData />,
                    title:'Data Science',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    icon:<GiChemicalDrop />,
                    title:'Machine Learning',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
            ],
            publication:[
                {
                    img:pub1,
                    title:'How can we prevent a glaciers melting?',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                },
                {
                    img:pub2,
                    title:'Global warming: how to prevent a catastrophe. Arguments and conclusions.',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                },
                {
                    img:pub3,
                    title:'What type of proteins are useful?',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                }
            ],
            award:[
                {
                    totalAward:10
                },
                {
                    totalHonour:20
                },
                {
                    totalOther:15
                }
            ],
            teaching:{
                undergraduate:[
                    {
                        title:'CSC201 - Introduction to Java Programming Language',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC315 - Introduction to Information System',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC419 - Foundation of Programming Language',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    }
                ],
                postgraduate:[
                    {
                        title:'CSC601 - Introduction to Python Programming Language 1',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC619 - Introduction to Python Programming Language 2',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC791 - Introduction to Python Programming Language 3',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    }
                ]
            }
        },
        {
            name:'Prof. F.O. Oginni',
            image:denImg10,
            altImg:bmsAltImg4,
            dept:'Oral and Maxillofacial Surgery ',
            socialLinks:'',
            link:'fadekemi-oginni',
            phone:'09034897594',
            faculty:'Dentistry',
            desig:'',
            experience:[
                {
                    year:'2021 - Present',
                    institution:'Computer Science Department, Obafemi Awolowo University,Ile-Ife, Osun State',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                // {
                //     year:'2020 - 2021',
                //     institution:'National Youth Service Corps (NYSC), Infomation Technology and Communication Unit (INTECU), Obafemi Awolowo University,Ile-Ife, Osun State',
                //     desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                // },
                {
                    year:'2014 - 2019',
                    institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    year:'2005 - 2013',
                    institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
             
               
            ],
            research:[
                {
                    icon:<GiArtificialIntelligence />,
                    title:'Artificial Intelligence',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    icon:<BiData />,
                    title:'Data Science',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    icon:<GiChemicalDrop />,
                    title:'Machine Learning',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
            ],
            publication:[
                {
                    img:pub1,
                    title:'How can we prevent a glaciers melting?',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                },
                {
                    img:pub2,
                    title:'Global warming: how to prevent a catastrophe. Arguments and conclusions.',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                },
                {
                    img:pub3,
                    title:'What type of proteins are useful?',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                }
            ],
            award:[
                {
                    totalAward:10
                },
                {
                    totalHonour:20
                },
                {
                    totalOther:15
                }
            ],
            teaching:{
                undergraduate:[
                    {
                        title:'CSC201 - Introduction to Java Programming Language',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC315 - Introduction to Information System',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC419 - Foundation of Programming Language',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    }
                ],
                postgraduate:[
                    {
                        title:'CSC601 - Introduction to Python Programming Language 1',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC619 - Introduction to Python Programming Language 2',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC791 - Introduction to Python Programming Language 3',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    }
                ]
            }
        },
        {
            name:'Prof. C.A. Adekoya',
            image:denImg11,
            altImg:bmsAltImg4,
            dept:'Child Dental Health',
            socialLinks:'',
            link:'comfort-adekoya',
            phone:'09034897594',
            faculty:'Dentistry',
            desig:'',
            experience:[
                {
                    year:'2021 - Present',
                    institution:'Computer Science Department, Obafemi Awolowo University,Ile-Ife, Osun State',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                // {
                //     year:'2020 - 2021',
                //     institution:'National Youth Service Corps (NYSC), Infomation Technology and Communication Unit (INTECU), Obafemi Awolowo University,Ile-Ife, Osun State',
                //     desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                // },
                {
                    year:'2014 - 2019',
                    institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    year:'2005 - 2013',
                    institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
             
               
            ],
            research:[
                {
                    icon:<GiArtificialIntelligence />,
                    title:'Artificial Intelligence',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    icon:<BiData />,
                    title:'Data Science',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
                {
                    icon:<GiChemicalDrop />,
                    title:'Machine Learning',
                    desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
                },
            ],
            publication:[
                {
                    img:pub1,
                    title:'How can we prevent a glaciers melting?',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                },
                {
                    img:pub2,
                    title:'Global warming: how to prevent a catastrophe. Arguments and conclusions.',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                },
                {
                    img:pub3,
                    title:'What type of proteins are useful?',
                    area:'Ecology & Safety, Volume 10, 2016',
                    desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                }
            ],
            award:[
                {
                    totalAward:10
                },
                {
                    totalHonour:20
                },
                {
                    totalOther:15
                }
            ],
            teaching:{
                undergraduate:[
                    {
                        title:'CSC201 - Introduction to Java Programming Language',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC315 - Introduction to Information System',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC419 - Foundation of Programming Language',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    }
                ],
                postgraduate:[
                    {
                        title:'CSC601 - Introduction to Python Programming Language 1',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC619 - Introduction to Python Programming Language 2',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    },
                    {
                        title:'CSC791 - Introduction to Python Programming Language 3',
                        description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                    }
                ]
            }
        }
    ]
}

export {academicStaff}