import React from 'react'
import Layout1 from '../../../components/College/Layout1'

export default function Grillo_Lecture() {
  return (
    <div>
    <Layout1 title="Grillo Lecture" text="Grillo">

    </Layout1>
  </div>
  )
}
