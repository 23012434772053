import React from 'react'
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode,Autoplay, Pagination,Navigation } from "swiper";
import EventList from '../../utilities/CollegeEvents'
import {TbCalendarStats} from 'react-icons/tb'
import {CiUser} from 'react-icons/ci'
import { Link, useSearchParams } from 'react-router-dom';
import {BsArrowRight} from 'react-icons/bs'
export default function Events() {

  let [searchParams, setSearchParams] = useSearchParams()
  return (
    <div className='event_container'>
    <div className='event_wrapper container'>
    <h3 className='heading_1 '>Events</h3> 
    {/* <p>Get in touch to know latest happenings on the campus </p> */}
    <p className='sub_heading'>
     Our events provide you with the perfect opportunity to meet like-minded people, showcase your talents, and have a great time. 
    {/* So, make sure to check it out and get ready to make some memories! */}
       </p>
    <Swiper
        slidesPerView={1}
        spaceBetween={10}
        slidesPerGroup={1}
        // freeMode={true}
        loop={true}
        // centeredSlides={true}
        loopFillGroupWithBlank={true}
        pagination={{
          clickable: true,
        }}
        autoplay={{
            delay: 2500,
            // delay: 4500,
            disableOnInteraction: false,
          }}
        breakpoints={{
            640: {
              slidesPerView: 2,
              spaceBetween: 5,
            },
            768: {
            //   slidesPerView: 4,
            //   spaceBetween: 40,
              slidesPerView: 3,
              spaceBetween: 10,
            },
            1024: {
              slidesPerView: 4,
              spaceBetween: 10,
            },
          }}
          navigation={true}
          modules={[Autoplay,FreeMode,Pagination, Navigation]}
        // modules={[FreeMode, Pagination]}
        className="mySwiper"
      >

        {
           EventList.map((item, index)=>{
            
            return(
              <SwiperSlide>
              <div className="image_text_container">
                <div className="image_date_wrapper">
                      <div className="image_wrapper">
                          <img src={item.img} alt="" />
                      </div>  
                      <div className="date_time_wapper">
                            <div className="icon_text">
                             <span><TbCalendarStats /></span> 
                             <p>{item.time + ", " +item.date}</p>
                            </div>
                      </div>
                </div>
    
                <div className="event_details"> 
                        <div className="user">
                         
                          <p><span><CiUser size={12}  /> </span>By {item.src}</p>
                        </div>
                        <Link to={`event/${item.title.toLowerCase().replaceAll(/[^a-zA-Z0-9]/g,'-')}`}>
                        {item.title.toUpperCase()}
                        </Link>

                       <p>{item.text}</p>

                       {/* <p onClick={()=>setSearchParams({event:'hello'})} className='read_more'>
                        Read More <span><BsArrowRight /></span>
                        </p> */}
                       <Link to={`event/${item.title.toLowerCase().replaceAll(/[^a-zA-Z0-9]/g,'-')}`} className='read_more'>
                        Read More <span><BsArrowRight /></span>
                        </Link>
                </div>
    
              </div>
            </SwiperSlide>
            )
           })
        }
       
       
      </Swiper>

    </div>

    </div>
  )
}
