import React,{useEffect} from 'react'
import Layout1 from '../../../components/College/Layout1'

export default function IFUTSA() {
  useEffect(()=>{
    document.title="College of Health Sciences - IFUTSA, Obafemi Awolwo University, Ile-Ife "
    window.scrollTo({
      top:0
    })
  },[])
  return (
    <div>
    <Layout1 title="Ife University Physiotherapy Student Association (IFUTSA)" text="IFUTSA">

    </Layout1>
  </div>
  )
}
