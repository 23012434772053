import React from 'react'
import { FaTimes } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import CollegeMobileNavLinks from './CollegeMobileNavLinks'
import {AiOutlineMail} from 'react-icons/ai'
import {FaFacebookF} from 'react-icons/fa'
import {IoLogoTwitter} from 'react-icons/io'
import {AiOutlineInstagram} from 'react-icons/ai'
import {FaPinterestP} from 'react-icons/fa'
import {FaLinkedinIn} from 'react-icons/fa'

export default function CollegeSideDraw({toggle,handleToggle}) {
  return (
    <div className={`chs_sidedraw_container_navbar ${toggle? 'active': ''}`} onClick={handleToggle}>
    <div className='chs_sidedraw_wrapper_navbar' onClick={handleToggle}>
            <div className='inner_wrapper'>
                    <div className="login_signup_container">
                        <div className='wrapper min_space_1'>
                        <p onClick={handleToggle}><Link to="history">Log in / Sign Up</Link></p>
                        <p><FaTimes onClick={handleToggle}  size={20} /></p>
                        </div>
                    
                    </div>
                    <div className="navlinks_container ">
                        <CollegeMobileNavLinks handleToggle={handleToggle} toggle ={toggle} />
                    </div>
                    <div className="call_us_container ">
                      <div className="contact_details min_space_1">
                      <h4>Call Us</h4>
                      <h5>+2348034074526</h5>
                      <p>
                        College of Health Sciences, Obafemi Awolowo University, Ile-Ife, Osun State, Nigeria.
                        
                      </p>
                      <p>
                        provostchs@oauife.edu.ng
                        
                      </p>
                      

                      </div>

                    <div className="social_media_links min_space_1">
                      <ul>
                        <li><a href="https://www.facebook.com/profile.php?id=100083298822865" target="_blank"><FaFacebookF /></a></li>
                        <li><a href="https://twitter.com/chsoau" target="_blank"><IoLogoTwitter /></a></li>
                        <li><a href="https://www.instagram.com/chsoau/" target="_blank"><AiOutlineInstagram /></a></li>
                        <li><a href="#"><FaPinterestP /></a></li>
                        <li><a href="#"><FaLinkedinIn /></a></li>
        
                      </ul>
                    </div>
                       
                    </div>
            </div>
    </div>

    </div>
  )
}
