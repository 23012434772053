import React,{useEffect} from 'react'
import Academics from '../../../components/College/Academics'
import AboutUs from '../../../components/College/AboutUs'
import HeroSection from '../../../components/College/HeroSection'
import Footer from '../../../components/College/Footer'
import Faculty from '../../../components/College/Faculty'
import Events from '../../../components/College/Events'
import CampusLife from '../../../components/College/CampusLife'
import StaffPics from '../../../components/StaffPics'
import LatestNews from '../../../components/College/LatestNews'
import Gallery from '../../../components/College/Gallery'
import ContactUs from '../../../components/College/ContactUs'
import Admission from '../../../components/College/Admission'

export default function Home() {
  useEffect(()=>{
    document.title="College of Health Sciences - Home, Obafemi Awolwo University, Ile-Ife "
    window.scrollTo({
      top:0,
      behaviour:'smooth'
    })
  },[])
  return (
    <div className='chs_homapage_container chs_to'>
    <div className=' wrapper'>
        <HeroSection />
        <Academics />
        <AboutUs />
        <Faculty />
        <Events />
        <CampusLife />
        <StaffPics />
        {/* <Admission />
        <LatestNews />
        <Gallery />
        <ContactUs /> */}
        <Footer />
    </div>
       
    </div>
  )
}