import React,{useEffect} from 'react'
import Footer from '../../../components/College/Footer'
import Banner from '../../../components/College/Banner'
import BannerImage from '../../../assets/images/oau-building.jpg'
import Layout1 from '../../../components/College/Layout1'
import {btnToggler} from '../../../utilities/buttonToggler'

export default function Academic_Prizes() {
  useEffect(()=>{
    document.title="College of Health Sciences - Education Perspective, Obafemi Awolwo University, Ile-Ife "
    window.scrollTo({
      top:0
    })
    let btns = [...document.querySelectorAll('.button_wrapper > ul > li')]
    let prizeContents = [...document.querySelectorAll('.content_wrapper > ul>li')]
    btnToggler(btns, prizeContents)
  })
  return (
    <div> 
 
        <Layout1 title="Academic Prizes" text="Prize">
          <div className='academic_content sub_title_text'>
            <h2>Academic Prizes</h2>
            <p >
            At the core of the College’s mission is to provide cutting-edge and student-centred curricula in a scholarly environment. Without much ado, it suffices to say that outstanding performances by students in the College is encouraged and honoured.
		        </p>
          </div>
        <div className="toggler button_wrapper">
                <ul>
                  <li >Basic Sciences</li>
                  <li >Clinical Sciences</li>
                  <li >Dentistry</li>
                </ul>
            </div>

            <div className="page content_wrapper">
              <ul>

                {/* BASIC SCIENCES */}
                <li>
                  <div className="inner_content">
                    <h3>Faculty of Basic Medical  Sciences</h3>
                    <ul>
                      <li className='items'>
                      <h5>Prizes in Nursing Science</h5>
                    <ul className='sub_items'>
                      <li><p> <span > Adetoun Bailey Prize:</span> Best graduating student in Nurse-Patient Interaction with a score of seventy-two (72) percent in clinicals.</p></li>
                      <li><p> <span >Louis Bell Prize:</span>  Best graduating student in Community Health Nursing whose score is not less than sixty (60) percent in the course.</p></li>
                    </ul>
                      </li>

                      <li className='items'>
                      <h5>Prizes in Medical Rehabilitation</h5>
                    <ul className='sub_items'>
                      <li><p> <span >Faculty Prize:</span> Student with the best overall performance in the faculty of Basic Medical Sciences.</p></li>
                      <li><p> <span >Dr. B.O. Songonuga Prize:</span> Student with the best overall performance in Bachelor of Medical Rehabilitation degree examination.</p></li>
                      <li><p> <span >Mrs. G.O. Nwuga Prize:</span> Best student in Clinical Experience in the final year B.M.R (Physical Therapy) degree examination.</p></li>
                    </ul>
                      </li>
                    </ul>
                  
                  </div>
                </li>

                {/* CLINICAL SCIENCES */}
                <li>
                   <div className="inner_content">
                    <h3>Faculty of Clinical Sciences</h3>
                    <ul className='sub_items'>
                      <li><p> <span >Faculty Prize:</span> best overall students in the clinical years (Parts IV to VI) of the MB ChB degree programme.</p></li>
                      <li><p> <span >Olaningbe Makanjuola Memorial Prize:</span> best candidate in Medicine and Medical Specialties in Part II MB ChB degree examination.</p></li>
                      <li><p> <span >Olaningbe Makanjuola Memorial Prize:</span> best student in Medicine in Part III MB ChB degree examination.</p></li>
                      <li><p> <span >The Glaxo-Allenbury Prize:</span> best candidate in the final MB ChB degree examination in Paediatrics.</p></li>
                      <li><p> <span >The Paediatrics Association of Nigeria Prize:</span> best candidate in the final MB ChB degree examination in Paediatrics.</p></li>
                      <li><p> <span >The Nigerian Medical Association Prize:</span> best overall candidate in the final MB ChB degree examination.</p></li>
                      <li><p> <span >Professor O. A. Ajose Memorial Prize:</span> student with the best dissertation in the final MB ChB degree examination in Community Health.</p></li>
                      <li><p> <span >The Professor T. Adeoye Lambo Prize:</span>student with the best performance in Neuropsychiatry in the final MB ChB degree examination.</p></li>
                      <li><p> <span >Dr. Moses Adekoyejo Majekodunmi Prize:</span>student with the best overall results in the MB ChB degree in Obstetrics and Gynaecology.</p></li>
                      <li><p> <span >Lawrence Omole Prize:</span>student with the best result in Community Health in the final MB ChB degree examination.</p></li>
                      <li><p> <span >The Major General Olufemi Olutoye Prize: </span>student with the best performance in Surgery in the final MB ChB degree examination.</p></li>
                      <li><p> <span >The Candido-Da Rocha Prize: </span>student with the best performance in Surgery in the final MB ChB degree examination.</p></li>
                      <li><p> <span >Adekunle Okute Prize: </span>student with the best result in Surgery in the finals MB ChB degree examination.</p></li>
                      <li><p> <span >Professor Olufisan Taiwo Memorial Prize: </span>student with highest score in the clinical examination in the final MB ChB degree examination in Paediatrics whose total score is not less than sixty (60) percent.</p></li>
                      <li><p> <span >Dr. Olalekan Sikiru Badru Prize: </span>final year medical student with the best result in Orthopaedic Surgery with minimum score of sixty (60) percent in the overall result in Surgery.</p></li>
                    </ul>
                    </div>
                    
                  </ li>

                {/* DENTISTRY */}
                <li>
                <div className="inner_content">
                    <h3>Faculty of Dentistry</h3>
                    <ul className='sub_items'>
                    <li><p> <span> Faculty Prize:</span> student with the best overall performance in the Final BCh D examination in the faculty.</p></li>
                    <li><p> <span>Professor N. O. Hollist Prize:</span>  student with the best overall performance with the highest cumulative average mark in the faculty at the final BCh D examination.</p></li>
                    <li><p> <span>Fox Taylor Prize:</span> graduating student with the best overall performance in Oral and Maxillofacial Surgery at the final BCh D examination.</p></li>
                    <li><p> <span>Pa J. A. Fagade Prize:</span> graduating student with the best overall performance in Peridontology at the final BCh D examination.</p></li>
                    <li><p> <span>Aubrey Sheiham Prize:</span>  graduating student with the best overall performance in Community Dentistry at the final BCh D examination.</p></li>
                    <li><p> <span>Professor Kinziro Kubota Prize: </span>graduating student with the best overall performance in Restorative Dentistry not below sixty (60) percent at the final BCh D examination.</p></li>
             
                    </ul>
                    </div>
                </li>
              </ul>
            </div>
        </Layout1>
  
    </div>
  )
}
