import React,{useEffect,useState} from 'react'
import {BiChevronDown,BiChevronRight} from 'react-icons/bi'
import {AiOutlineSearch} from 'react-icons/ai'
import {IoCloseOutline} from 'react-icons/io5'
import { Link } from 'react-router-dom'
import Navlinks from '../../utilities/Navlinks'
import CollegeDesktopTabletSublink1 from './CollegeDesktopTabletSublink1'


export default function CollegeDestopTabletNavLinks() {
    let [val, setVal] = useState()

useEffect(()=>{

        let searchBtn = document.querySelector('.search_wrapper')
        let searchBox = document.querySelector('.search_box_container')
        let searchBoxCloseBtn = document.querySelector('.close_search_btn')
            searchBtn.addEventListener('click',()=>{
                searchBox.classList.add('active')
            })
            searchBoxCloseBtn.addEventListener('click',()=>{
                searchBox.classList.remove('active')
            })
            // searchBox.addEventListener('click',()=>{
            //     searchBox.classList.remove('active')
            // })

          
 
},[])


let handleOnchange = (e)=>{
    let {value} = e.target
    setVal(value)
  }

  if(val == undefined){
    setVal('')
  }
  let handleClick = ()=>{
    let searchBox = document.querySelector('.search_box_container')
    searchBox.classList.remove('active')
    setVal('')
}

  return (

    <>
     <div className="search_box_container ">
            <div className="search_box_wrapper">
                <button type="submit" onClick={handleClick}><Link to={`/search-result/${val}`}><AiOutlineSearch color="black" size={20} /></Link> </button>
                <input value={val} onChange={handleOnchange} type="text" placeholder='Search your text here...' />
                <div className="close_search_btn">
                    <IoCloseOutline color="black" size={20}/>
                </div>
            </div>
        </div>
    <div className='chs_desktop_tabs_navlinks'>
       
            <div className="chs_desktop_tabs_navlinks_wrapper">
                <ul>
                    { 
                        Navlinks.map((item,index)=>{
                            return(
                            <React.Fragment key={index}>
                                    {
                                        item.submenu? <li className='has_sublink'>
                                        <Link to={item.path}> <p> {item.link} <BiChevronDown size={25} /></p> </Link>
                                        <CollegeDesktopTabletSublink1 submenu={item.submenu} />
                                    </li>
                                    :
                                    <li>
                                        {
                                              item.external? <a href={item.external} target="_blank">{item.link}</a>:
                                              <Link to={item.path} >{item.link}</Link>
                                        }
                                    </li>
                                    // item.external? <a href={item.external} target="_blank">{item.link}</a>:
                                    // <Link to={item.path} >{item.link}</Link>
                                    // <li><Link to={item.path}>{item.link}</Link></li>
                                    }

                            </React.Fragment>
                           )
                        })
                    }
                    
                </ul>
                <div className="right_wrapper">
                    <div className="search_wrapper">
                        <AiOutlineSearch color="white" size={25} />
                    </div>
                    <div className="donate_wrapper">
                        <Link to="/" className='donate_btn'>Donate</Link>
                    </div>
                </div>
            </div>
    </div>
    
    </>
    
  )
}
