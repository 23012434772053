import React,{useState, useEffect} from 'react'
import Layout1 from '../../../components/College/Layout1'
import Image from '../../../assets/images/oau-logo.png'

import { bmsNonAcademicStaff } from '../../../utilities/Non-Academic-Staff'
import { academicStaff } from '../../../utilities/Academic-Staff'
import Faculty_Staff_Layout from '../../../components/College/Faculty_Staff_Layout'

export default function Basic_Medical_Sciences_Staff() {
  useEffect(()=>{
    document.title="College of Health Sciences - Faculty of Basic Medical Sciences, Obafemi Awolwo University, Ile-Ife "
    window.scrollTo({
      top:0
    })


 

  },[])
 
  return (
    <div>
      <Layout1 title="Faculty of Basic Medical Sciences Staff" text="Staff">
          <Faculty_Staff_Layout
              academicStaff={academicStaff.bms}
              nonAcademicStaff={bmsNonAcademicStaff}
              faculty="Basic Medical Sciences"
              text="BMS"
          />
      </Layout1>
    </div>
  )


}
