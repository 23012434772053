import React from 'react'
import Layout1 from '../../../components/College/Layout1'

export default function Prospective_Student() {
  return (
    <div>
      <Layout1 title="Prospective Student" text="Student">
             
      </Layout1>
    </div>
  )
}
