import React,{useState, useEffect} from 'react'
import { useParams } from 'react-router-dom'
import Banner from '../../../components/College/Banner'
import Event from '../../../utilities/CollegeEvents'
import BannerImage from '../../../assets/images/college_banner_2.jpg'
import Footer from '../../../components/College/Footer'
import EventCountDown from '../../../components/College/EventCountDown'
import {MdLocationPin,MdAccessTime} from 'react-icons/md'
import {FaCalendarAlt} from 'react-icons/fa'
import RightBarSearchBox from '../../../components/College/RightBarSearchBox'
import RightBarCategories from '../../../components/College/RightBarCategories'
import PopularTags from '../../../components/College/PopularTags'
import PopulartagLink from '../../../utilities/PopularTagsLinks'
import Links from '../../../utilities/RightBarCategoryLink'
export default function EventDetails() {
   let {id} = useParams()

   let [detail, setDetail] = useState()
  // console.log(detail)
  let data = Event.filter((item)=>item.title.toLowerCase().replaceAll(/[^a-zA-Z0-9]/g,'-') == id)
  //  setDetail(Event.filter((item)=>item.url.toLowerCase() == id)) 
//    let data = Event.map(item=>item.findIndex((item)=>item.title.toLowerCase() === id))
  //  console.log(data)

   useEffect(()=>{
    document.title="College of Health Sciences - Event Details, Obafemi Awolwo University, Ile-Ife "
    window.scrollTo({
      top:0
    })
  },[])

 
  return (
    <div className='chs_event_details_container chs_top'>
            <Banner image={BannerImage} text="Event Details" title="EVENT DETAILS"/>



            <div className="content_container">
            <div className="content_wrapper container">
            {
  data.map((item, index)=>{
    return(
      <div className="left_bar">

        <div className="wrapper_image_countdown">

        <div className="image" >
          <img src={item.img} />

           
         
      </div>
     
          <EventCountDown date ={item.date}/>

        </div>
     


        <div className="event_details">

<div className="calendar">
  <span> <FaCalendarAlt  /></span>  <span>{item.date}</span>
</div>
<div className="location">
  <span><MdLocationPin /></span>  <span>{item.venue}</span>
</div>
<div className="location">
  <span><MdAccessTime /></span>  <span>{item.time}</span>
</div>

{/* <FaCalendarAlt  /> */}
{/* <MdLocationPin /> */}
</div>

      <div className="title">
          <h5>{item.title}</h5>

     
 
          <p>
            {item.text}
          </p>
      </div>
      
    </div>
    )
  })
}
                 
                  <div className="right_bar">
                      <div className="right_bar_wrapper">
                          <RightBarSearchBox />
                          <RightBarCategories title="Categories" links={Links} />
                          <PopularTags link={data[0].tags} />
                      </div>

                  </div>
            </div>
                
            </div>



            <Footer />
    {/* <br />
    {data.map((item)=><>
        <p>{item.title}</p>
        <p>{item.text}</p>
        <img src={item.img} />
    </>)} */}
    </div>
  )
}
