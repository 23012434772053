
// TbCalendarStats
import Event1 from '../assets/images/events/event-5.jpg'
import Event2 from '../assets/images/events/ani.PNG'
import Event3 from '../assets/images/events/event-4.jpg'
import Event4 from '../assets/images/events/Outreach.jpg'
import Event5 from '../assets/images/events/event-1.jpg'
import Event6 from '../assets/images/events/oginni.jpg'
import Event7 from '../assets/images/events/grillo2023.jpg'
let Event = [
    {
        title:"15th Prof. T.A.I. Grillo Distinguished Lecture",
        img:Event7,
        text:"The College of Health Sciences cordially invites all staffs and students to annual Prof. T.A.I Grillo Lecture. Topic: TRANSFORMING HEALTH PROFESSIONS EDUCATION IN NIGERIA: Ancient Landmarks, New Frontiers and the Road Ahead. Guest Speaker: Prof. Adesegun Fatusi (Vice Chancellor of University Medical Sciences(UNIMED),Ondo City, Ondo State.) ",
        src:"College",
        time:"10:00am",
        date:"May 25, 2023",
        venue:"Oduduwa Hall",
        url:'15th-Grillo-Lecture',
        tags:["Grillo", "Lecture", "Annual Lecture", "Grillo Lecture"]

    },
    {
        title:"368th Inaugural Lecture Titled:FROM BROKEN PIECES TO MASTER PIECES:The art and craft of Maxillofacial Surgery",
        img:Event6,
        text:"Prof. Fadekemi Olufunmilayo Oginni and Family is specially invititng staff  and  friends to her 368th inaugural lecture titled: FROM BROKEN PIECES TO MASTER PIECES:The art and craft of Maxillofacial Surgery.",
        src:"Faculty of Dentistry",
        time:"5:00pm",
        date:"Feb 14, 2023",
        venue:"Oduduwa Hall",
        url:'367-inaugural-lecture',
        tags:["Event", "Inaugural","Maxillofacial","Surgery","Oginni","Fadeke",]

    },
    {
        title:"367th Inaugural Lecture: SWEET YET BITTER, INNOCUOUS YET SINNISTER",
        img:Event1,
        text:"Prof. Babatope Ayodeji Kolawole and Family is specially invititng staff  and  friends to his 367th inaugural lecture titled: Sweet yet Bitter, Innocuous yet sinnister ",
        src:"College",
        time:"5:00pm",
        date:"Jan 20, 2023",
        venue:"Oduduwa Hall",
        url:'367-inaugural-lecture',
        tags:["Event","Kolawole","Sinnister", "Inaugural", "Campus"]

    },
    {
        title:"College 50th Anniversary Lecture & Awards Ceremony",
        img:Event2,
        text:" The provost of college of health sciences is inviting you to the 50th anniversary of College of Health Sciences, OAU, Ile-Ife which will be taking place at ",
        src:"College",
        time:"10:00am",
        date:"Dec 14, 2022",
        venue:"Clinical Skill Center",
        url:'50th-anniversary',
        tags:["Anniversary", "CHS", "Lecture", "Award", "Ceremony"]

    },
    {
        title:"Essay & Debate Competition: Tripod of Maladies in the nigeria Health Sector",
        img:Event3,
        text:"The provost of College of Health Sciences and the staff committee is inviting you to Essay and Debate competiton titled: Tripod Maladies in the Nigerian Health Sector: Poor Funding, Weak Health System and Brain Drain",
        src:"College",
        time:"2:00pm",
        date:"Dec 6, 2022",
        venue:"",
        url:'debate',
        tags:["Debate", "Competition", "Health", "Essay"]

    },
    {
        title:"Health Awareness and Screening outreach",
        img:Event4,
        text:" ",
        src:"College",
        time:"9:00am",
        date:"Oct 19, 2022",
        venue:"",
        url:'health-awareness'

    }
]


export default Event

