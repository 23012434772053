import React from 'react'
import Layout1 from '../../../components/College/Layout1'

export default function Medical_Illustration() {
  return (
    <div>
    <Layout1 title="Medical Illustration Unit" text="Staff">
    ABOUT THE UNIT <br/>
    WHAT DO THE UNIT DO?<br/>
    STAFF PICS 
        {/* ABOUT THE UNIT */}
        {/* WHAT DO THE UNIT DO? */}
        {/* STAFF PICS */}

    </Layout1>
  </div>
  )
}
