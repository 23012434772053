import React from 'react'
import Banner from '../../assets/images/college_banner_2.jpg'
import ButtonSlideBg from './ButtonSlideBg'
export default function CampusLife() {
  return (
    <div className='campus_life_container'>
    <div className='campus_life_wrapper containe'>
            <div className="box left">

                <div className="inner_wrapper">
                <h3>Student Life</h3> 
                <p>

                Take the first step towards a successful health sciences career and click the button below to learn more about student life in college of health sciences!
                </p>
            <ButtonSlideBg className="btn" text="Learn More" to='/std-life' />
                </div>
                
            </div>
          
    </div>

    </div>
  )
}
