import React,{ useEffect }  from 'react'

import { useParams } from 'react-router-dom'
import Footer from '../../../components/College/Footer'
export default function SearchResult() {
    let {id}= useParams()
    useEffect(()=>{
        document.title="College of Health Sciences - Search Result, Obafemi Awolwo University, Ile-Ife "
        window.scrollTo({
          top:0
        })
      },[])
  return (
    <div className='search_result_wrapper chs_top'>
    <div className='search_result'>
        {id? id : "No Result Found"}
    </div>


<Footer />
    </div>
  )
}
