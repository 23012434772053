import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import {MdArrowBackIos,MdArrowForwardIos} from 'react-icons/md'
import CollegeSubLink2 from './CollegeSubLink2'





export default function CollegeSubLink1({toggle,handleToggle,submenu}) {
    // console.log(submenu)
  return (
    <div className='subnavlinks'>
                    <ul >
                    <li><p className='back_link'> <MdArrowBackIos />Back</p></li>
                        {
                            
                            submenu.map(((items, index)=>{
                                return(
                                    <React.Fragment key={index}>
                                        
                                        {
                                            items.submenu?
                             <li  className='has_sublinks_container '>
                            <div className="has_sublinks">
                            <NavLink to={items.path} className={({isActive})=>(isActive?'active_link':undefined) }>
                {items.link}
                <span><MdArrowForwardIos /> </span>
                </NavLink>
               
                            {/* <Link to={items.path}>
                            {items.link}
                            <span><MdArrowForwardIos /> </span>
                            </Link> */}
                            </div>
                            <CollegeSubLink2 handleToggle={handleToggle} toggle ={toggle} submenu={items.submenu} />
                            </li>
                                            :
                                            <li onClick={handleToggle}>
                                                 {/* {console.log(items)} */}
                                                 {items.external?
                                                 <a href={items.external} target="_blank"> {items.link}</a>
                                                 :
                                                 <NavLink to={items.path} className={({isActive})=>(isActive?'active_link':undefined) }>
                                                 {items.link}
             
                                             </NavLink>
                                                }
                                               
                                                {/* <Link to={items.path}>{items.link}</Link> */}
                                            </li>
                                        } 
                                    </React.Fragment>
                                   
                                )
                            
                                // console.log(items.link)
                            }))
                        }
                        {/* <li><p className='back_link about'> <MdArrowBackIos />  Back</p></li>
                        <li onClick={handleToggle}><Link>History About The College</Link></li>
                        <li onClick={handleToggle}><Link>History About The College</Link></li>
                        <li onClick={handleToggle}><Link>Facts About The College</Link></li>
                        <li onClick={handleToggle}><Link>Our Anthem</Link></li>
                        <li onClick={handleToggle}><Link>Awards</Link></li>
                        <li  className='has_sublinks_container '>
                            <div className="has_sublinks past_leaders">
                            <Link>
                            Past Leaders
                            <span><MdArrowForwardIos /> </span>
                            </Link>
                            </div>
                            
                            <div className="subnavlinks past_leaders">
                                <ul>
                                    <li ><p className='back_link past_leaders'><MdArrowBackIos /> Back</p></li>
                                    <li onClick={handleToggle}><Link to="/">Provost</Link></li>
                                    <li onClick={handleToggle}><Link to="/">Deputy Provost</Link></li>
                                    <li onClick={handleToggle}><Link to="/">Director</Link></li>
                                    <li onClick={handleToggle}><Link to="/">Dean</Link></li>
                                    <li onClick={handleToggle}><Link to="/">Head of Department</Link></li>
                                    <li onClick={handleToggle}><Link to="/">College Secretary</Link></li>
                                </ul>
                            </div>
                        </li> */}
                    </ul>
                </div>
  )
}
