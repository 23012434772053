import React from 'react'
import Layout1 from '../../../components/College/Layout1'

export default function Student_Life() {
  return (
    <div>
    <Layout1 title="Student's Life" text="Student">

    </Layout1>
  </div>
  )
}
