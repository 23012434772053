import React from 'react'
import {BsArrowRight} from 'react-icons/bs'
import { Link } from 'react-router-dom'
export default function ButtonSlideBg({text, to,className}){
  return (
    <div className={`call_to_action_container ${className? className : ''}`} >
        <a href={to} target='_blank'><p>{text}</p> <span><BsArrowRight /></span></a>
    </div>
  )
}
 