import provost1 from '../assets/images/past provost/img1.jpg'
import provost2 from '../assets/images/past provost/img2.jpg'
import provost3 from '../assets/images/past provost/img3.jpg'
import provost4 from '../assets/images/past provost/img4.jpg'
import provost5 from '../assets/images/past provost/img5.jpg'
import provost6 from '../assets/images/past provost/img6.jpg'
import provost7 from '../assets/images/past provost/img7.jpg'
import provost8 from '../assets/images/past provost/img8.jpg'
import provost9 from '../assets/images/past provost/img9.jpg'
import provost10 from '../assets/images/past provost/img10.jpg'
import provost11 from '../assets/images/past provost/img11.jpg'
import provost12 from '../assets/images/past provost/img12.jpg'
import provost13 from '../assets/images/past provost/img13.jpg'
import provost14 from '../assets/images/past provost/img14.jpg'
import provost15 from '../assets/images/past provost/img15.jpg'


let LeftData = [
    {
        image:provost1,
        name:'Prof. T.A.I Grillo ',
        Year:'1972 - 1976',
        status:'Founding Dean'
    },
    {
        image:provost2,
        name:'Prof. M.A. Bankole ',
        Year:'1976 - 1980',
        status:'Dean'
    },
  
    {
        image:provost4,
        name:'Prof. F.O. Adebonojo ',
        Year:'1980 - 1982',
        status:'Dean'
    },
    {
        image:provost3,
        name:'Prof. O.O. Soyinka ',
        Year:'1982 - 1986',
        status:'Dean'
    },
    {
        image:provost5,
        name:'Prof. S.O. Olusi ',
        Year:'1986 - 1989',
        status:'Dean'
    },
    {
        image:provost6,
        name:'Prof. W.O. Odesanmi',
        Year:'1989 - 1990',
        status:'Dean'
    },
    {
        image:provost7,
        name:'Prof. A.O. Adeyemo ',
        Year:'1990 - 1991',
        status:'Dean'
    },
    {
        image:provost8,
        name:'Prof. Kayode Adetugbo',
        Year:'1991 - 1995'
    },
    {
        image:provost9,
        name:'Prof. G.A. Oyedeji ',
        Year:'1995 - 1999'
    },
    {
        image:provost10,
        name:'Prof. O. Adejuyigbe ',
        Year:'1999 - 2003'
    },
    {
        image:provost11,
        name:'Prof. M.O. Balogun',
        Year:'2003 - 2007'
    },
    {
        image:provost12,
        name:'Prof. E.O. Ogunbodede ',
        Year:'2007 - 2009'
    },
    {
        image:provost13,
        name:'Prof. S.O. Ogunniyi ',
        Year:'2009 - 2013'
    },
    {
        image:provost14,
        name:'Prof. A.O. Fatusi',
        Year:'2013 - 2017'
    },
    {
        image:provost15,
        name:'Prof. K.T. Ijadunola',
        Year:'2017 - 2021'
    },


].reverse()

let RightData = [
   
    {
        link:'Past Deputy provost',
        to:'/past-deputy-provost'
    },
    {
        link:'Past Dean',
        to:'/past-dean'
    },
    {
        link:'Past IPH Director',
        to:'/past-director'
    },
    {
        link:'Past College Secretary',
        to:'/past-chs-secretary'
    },
    {
       tags: ["Past Provost","Past", "Provost"]
    }
]

export {RightData, LeftData}