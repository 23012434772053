import React from 'react'
import {GiChemicalDrop} from 'react-icons/gi'

export default function StaffResearch({data}) {
  return (
    <div className="research_wrapper inner_wrapper">

        {
            data.map((item, index)=> <div key={index} className="item">
            <span>{item.icon}</span>
            <h4>{item.title}</h4>
            <p>{item.desc}</p>
      </div>)
        }

  </div>
  )
}
