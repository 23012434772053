import React from 'react'
import Footer from './Footer'
import Banner from './Banner'
import BannerImage from '../../assets/images/oau-building.jpg'
import RightBarSearchBox from '../../components/College/RightBarSearchBox'
import RightBarCategories from '../../components/College/RightBarCategories'
import PopularTags from '../../components/College/PopularTags'

export default function Layout1({children, text, title,image,category, tag}) {
  return (
    <div className='layout_container chs_top'>
        
        <div className='layout_wrapper'>
            <Banner image={image? image : BannerImage} title={title?title : "No Title"} text={text ? text : "No Title"}/>
            <div className="layout_inner_wrapper  content_container">

                <div className="content_wrapper container">
                <div className="left_bar">
                        {children}
                </div>
                <div className="right_bar">
                      <div className="right_bar_wrapper">
                          <RightBarSearchBox />
                          <RightBarCategories title="Related Links" links={category}  />
                          <PopularTags link={tag} />
                      </div>

            </div>
              </div>
               
            </div>
        </div>
        <Footer />
    </div>
  )
}

