import React,{useEffect} from 'react'
import Banner from '../../../components/College/Banner'
import Footer from '../../../components/College/Footer'
import BannerImage from '../../../assets/images/oau-building.jpg'
import RightBarSearchBox from '../../../components/College/RightBarSearchBox'
import RightBarCategories from '../../../components/College/RightBarCategories'


import Layout1 from '../../../components/College/Layout1'

import { Fact as data } from '../../../utilities/Data'




export default function Fact() {
    useEffect(()=>{
        document.title="College of Health Sciences - Home, Obafemi Awolwo University, Ile-Ife "
        window.scrollTo({
          top:0
        })
      },[])
  return (
    <div >
      <Layout1
        title="FACT ABOUT COLLEGE"
        image={BannerImage} 
        text="Fact"
        tag={data[data.length-1].tags}
        category={data[data.length-1].links} 
    
      >
        <h2>Fact about College of Health Sciences</h2>
      {
   data.map((item, index)=>{
    return(
      <div className='text_wrapper'>
          <h5>{item.title}</h5>
          <p>{item.text}</p>
      </div>

     
    )
   })
 } 
      </Layout1>
    </div>
  )
}
