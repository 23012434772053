import React from 'react'

export default function Timeline({data}) {
  return (
    <ul>

        {
            data.map((item, index)=>  <li key={index}>
            <header>
                <span>{item.year}</span>
                <span></span>
                <h4>{item.institution}</h4>
            </header>
            <p>
             {item.desc}
            </p>
        </li>)
        }
    {/* <li>
        <header>
            <span>2020 - 2022</span>
            <span></span>
            <h4>Computer Science Department, Federal University of Technology, Akure (FUTA) </h4>
        </header>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!
        </p>
    </li>
    <li>
        <header>
            <span>2015 - 2019</span>
            <span></span>
            <h4>Computer Science Department, Federal University of Technology, Akure (FUTA) </h4>
        </header>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!
        </p>
    </li> */}
   
    
</ul>
  )
}
