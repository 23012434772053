import React from 'react'
import { Link, useLocation } from 'react-router-dom'

export default function CollegeDesktopTabletSublink2({submenu}) {
    let para = useLocation()
  return (
    <div className="submenu_2_wrapper">
        <ul>
                 {
                    submenu.map((item,index)=>
                       <li key={index}><Link to={item.path} className='active'>{item.link}</Link></li>
                    )
                 }  
                    
               
            
                              
        </ul>
    </div>
  )
}
