import React from 'react'
import { Link } from 'react-router-dom'
import MiniHeaderBar from './MiniHeaderBar'

export default function PopularTags({link}) {

  return (
    <div className='popular_tags_wrapper'>
            <MiniHeaderBar text="Popular Tags" />
            <div className="popular_tags_content">
                    

                   
            {
                        link?
                        
                        // link.map((item, index)=> <Link  to={`../popular-tag/${item.toLowerCase()}`}>{item}</Link>)
                        link.map((item, index)=> <Link  to="#">{item}</Link>)
                        :
                        <p>No tag available</p>
                        
                        
                    }
                    {/* <Link>Publication</Link>
                    <Link>Journals</Link>
                    <Link>FAQs</Link>
                    <Link>Campus</Link> */}
                    
            </div>
    </div>
  )
}
