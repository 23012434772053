import React,{useEffect} from 'react'
import Layout1 from '../../../components/College/Layout1'

export default function NUNSA() {
  useEffect(()=>{
    document.title="College of Health Sciences - NUNSA, Obafemi Awolwo University, Ile-Ife "
    window.scrollTo({
      top:0
    })
  },[])
  return (
    <div>
    <Layout1 title="NUNSA" text="NUNSA">

    </Layout1>
  </div>
  )
}
