import React,{useState, useEffect} from 'react'
import { Link, NavLink } from 'react-router-dom'
import {MdArrowBackIos,MdArrowForwardIos} from 'react-icons/md'
import Links from '../../utilities/Navlinks'
import CollegeSubLink1 from './CollegeSubLink1'



export default function CollegeMobileNavLinks({toggle,handleToggle}) {
let [scr,setScreenSize] = useState(0)
    useEffect(()=>{
        let link = document.querySelectorAll('.has_sublinks')
        let backLink = document.querySelectorAll('.back_link')
       
        window.addEventListener("resize",()=>{
            const width = window.screen.width
                setScreenSize(width )
        })
        
if(scr<=768){
    backLink.forEach(el=>{
        el.addEventListener('click',e=>{
            if(el.closest('.subnavlinks').classList.contains('active')){
                el.closest('.subnavlinks').classList.remove('active')
            }

          
        })
    })


    link.forEach(el=>{
        el.addEventListener('click',e=>{

          if(el.nextElementSibling.classList.contains('subnavlinks')){
            el.nextElementSibling.classList.add('active')
          }
           
        })
    })
}
        

        return()=> window.addEventListener("resize",()=>{
            const width = window.screen.width
                setScreenSize(width )
        })
    },[scr])

  return (
    <div className='chs_mobileview_navlinks  wrapper min_space_1'>
    <ul>
    {
            Links.map((item,index)=>{
                return(
                        <React.Fragment key={index}>
                            {item.submenu?
            <li className='has_sublinks_container'>
            <div className="has_sublinks">
            <NavLink to={item.path} className={({isActive})=>(isActive?'active_link':undefined) }>
                {item.link}
                <span><MdArrowForwardIos /> </span>
                </NavLink>
                {/* <Link to={item.path}>
                    {item.link}
                 <span><MdArrowForwardIos /> </span>
                 </Link> */}
            </div>
            <CollegeSubLink1 handleToggle={handleToggle} toggle ={toggle} submenu={item.submenu} />
            </li> 
            : 
            <li onClick={handleToggle}>
                {
                      item.external? <a href={item.external} target="_blank">{item.link}</a>:
                      <Link to={item.path}>{item.link}</Link>
                }
                {/* <NavLink to={item.path} className={({isActive})=>(isActive?'active_link':undefined) }>
                {item.link}
                
                </NavLink> */}
                {/* <Link to={item.path} className='active_link'>{item.link}</Link> */}
                
            </li>
            
            }
                            
                        </React.Fragment>
                )
            })
        }

    </ul>
      
    

</div>
  )
}
