import React from 'react'
import ButtonSlideBg from './ButtonSlideBg'

export default function Publication({data}) {
  return (

    
    <div className='publication_wrapper'>
    <div className='publication'>

        {
          data&&  data.map((item, index)=> <div key={index} className="item">
                    <div className="left">
                        <div className="imgage_wrapper">
                            <img src={item.img} alt="" />
                        </div>
                    </div>
                    <div className="right">
                        <h3>{item.title}</h3>
                        <h6>{item.area}</h6>
                        <p>{item.desc}</p>
                    </div>
            </div>)
        }
           
    </div>

    <div className="btn_wrapper">
        <ButtonSlideBg  text="More Articles" />
    </div>


    </div>
  )
}
