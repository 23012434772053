import Img1 from '../assets/images/staff gallery/img1.jpg'
import alt_img1 from '../assets/images/staff gallery/main image/alt_img1.jpg'
// import Img2 from '../assets/images/staff gallery/img1.png'
import Img2 from '../assets/images/staff gallery/Img2.png'
import alt_img2 from '../assets/images/staff gallery/main image/alt_img2.jpg'
import Img3 from '../assets/images/staff gallery/img3.png'
import alt_img3 from '../assets/images/staff gallery/main image/alt_img3.jpeg'
import Img4 from '../assets/images/staff gallery/img4.png'
import alt_img4 from '../assets/images/staff gallery/main image/alt_img4.jpg'
import Img5 from '../assets/images/staff gallery/img5.png'
import alt_img5 from '../assets/images/staff gallery/main image/alt_img5.jpg'
import Img6 from '../assets/images/staff gallery/img6.jpg'
import alt_img6 from '../assets/images/staff gallery/main image/alt_img6.png'
import Img7 from '../assets/images/staff gallery/img7.jpg'
import vdean from '../assets/images/staff gallery/vdean3.jpg'
import alt_img8 from '../assets/images/staff gallery/main image/alt_img8.jpg'





import pub1 from '../assets/images/staff publication/1.jpg'
import pub2 from '../assets/images/staff publication/2.jpg'
import pub3 from '../assets/images/staff publication/3.jpg'
import alt_img7 from '../assets/images/staff gallery/main image/alt_img7.jpg'

import DeanOfClinical from '../assets/images/staff gallery/clinical/dean_clinical.jpg'
import deanClinicalAlt from '../assets/images/staff gallery/clinical/alt/dean_clinical_alt.JPG'



import {GiChemicalDrop, GiArtificialIntelligence} from 'react-icons/gi'
import {BiData} from 'react-icons/bi'

// function Shuffle(arr){
//     return arr.sort(()=>Math.random() - 0.5 )
// }

function shuffle(array) {
    let currentIndex = array.length,  randomIndex;
  
    // While there remain elements to shuffle.
    while (currentIndex != 0) {
  
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
  
      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
  
    return array;
  }
  
  

const Data = shuffle( [
    {
        name:'Prof. Lattef Salawu',
        image:Img1,
        altImg:alt_img1,
        dept:'Haematology & Immunology',
        socialLinks:'',
        link:'lateef-salawu',
        phone:'09034897594',
        faculty:'Basic Medical Sciences',
        desig:'Dean',
        experience:[
            {
                year:'2021 - Present',
                institution:'Computer Science Department, Obafemi Awolowo University,Ile-Ife, Osun State',
                desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
            },
            // {
            //     year:'2020 - 2021',
            //     institution:'National Youth Service Corps (NYSC), Infomation Technology and Communication Unit (INTECU), Obafemi Awolowo University,Ile-Ife, Osun State',
            //     desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
            // },
            {
                year:'2014 - 2019',
                institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
            },
            {
                year:'2005 - 2013',
                institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
            },
         
           
        ],
        research:[
            {
                icon:<GiArtificialIntelligence />,
                title:'Artificial Intelligence',
                desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
            },
            {
                icon:<BiData />,
                title:'Data Science',
                desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
            },
            {
                icon:<GiChemicalDrop />,
                title:'Machine Learning',
                desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
            },
        ],
        publication:[
            {
                img:pub1,
                title:'How can we prevent a glaciers melting?',
                area:'Ecology & Safety, Volume 10, 2016',
                desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
            },
            {
                img:pub2,
                title:'Global warming: how to prevent a catastrophe. Arguments and conclusions.',
                area:'Ecology & Safety, Volume 10, 2016',
                desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
            },
            {
                img:pub3,
                title:'What type of proteins are useful?',
                area:'Ecology & Safety, Volume 10, 2016',
                desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
            }
        ],
        award:[
            {
                totalAward:10
            },
            {
                totalHonour:20
            },
            {
                totalOther:15
            }
        ],
        teaching:{
            undergraduate:[
                {
                    title:'CSC201 - Introduction to Java Programming Language',
                    description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                },
                {
                    title:'CSC315 - Introduction to Information System',
                    description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                },
                {
                    title:'CSC419 - Foundation of Programming Language',
                    description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                }
            ],
            postgraduate:[
                {
                    title:'CSC601 - Introduction to Python Programming Language 1',
                    description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                },
                {
                    title:'CSC619 - Introduction to Python Programming Language 2',
                    description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                },
                {
                    title:'CSC791 - Introduction to Python Programming Language 3',
                    description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                }
            ]
        }
    },
    
   
    {
        name:'Dr. Samson Odukoya',
        image: vdean,
        altImg:alt_img8,
        dept:'Haematology & Immunology',
        socialLinks:'',
        link:'samson-odukoya',
        phone:'09034897594',
        faculty:'Basic Medical Sciences',
        desig:'Vice Dean',
        experience:[
            {
                year:'2021 - Present',
                institution:'Computer Science Department, Obafemi Awolowo University,Ile-Ife, Osun State',
                desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
            },
            // {
            //     year:'2020 - 2021',
            //     institution:'National Youth Service Corps (NYSC), Infomation Technology and Communication Unit (INTECU), Obafemi Awolowo University,Ile-Ife, Osun State',
            //     desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
            // },
            {
                year:'2014 - 2019',
                institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
            },
            {
                year:'2005 - 2013',
                institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
            },
         
           
        ],
        research:[
            {
                icon:<GiArtificialIntelligence />,
                title:'Artificial Intelligence',
                desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
            },
            {
                icon:<BiData />,
                title:'Data Science',
                desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
            },
            {
                icon:<GiChemicalDrop />,
                title:'Machine Learning',
                desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
            },
        ],
        publication:[
            {
                img:pub1,
                title:'How can we prevent a glaciers melting?',
                area:'Ecology & Safety, Volume 10, 2016',
                desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
            },
            {
                img:pub2,
                title:'Global warming: how to prevent a catastrophe. Arguments and conclusions.',
                area:'Ecology & Safety, Volume 10, 2016',
                desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
            },
            {
                img:pub3,
                title:'What type of proteins are useful?',
                area:'Ecology & Safety, Volume 10, 2016',
                desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
            }
        ],
        award:[
            {
                totalAward:10
            },
            {
                totalHonour:20
            },
            {
                totalOther:15
            }
        ],
        teaching:{
            undergraduate:[
                {
                    title:'CSC201 - Introduction to Java Programming Language',
                    description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                },
                {
                    title:'CSC315 - Introduction to Information System',
                    description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                },
                {
                    title:'CSC419 - Foundation of Programming Language',
                    description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                }
            ],
            postgraduate:[
                {
                    title:'CSC601 - Introduction to Python Programming Language 1',
                    description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                },
                {
                    title:'CSC619 - Introduction to Python Programming Language 2',
                    description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                },
                {
                    title:'CSC791 - Introduction to Python Programming Language 3',
                    description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                }
            ]
        }
    },

    {
        name:'Prof. Morenike Ukpong ',
        image:Img2,
        altImg:alt_img2,
        dept:'Child Dental Health',
        socialLinks:'',
        link:'morenike-ukpong',
        phone:'09034897594',
        faculty:'Dentistry',
        desig:'Dean',
        experience:[
            {
                year:'2021 - Present',
                institution:'Computer Science Department, Obafemi Awolowo University,Ile-Ife, Osun State',
                desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
            },
            {
                year:'2014 - 2019',
                institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
            },
            {
                year:'2005 - 2013',
                institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
            }
         
           
        ],
        research:[
            {
                icon:<GiArtificialIntelligence />,
                title:'Artificial Intelligence',
                desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
            },
            {
                icon:<BiData />,
                title:'Data Science',
                desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
            },
            {
                icon:<GiChemicalDrop />,
                title:'Machine Learning',
                desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
            },
        ],
        publication:[
            {
                img:pub1,
                title:'How can we prevent a glaciers melting?',
                area:'Ecology & Safety, Volume 10, 2016',
                desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
            },
            {
                img:pub2,
                title:'Global warming: how to prevent a catastrophe. Arguments and conclusions.',
                area:'Ecology & Safety, Volume 10, 2016',
                desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
            },
            {
                img:pub3,
                title:'What type of proteins are useful?',
                area:'Ecology & Safety, Volume 10, 2016',
                desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
            }
        ],
        award:[
            {
                totalAward:10
            },
            {
                totalHonour:20
            },
            {
                totalOther:15
            }
        ],
        teaching:{
            undergraduate:[
                {
                    title:'CSC201 - Introduction to Java Programming Language',
                    description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                },
                {
                    title:'CSC315 - Introduction to Information System',
                    description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                },
                {
                    title:'CSC419 - Foundation of Programming Language',
                    description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                }
            ],
            postgraduate:[
                {
                    title:'CSC601 - Introduction to Python Programming Language 1',
                    description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                },
                {
                    title:'CSC619 - Introduction to Python Programming Language 2',
                    description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                },
                {
                    title:'CSC791 - Introduction to Python Programming Language 3',
                    description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                }
            ]
        }
    },
    {
        name:'Prof. Kikelomo Kolawole',
        image:Img3,
        altImg:alt_img3,
        dept:'Child Dental Health',
        socialLinks:'',
        link:'kikelomo-kolawole',
        phone:'09034897594',
        faculty:'Clinical Sciences',
        desig:'Head of Department',
        experience:[
            {
                year:'2021 - Present',
                institution:'Computer Science Department, Obafemi Awolowo University,Ile-Ife, Osun State',
                desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
            },
            {
                year:'2014 - 2019',
                institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
            },
            {
                year:'2005 - 2013',
                institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
            }
         
           
        ],
        research:[
            {
                icon:<GiArtificialIntelligence />,
                title:'Artificial Intelligence',
                desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
            },
            {
                icon:<BiData />,
                title:'Data Science',
                desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
            },
            {
                icon:<GiChemicalDrop />,
                title:'Machine Learning',
                desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
            },
        ],
        publication:[
            {
                img:pub1,
                title:'How can we prevent a glaciers melting?',
                area:'Ecology & Safety, Volume 10, 2016',
                desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
            },
            {
                img:pub2,
                title:'Global warming: how to prevent a catastrophe. Arguments and conclusions.',
                area:'Ecology & Safety, Volume 10, 2016',
                desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
            },
            {
                img:pub3,
                title:'What type of proteins are useful?',
                area:'Ecology & Safety, Volume 10, 2016',
                desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
            }
        ],
        award:[
            {
                totalAward:10
            },
            {
                totalHonour:20
            },
            {
                totalOther:15
            }
        ],
        teaching:{
            undergraduate:[
                {
                    title:'CSC201 - Introduction to Java Programming Language',
                    description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                },
                {
                    title:'CSC315 - Introduction to Information System',
                    description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                },
                {
                    title:'CSC419 - Foundation of Programming Language',
                    description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                }
            ],
            postgraduate:[
                {
                    title:'CSC601 - Introduction to Python Programming Language 1',
                    description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                },
                {
                    title:'CSC619 - Introduction to Python Programming Language 2',
                    description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                },
                {
                    title:'CSC791 - Introduction to Python Programming Language 3',
                    description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                }
            ]
        }
    },
    {
        name:'Dr. Olufemi Ogundipe',
        image:Img4,
        altImg:alt_img5,
        dept:'Oral Maxillofacial Surgery',
        socialLinks:'',
        link:'olufemi-ogundipe',
        phone:'09034897594',
        faculty:'Dentistry',
        experience:[
            {
                year:'2021 - Present',
                institution:'Computer Science Department, Obafemi Awolowo University,Ile-Ife, Osun State',
                desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
            },
            {
                year:'2014 - 2019',
                institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
            },
            {
                year:'2005 - 2013',
                institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
            }
         
           
        ],
        research:[
            {
                icon:<GiArtificialIntelligence />,
                title:'Artificial Intelligence',
                desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
            },
            {
                icon:<BiData />,
                title:'Data Science',
                desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
            },
            {
                icon:<GiChemicalDrop />,
                title:'Machine Learning',
                desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
            },
        ],
        publication:[
            {
                img:pub1,
                title:'How can we prevent a glaciers melting?',
                area:'Ecology & Safety, Volume 10, 2016',
                desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
            },
            {
                img:pub2,
                title:'Global warming: how to prevent a catastrophe. Arguments and conclusions.',
                area:'Ecology & Safety, Volume 10, 2016',
                desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
            },
            {
                img:pub3,
                title:'What type of proteins are useful?',
                area:'Ecology & Safety, Volume 10, 2016',
                desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
            }
        ],
        award:[
            {
                totalAward:10
            },
            {
                totalHonour:20
            },
            {
                totalOther:15
            }
        ],
        teaching:{
            undergraduate:[
                {
                    title:'CSC201 - Introduction to Java Programming Language',
                    description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                },
                {
                    title:'CSC315 - Introduction to Information System',
                    description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                },
                {
                    title:'CSC419 - Foundation of Programming Language',
                    description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                }
            ],
            postgraduate:[
                {
                    title:'CSC601 - Introduction to Python Programming Language 1',
                    description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                },
                {
                    title:'CSC619 - Introduction to Python Programming Language 2',
                    description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                },
                {
                    title:'CSC791 - Introduction to Python Programming Language 3',
                    description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                }
            ]
        }
    },
    {
        name:'Dr. Olanrewaju Ibigbami',
        image:Img5,
        altImg:alt_img6,
        dept:'Mental Health',
        socialLinks:'',
        link:'olanrewaju-ibigbami',
        phone:'09034897594',
        faculty:'Clinical Sciences',
        experience:[
            {
                year:'2021 - Present',
                institution:'Computer Science Department, Obafemi Awolowo University,Ile-Ife, Osun State',
                desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
            },
            {
                year:'2014 - 2019',
                institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
            },
            {
                year:'2005 - 2013',
                institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
            }
         
           
        ],
        research:[
            {
                icon:<GiArtificialIntelligence />,
                title:'Artificial Intelligence',
                desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
            },
            {
                icon:<BiData />,
                title:'Data Science',
                desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
            },
            {
                icon:<GiChemicalDrop />,
                title:'Machine Learning',
                desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
            },
        ],
        publication:[
            {
                img:pub1,
                title:'How can we prevent a glaciers melting?',
                area:'Ecology & Safety, Volume 10, 2016',
                desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
            },
            {
                img:pub2,
                title:'Global warming: how to prevent a catastrophe. Arguments and conclusions.',
                area:'Ecology & Safety, Volume 10, 2016',
                desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
            },
            {
                img:pub3,
                title:'What type of proteins are useful?',
                area:'Ecology & Safety, Volume 10, 2016',
                desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
            }
        ],
        award:[
            {
                totalAward:10
            },
            {
                totalHonour:20
            },
            {
                totalOther:15
            }
        ],
        teaching:{
            undergraduate:[
                {
                    title:'CSC201 - Introduction to Java Programming Language',
                    description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                },
                {
                    title:'CSC315 - Introduction to Information System',
                    description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                },
                {
                    title:'CSC419 - Foundation of Programming Language',
                    description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                }
            ],
            postgraduate:[
                {
                    title:'CSC601 - Introduction to Python Programming Language 1',
                    description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                },
                {
                    title:'CSC619 - Introduction to Python Programming Language 2',
                    description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                },
                {
                    title:'CSC791 - Introduction to Python Programming Language 3',
                    description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                }
            ]
        },
        research:[
            {
                icon:<GiArtificialIntelligence />,
                title:'Artificial Intelligence',
                desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
            },
            {
                icon:<BiData />,
                title:'Data Science',
                desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
            },
            {
                icon:<GiChemicalDrop />,
                title:'Machine Learning',
                desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
            },
        ],
        publication:[
            {
                img:pub1,
                title:'How can we prevent a glaciers melting?',
                area:'Ecology & Safety, Volume 10, 2016',
                desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
            },
            {
                img:pub2,
                title:'Global warming: how to prevent a catastrophe. Arguments and conclusions.',
                area:'Ecology & Safety, Volume 10, 2016',
                desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
            },
            {
                img:pub3,
                title:'What type of proteins are useful?',
                area:'Ecology & Safety, Volume 10, 2016',
                desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
            }
        ],
        award:[
            {
                totalAward:10
            },
            {
                totalHonour:20
            },
            {
                totalOther:15
            }
        ],
        teaching:{
            undergraduate:[
                {
                    title:'CSC201 - Introduction to Java Programming Language',
                    description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                },
                {
                    title:'CSC315 - Introduction to Information System',
                    description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                },
                {
                    title:'CSC419 - Foundation of Programming Language',
                    description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                }
            ],
            postgraduate:[
                {
                    title:'CSC601 - Introduction to Python Programming Language 1',
                    description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                },
                {
                    title:'CSC619 - Introduction to Python Programming Language 2',
                    description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                },
                {
                    title:'CSC791 - Introduction to Python Programming Language 3',
                    description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                }
            ]
        }
    },
   
    {
        name:'Prof. Babatope Kolawole',
        image:Img7,
        altImg:alt_img7,
        dept:'Medicine',
        socialLinks:'',
        link:'babatope-kolawole',
        phone:'09034897594',
        faculty:'Clinical Sciences',
        desig:'Provost',
        experience:[
            {
                year:'2021 - Present',
                institution:'Computer Science Department, Obafemi Awolowo University,Ile-Ife, Osun State',
                desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
            },
            {
                year:'2014 - 2019',
                institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
            },
            {
                year:'2005 - 2013',
                institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
            }
         
           
        ],
        research:[
            {
                icon:<GiArtificialIntelligence />,
                title:'Artificial Intelligence',
                desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
            },
            {
                icon:<BiData />,
                title:'Data Science',
                desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
            },
            {
                icon:<GiChemicalDrop />,
                title:'Machine Learning',
                desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
            },
        ],
        publication:[
            {
                img:pub1,
                title:'How can we prevent a glaciers melting?',
                area:'Ecology & Safety, Volume 10, 2016',
                desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
            },
            {
                img:pub2,
                title:'Global warming: how to prevent a catastrophe. Arguments and conclusions.',
                area:'Ecology & Safety, Volume 10, 2016',
                desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
            },
            {
                img:pub3,
                title:'What type of proteins are useful?',
                area:'Ecology & Safety, Volume 10, 2016',
                desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
            }
        ],
        award:[
            {
                totalAward:10
            },
            {
                totalHonour:20
            },
            {
                totalOther:15
            }
        ],
        teaching:{
            undergraduate:[
                {
                    title:'CSC201 - Introduction to Java Programming Language',
                    description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                },
                {
                    title:'CSC315 - Introduction to Information System',
                    description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                },
                {
                    title:'CSC419 - Foundation of Programming Language',
                    description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                }
            ],
            postgraduate:[
                {
                    title:'CSC601 - Introduction to Python Programming Language 1',
                    description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                },
                {
                    title:'CSC619 - Introduction to Python Programming Language 2',
                    description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                },
                {
                    title:'CSC791 - Introduction to Python Programming Language 3',
                    description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                }
            ]
        }
    },
    {
        name:'Prof. Boladale Mapayi',
        image:Img6,
        // altImg:alt_img4,
        altImg:Img6,
        dept:'Mental Health',
        socialLinks:'',
        link:'boladale-mapayi',
        phone:'09034897594',
        faculty:'Clinical Sciences',
        experience:[
            {
                year:'2021 - Present',
                institution:'Computer Science Department, Obafemi Awolowo University,Ile-Ife, Osun State',
                desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
            },
            {
                year:'2014 - 2019',
                institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
            },
            {
                year:'2005 - 2013',
                institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
            }
         
           
        ],
        research:[
            {
                icon:<GiArtificialIntelligence />,
                title:'Artificial Intelligence',
                desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
            },
            {
                icon:<BiData />,
                title:'Data Science',
                desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
            },
            {
                icon:<GiChemicalDrop />,
                title:'Machine Learning',
                desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
            },
        ],
        publication:[
            {
                img:pub1,
                title:'How can we prevent a glaciers melting?',
                area:'Ecology & Safety, Volume 10, 2016',
                desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
            },
            {
                img:pub2,
                title:'Global warming: how to prevent a catastrophe. Arguments and conclusions.',
                area:'Ecology & Safety, Volume 10, 2016',
                desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
            },
            {
                img:pub3,
                title:'What type of proteins are useful?',
                area:'Ecology & Safety, Volume 10, 2016',
                desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
            }
        ],
        award:[
            {
                totalAward:10
            },
            {
                totalHonour:20
            },
            {
                totalOther:15
            }
        ],
        teaching:{
            undergraduate:[
                {
                    title:'CSC201 - Introduction to Java Programming Language',
                    description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                },
                {
                    title:'CSC315 - Introduction to Information System',
                    description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                },
                {
                    title:'CSC419 - Foundation of Programming Language',
                    description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                }
            ],
            postgraduate:[
                {
                    title:'CSC601 - Introduction to Python Programming Language 1',
                    description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                },
                {
                    title:'CSC619 - Introduction to Python Programming Language 2',
                    description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                },
                {
                    title:'CSC791 - Introduction to Python Programming Language 3',
                    description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                }
            ]
        }
    },
    {
        name:'Prof. Oludayo Sowande',
        // name:'Prof. Oludayo Adedapo Sowande',
        image:DeanOfClinical,
        altImg:deanClinicalAlt,
        dept:'Surgery',
        socialLinks:'',
        link:'sowande',
        phone:'09034897594',
        faculty:'Clinical Sciences',
        desig:'Dean of Clinical Sciences',
        experience:[
            {
                year:'2021 - Present',
                institution:'Computer Science Department, Obafemi Awolowo University,Ile-Ife, Osun State',
                desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
            },
            {
                year:'2014 - 2019',
                institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
            },
            {
                year:'2005 - 2013',
                institution:'Computer Science Department, Federal University of Technology, Akure (FUTA)',
                desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
            }
         
           
        ],
        research:[
            {
                icon:<GiArtificialIntelligence />,
                title:'Artificial Intelligence',
                desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
            },
            {
                icon:<BiData />,
                title:'Data Science',
                desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
            },
            {
                icon:<GiChemicalDrop />,
                title:'Machine Learning',
                desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit atque quisquam aliquid, labore autem inventore dolores magnam aut accusamus placeat nesciunt iure ex consequatur veniam corrupti. Illo ratione nihil officiis!'
            },
        ],
        publication:[
            {
                img:pub1,
                title:'How can we prevent a glaciers melting?',
                area:'Ecology & Safety, Volume 10, 2016',
                desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
            },
            {
                img:pub2,
                title:'Global warming: how to prevent a catastrophe. Arguments and conclusions.',
                area:'Ecology & Safety, Volume 10, 2016',
                desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
            },
            {
                img:pub3,
                title:'What type of proteins are useful?',
                area:'Ecology & Safety, Volume 10, 2016',
                desc:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
            }
        ],
        award:[
            {
                totalAward:10
            },
            {
                totalHonour:20
            },
            {
                totalOther:15
            }
        ],
        teaching:{
            undergraduate:[
                {
                    title:'CSC201 - Introduction to Java Programming Language',
                    description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                },
                {
                    title:'CSC315 - Introduction to Information System',
                    description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                },
                {
                    title:'CSC419 - Foundation of Programming Language',
                    description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                }
            ],
            postgraduate:[
                {
                    title:'CSC601 - Introduction to Python Programming Language 1',
                    description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                },
                {
                    title:'CSC619 - Introduction to Python Programming Language 2',
                    description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                },
                {
                    title:'CSC791 - Introduction to Python Programming Language 3',
                    description:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi officia,'
                }
            ]
        }
    },
])

export default Data