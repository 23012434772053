import React,{useState} from 'react'
import Logo from '../../assets/images/oau-logo.png'
import CollegeDestopTabletNavLinks from './CollegeDestopTabletNavLinks'
import CollegeSideDraw from './CollegeSideDraw'
import CollegeTopNavBar from './CollegeTopNavBar'
import Harmbugger from '../Harmbugger'
import { Outlet } from 'react-router-dom'

export default function CollegeNavbar() {
  let [toggle, setToggle]= useState(false)
  let handleToggle = ()=>{
    setToggle((prev)=>!prev)
  }


  return (

    <>
     <div className="chs_container_navbar">
      <CollegeTopNavBar />
    <div className="chs_wrapper_navbar">
        <div className="container">
            <div className="left_box">
                <img src={Logo} alt="" />
            </div>
            <div className="right_box">
            <CollegeDestopTabletNavLinks />
              <Harmbugger handleToggle={handleToggle} toggle ={toggle}  />
            </div>
        </div>
       
    </div>
       <CollegeSideDraw handleToggle={handleToggle} toggle ={toggle}  />
      
    </div>
    <div className='chs_top'>

    <Outlet />
    </div>

    </>
   
  )
}
