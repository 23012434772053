import React,{useState} from 'react'
import Logo from '../../assets/images/oau-logo.png'

import {FaFacebookF} from 'react-icons/fa'
import {IoLogoTwitter} from 'react-icons/io'
import {AiOutlineInstagram} from 'react-icons/ai'
import {FaPinterestP} from 'react-icons/fa'
import {FaLinkedinIn} from 'react-icons/fa'
import {BsYoutube} from 'react-icons/bs'
import { Link } from 'react-router-dom'
import FooterLinks from '../../utilities/FooterLinks'



 function Links({data}){
    return(
        <>
           {
            data.map((item, index)=>{
                return(
                    <li key={index}><Link>{item.name}</Link></li>
                )
            })
        }
        </>
     
    )
}



export default function Footer() {
    let date = new Date()
    const [year] = useState(date.getFullYear())






  return (
    <div className='footer_container'>
    <div className='footer_wrapper container'>
        <div className="box">
                <Link className="wrapper">
                    <div className="image_wrapper">
                        <img src={Logo} alt="" />
                    </div>
                    <div className="text_wrapper">
                        <h4>College of Health Sciences</h4>
                        <p>Obafemi Awolowo University, Ile-Ife.</p>
                    </div>
                   
                </Link>
                <div className='history'>
                        <p>
                        The College of Health Sciences, OAU since its inception  in 1972, has made major contributions to the supply of highly trained medical graduates to the Nigerian health sector.  
                        </p>
                        <p>
                            +2349034897594
                        </p>
                        <p>
                           provostchs@oauife.edu.ng
                        </p>
                </div>
        </div>
        

            {
                FooterLinks.map((item, index)=>{
                    return(
                        <div key={index} className="box">
                             <h5>{item.title}</h5>
                             <ul>
                                <Links data={item.link} />
                             </ul>
                        </div>
                    )
                })
            }
                {/* <h5>Our Campus</h5>

                <ul>
                    <li><Link>Gallery</Link></li>
                    <li><Link>Gallery</Link></li>
                    <li><Link>Gallery</Link></li>
                    <li><Link>Gallery</Link></li>
                </ul> */}
        
        {/* <div className="box">

        </div>
        <div className="box">
            
        </div> */}
    </div>

<div className="follow_us_container">
<div className="follow_us_wrapper container">
        <div className="left">
            <p>
                <span>Follow Us On</span>
                <a href="https://www.facebook.com/profile.php?id=100083298822865" target="_blank"><FaFacebookF /></a>
                <a href="https://twitter.com/chsoau" target="_blank"><IoLogoTwitter /></a>
                <a href="https://www.instagram.com/chsoau/" target="_blank"><AiOutlineInstagram /></a>
                <a href="#"><BsYoutube/></a>
                <a href="#"><FaPinterestP /></a>
                <a href="#"><FaLinkedinIn /></a>
            </p>
        </div>
        <div className="right">
            <p>
                &copy; {year} College of Health Sciences, OAU.
            </p>
        </div>
</div>
    
</div>
    </div>
  )


}
