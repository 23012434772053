import React from 'react'
import {BiClinic} from 'react-icons/bi'
import {FaTeeth,FaHandHoldingMedical} from 'react-icons/fa'
import {RiMentalHealthLine} from 'react-icons/ri'
import { Link } from 'react-router-dom'
import ButtonSlideBg from './ButtonSlideBg'

export default function Faculty() {
  return (
    <div className='faculty_container'>
    <div className='faculty_wrapper container'>
    <h3 className='heading_1 '>Our Faculty & Institute</h3>   
    <p className='sub_heading'>Our Faculty & Institute is a premier educational institution dedicated to providing the highest quality of education. </p>     
    
    <div className="items">
        <div className="box">
            <div className="icon_wrapper">
            <FaHandHoldingMedical size={70} />
            <h5>Faculty of Basic Medical Sciences</h5>
            </div>

            {/* <div className="view_link_container">
                <a href="http://bms.oauife.edu.ng/" target='_blank'>View</a>
            </div> */}
            {/* <a href="http://fcs.oauife.edu.ng/" className=""></a> */}
            <ButtonSlideBg to="https://bms.oauife.edu.ng/" className="btn" text="View"/>
            {/* <Link>View</Link> */}
        </div>
        <div className="box">
        <div className="icon_wrapper">
            <BiClinic size={70} />
            <h5>Faculty of Clinical Sciences</h5>
            </div>
            <ButtonSlideBg link to="https://fcs.oauife.edu.ng/" className="btn" text="View"/>
        </div>
        <div className="box">
        <div className="icon_wrapper">
            <FaTeeth size={65} />
            <h5>Faculty of Dentistry</h5>
            </div>
            <ButtonSlideBg to="https://dentistry.oauife.edu.ng/" className="btn" text="View"/>
        </div>
        <div className="box">
        <div className="icon_wrapper">
            <RiMentalHealthLine size={70} />
            <h5>Institute of Public Health</h5>
            </div>
            <ButtonSlideBg to="https://iph.oauife.edu.ng/"  className="btn" text="View"/>
        </div>
    </div>
    
    
    </div>

    </div>
  )
}
