import React from 'react'


export default function StaffProfileBanner({image, name, dept}) {
  return (
    <div className='staff_profile_banner_container'>
        
        <div className="content">
            <h5>{name}</h5>
            <p>{dept}</p>
        </div>

        <div className="image_wrapper">
            <img src={image} alt="" />
        </div>
    </div>
  )
}
