import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import {MdArrowBackIos,MdArrowForwardIos} from 'react-icons/md'




export default function CollegeSubLink2({toggle,handleToggle,submenu}) {
//    console.log(submenu)
  return (
    <div className="subnavlinks past_leaders">
    <ul>
    <li ><p className='back_link past_leaders'><MdArrowBackIos /> Back</p></li>
        {
            submenu.map((items,index)=>{
                return(
                    <React.Fragment   key={index}>
                     
                           
                        
                            
                            <li onClick={handleToggle}>
                                
                                <NavLink to={items.path} className={({isActive})=>(isActive?'active_link':undefined) }>
                                 {items.link}
               
                                </NavLink>
                            </li>
                        
                       
                    </React.Fragment>
                )
            })
        }
        {/* <li ><p className='back_link past_leaders'><MdArrowBackIos /> Back</p></li>
        <li onClick={handleToggle}><Link to="/">Provost</Link></li>
        <li onClick={handleToggle}><Link to="/">Deputy Provost</Link></li>
        <li onClick={handleToggle}><Link to="/">Director</Link></li>
        <li onClick={handleToggle}><Link to="/">Dean</Link></li>
        <li onClick={handleToggle}><Link to="/">Head of Department</Link></li>
        <li onClick={handleToggle}><Link to="/">College Secretary</Link></li> */}
    </ul>
</div>
  )
}
