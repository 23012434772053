import React,{useState, useEffect} from 'react'

export default function EventCountDown({date}) {

    let CountDown = ()=>{
        const year = new Date().getFullYear();
        const difference = +new Date(date) - +new Date()
          // console.log(difference)
          let timeLeft = {}
          if(difference > 0){
            timeLeft = {
              Days:Math.floor(difference / (1000 * 60 * 60  *24)),
              Hours: Math.floor(difference / (1000 * 60 * 60 ) % 24 ),
              Minutes : Math.floor((difference / 1000 / 60)%60),
              Seconds: Math.floor((difference / 1000) % 60) <=0 ? 0 : Math.floor((difference / 1000) % 60)
            }
          }
    
          return timeLeft
      }
    
      let [timeLeft, setTimeLeft] = useState(CountDown())
      
      
      useEffect(()=>{
    
        const id = setInterval(()=>{
          setTimeLeft(CountDown())
        },1000)
        return ()=>{
          clearTimeout(id)
        }
      },[])
    
    //    const timerComponents = Object.keys(timeLeft).map(interval => {
    //     if (!timeLeft[interval]) {
    //       return;
    //     }
    
    //     return (
    //        <span>
    //         {timeLeft[interval]} {interval}{" "}
    //       </span>
    //     )
    //   });


  return (
    <div className="countdown">

        {
            Object.keys(timeLeft).map((interval, index)=>{
                // if (!timeLeft[interval] || timeLeft.lenght  == 0) {
                //     return 
                //     <p>Pass</p>;
                //   }
                return(
                    <div className="items">
                        <h5>{timeLeft[interval]}</h5>
                        <p>{interval}</p>
                    </div>
                )
            })
        }
    {/* <div className="day">
        <h5>20</h5>
        <p>Day</p>
    </div>
    <div className="hour">
        <h5>20</h5>
        <p>Hour</p>
    </div>
    <div className="minuite">
        <h5>20</h5>
        <p>Minuite</p>
    </div>
    <div className="second">
        <h5>20</h5>
        <p>Second</p>
    </div> */}
  </div>
  )
}
