import React,{useEffect} from 'react'
import { staffBtnToggler } from '../../utilities/buttonToggler'
import FacultyStaffPagination from './FacultyStaffPagination'



export default function Faculty_Staff_Layout({academicStaff, nonAcademicStaff, faculty, text}) {
    useEffect(()=>{
    
    
        let btns = [...document.querySelectorAll('.button_wrapper > ul > li')]
        let staffContents = [...document.querySelectorAll('.content_wrapper > ul>li')]
        staffBtnToggler( btns, staffContents)
     
      },[])
  return (
    <div className="staffs_container">
              <div className="staff_wrapper">
                  <div data-aos="fade-down" data-aos-delay={200} className="principal_officals">
                  
                      <div className="items">
                      <div className="image_wrapper">
                        <img src={academicStaff.filter(item=>item.faculty == faculty && (item.desig == 'Dean'&&item)).map(item=>item.image)} alt="" />
                      </div>
                      <div className="name">
                          <h4>{academicStaff.filter(item=>item.faculty == faculty && (item.desig == 'Dean'&&item)).map(item=>item.name)} </h4>
                          <p>{academicStaff.filter(item=>item.faculty == faculty && (item.desig == 'Dean'&&item)).map(item=>item.dept)}</p>
                          <h5>Current Dean of {text}</h5>
                      </div>
                </div>
                      
                    
                      
                      <div className="sub_item1">
                          <div data-aos="fade-down" data-aos-delay={200} className="items">
                          <div className="image_wrapper">
                              <img src={academicStaff.filter(item=>item.faculty == faculty && (item.desig == 'Vice Dean'&&item)).map(item=>item.image)} alt="" />
                          </div>
                          <div className="names">
                              <h4>{academicStaff.filter(item=>item.faculty == faculty && (item.desig == 'Vice Dean'&&item)).map(item=>item.name)} </h4>
                              <p>{academicStaff.filter(item=>item.faculty == faculty && (item.desig == 'Vice Dean'&&item)).map(item=>item.dept)}</p>
                          <h5>Vice Dean of {text}</h5>
                          </div>
                          </div>
                          <div data-aos="fade-down" data-aos-delay={400} className="items">
                          <div className="image_wrapper">
                              <img src={nonAcademicStaff.filter(item=>item.faculty == faculty && (item.desig == 'Faculty Secretary'&&item)).map(item=>item.image)} alt="" />
                              
                          </div>
                          <div className="names">
                              <h4 style={{marginTop:'1rem'}}>{nonAcademicStaff.filter(item=>item.faculty == faculty && (item.desig == 'Faculty Secretary'&&item)).map(item=>item.name)} </h4>
                              {/* <p style={{visibility:'hidden'}}>{Data.filter(item=>item.faculty == "Basic Medical Sciences" && (item.desig == 'Vice Dean'&&item)).map(item=>item.dept)}</p> */}
                          <h5>Faculty Secretary</h5>
                          </div>
                          
                          </div>
                      </div>
                     
                     
                     
                     
                  </div>

                  <div className="toggler button_wrapper" style={{marginTop:'3rem'}}>
                    <ul>
                      <li >Academic</li>
                      <li >Non-academic</li>
                    </ul>
                  </div>
                  <div className="page content_wrapper">

                  <FacultyStaffPagination items={{academic:academicStaff.filter(item=>item.faculty == faculty && item.desig != 'Dean' && item.desig != "Vice Dean" && item), nonAcademic:nonAcademicStaff.filter(item=>item.faculty == faculty && (item.desig != 'Faculty Secretary'&&item))}}   itemsPerPage={4} />

                  </div>


                
              </div>
          </div>
  )
}
