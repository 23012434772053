import { Route, BrowserRouter  as Router, Routes} from 'react-router-dom'
import CollegeRoutes from './modules/college/CollegeRoutes';
import NoMatch from './modules/college/pages/NoMatch';
import CollegeNavbar from './components/College/CollegeNavbar';
import Home from './modules/college/pages/Home';
import Register from './modules/college/pages/Register';
import Login from './modules/college/pages/Login';


// import aos
import Aos from 'aos';
// import aos css
import 'aos/dist/aos.css';


function App() {
  function killCopy(e){
    return false;
}
function reEnable(){
    return true;
}
document.onselectstart=new Function ("return false");
if (window.sidebar){
    document.onmousedown=killCopy;
    document.onclick=reEnable;
}

Aos.init({
  // duration: 1800,
  duration: 1900,
  offset: 100, 
});
  return (
<Router >
    <Routes>
     {CollegeRoutes()}
     {/* <Route  path='sign-up'  element={<Register/>}/> */}
        <Route  path='sign-in'  element={<Login/>}/>
      <Route  path='*'  element={<NoMatch />}/>
    </Routes>
</Router>
  );
}

export default App;
