import React,{useEffect} from 'react'
import Layout1 from '../../../components/College/Layout1'

export default function PostgraduateProgram() {
  useEffect(()=>{
    document.title="College of Health Sciences - Postgraduate programme, Obafemi Awolwo University, Ile-Ife "
    window.scrollTo({
      top:0
    })
  })
  return (
    <Layout1 title='Postgraduate Programme' text="Programme">
     <div className='academic_content sub_title_text'>
            <h2>Postgraduate Degree Programmes</h2>
            <p >
           Postraduate Programme in College of Health Sciences, Obafemi Awolowo Universtiy, Ile-Ife is to produce Masters of Science, Masters of Philosophy, and Doctor of Phylosophy graduates that will meet / satisfy the national needs of professionals in health care sector - providing highly skilled specialized treatment, and leadership in health care and training institutions. Also to train Medical Practitioners who will be research oriented with wide knowledge base that can fit into clinical Prcatice, training and mentoring of others both nationally and internationally. 
		        </p>
          </div>
</Layout1>
  )
}
