// FACT ABOUT COLLEGE OF HEALTH SCIENCES
import Grillo from '../assets/images/past provost/img1.jpg'

import Provost from '../assets/images/provost.jpg'


// IFUMSA EXCOS
import president from '../assets/images/ifumsa/presi.png'
import vp from '../assets/images/ifumsa/VP.jpg'
import gen_sec from '../assets/images/ifumsa/gen_sec.jpg'
import ags from '../assets/images/ifumsa/ags.jpg'
import pro from '../assets/images/ifumsa/pro.jpg'
import finsec from '../assets/images/ifumsa/finsec.jpg'
import treasurer from '../assets/images/ifumsa/treasurer.jpg'
import sportDir from '../assets/images/ifumsa/sportDir.jpg'
import socialDIr from '../assets/images/ifumsa/socialDir.jpg'
import welfare from '../assets/images/ifumsa/welfare.jpg'

const Fact = [
    {
        title:'Year of Establishment',
        text:<p>1971 as the Faculty of Health Sciences (formal inauguration – 8 May, 1972).
        <br />
        1995 as the College of Health Sciences (formally approved by Senate – 29 March, 1995).</p>
        
    },
    {
        title:'First session',
        text:`
        September 1972 – Foundation students were admitted into the Faculty of Health Sciences
        `
    },
    {
        title:'Degrees awarded',
        text:<ul>
            <h5 className="post sub_heading" style={{textAlign:'left'}}>Undergraduate</h5>
            <li>Dentistry (BCh D)</li>
            <li>Medicine (MB ChB)</li>
            <li>Medical Rehabilitation (B.M.R)</li>
            <li>Nursing Science (B N Sc.)</li>
            <h5 className="post sub_heading" style={{textAlign:'left'}}>Postgraduate</h5>
            <li>MSc/PhD</li>
       
        </ul>
       
    },
    {
        title:'Faculties, institute and departments',
        text:`
        The College of Health Sciences comprises of three faculties and one institute. The three faculties are made up of departments of various medical disciplines while the institute focuses on multidisciplinary public health research and development efforts. In total, the College has 24 departments.
        
        `
    },
    {
        title:<p className='foundation_dean'>Founding Dean</p>,
        text:<div className='foundation_dean'>
        <img src={Grillo} />
        <p className="name foundation_dean">Professor T. A. I. Grillo</p>
        <p>Professor of Anatomy and Cell Biology</p>
        </div>
    },
    {
        links: [
                     {
                         link:'Grillo Lecture',
                         to:'/grillo-lecture'
                     },
                     {
                         link:'History about College',
                         to:'/history'
                     },
                    
                     {
                         link:'FAQs',
                         to:'/faqs'
                     },
         ],
      tags: ["History", "Inception"]
 
     }
]






const History = [
    {
        // title:<span>Current Provost of College of Health Sciences</span>,
        text:<div>
            <div className="image_wrapper">
            <img src={Provost} />
            </div>
            <p className='name'>Prof. B.A. Kolawole</p>
            <p className='desig'>Professor of Endocrinology / Internal Medicine</p>
            <p className='name'><span>Current Provost of College of Health Sciences,OAU</span></p>
           
        </div>
    },
    {
        title:'About the College',
        text:`
        Since inception in 1972, the College of Health Sciences (then Faculty of Health Sciences) has made major contributions to the supply of highly trained medical graduates especially to the Nigerian health sector.

        Formerly instituted in the then University of Ife in 1971 as the Faculty of Health Sciences, the College has expanded into a Collegiate System formally approved by the University’s Senate in 1995 and comprises three Faculties and one Institute.
        
        Over the years, the College has enjoyed the service of senior faculty members who are committed to its core aims. The educational and administrative policy of the College fully embraces the traditional tripod of education, research and service with greater emphasis placed on service. To this end, faculty members have done more than just teaching. They have improved health care through novel roles as scholars and physicians. They have also helped developed new and improved curricula and educational models that address emerging needs in the health sector.
        
        Coupled with affiliation agreement with one of the prominent hospital unit in the country, Obafemi Awolowo University Teaching Hospitals Complex, Ile-Ife (then Ife University Teaching Hospitals Complex), the College has produced over 5000 empathetic medical practitioners, nurses and dentists who are effective health team players and can assume and play leadership roles in health care delivery through the efficient application of their expertise.
        
        The College is undaunted in its aim to train health care personnel who can work as a team, provide comprehensive health care to individuals in any community (urban or rural); and be up-to-date on issues of global health relevance.
        `
    },
    {
        title:'Inception',
        text:`
        With the aim of improving health care delivery, the then Faculty of Health Sciences was formally instituted in the then University of Ife in 1971 after three years of careful planning and in response to the demand of the Government of the then Western State of Nigeria in 1967 for the establishment of a Medical School in the State.

In 1967, a Planning Committee of the University of Ife Senate was constituted. This comprised of representatives from the Federal Ministry of Health, the Ministry of Health of the Western State Government, the Faculties of Medicine of the Universities of Ibadan and Lagos, and the Faculty of Sciences of the University of Ife. The objective of the Committee was to develop an educational programme for the medical school that could primarily address the health needs of the Western State.

While the educational and administrative policy of the Faculty fully embraces the traditional tripod of education, research and service, it places more emphasis on service than is usual among other Nigerian Faculties of medicine.
        `
    },
    {
        title:'Establishment',
        text:`
        An International Working Party comprising representatives of the Inter-University Council and Nigerians who served on the Planning Committee of the University of Ife Senate met in January 1971 to prepare a final report for Senate which recommendations the University Senate endorsed. The Faculty was formally inauguration on the 8 May, 1972 with Professor T. A. I. Grillo, of blessed memory, as its foundation Dean.

			During a meeting in 1971, it was decided that the Faculty’s teaching hospitals would comprise a central primary hospital with a conglomerate of other state hospitals. With time, the clinical training facilities became consolidated in two main hospital – Ife Hospital Unit and Wesley Guild hospital, while community health services and training take place at Comprehensive Health Centre Imesi-Ile, Urban Comprehensive Health Centre, Ile-Ife and Multipurpose Health Centre, Ilesa, with the main dental hospital existing in the College.`
    },
    {
        title:'First fruits',
        text:`
        Foundation students were admitted into the Faculty in September 1972, though a group of prospective Health Sciences students had been admitted for the University Preliminary Courses in the Faculty of Sciences in September 1971.`
    },
    {
        title:'Management and administration',
        text:`
        Foundation students were admitted into the Faculty in September 1972, though a group of prospective Health Sciences students had been admitted for the University Preliminary Courses in the Faculty of Sciences in September 1971.`
    },
    {
       links: [
                    {
                        link:'Grillo Lecture',
                        to:'/grillo-lecture'
                    },
                    {
                        link:'Fact about College',
                        to:'/fact'
                    },
                    {
                        link:'News',
                        to:'/news'
                    },
                    {
                        link:'FAQs',
                        to:'/faqs'
                    },
        ],
     tags: ["History", "CHS", "Establishment", "Inception"]

    }

]


//     const History = [
//     {
//         // title:<span>Current Provost of College of Health Sciences</span>,
//         text:<div>
//             <div className="image_wrapper">
//             <img src={Provost} />
//             </div>
//             <p className='name'>Prof. B.A. Kolawole</p>
//             <p className='desig'>Professor of Endocrinology / Internal Medicine</p>
//             <p className='name'><span>Current Provost of College of Health Sciences</span></p>
           
//         </div>
//     },
//     {
//         title:'About the College',
//         text:`
//         Since inception in 1972, the College of Health Sciences (then Faculty of Health Sciences) has made major contributions to the supply of highly trained medical graduates especially to the Nigerian health sector.

//         Formerly instituted in the then University of Ife in 1971 as the Faculty of Health Sciences, the College has expanded into a Collegiate System formally approved by the University’s Senate in 1995 and comprises three Faculties and one Institute.
        
//         Over the years, the College has enjoyed the service of senior faculty members who are committed to its core aims. The educational and administrative policy of the College fully embraces the traditional tripod of education, research and service with greater emphasis placed on service. To this end, faculty members have done more than just teaching. They have improved health care through novel roles as scholars and physicians. They have also helped developed new and improved curricula and educational models that address emerging needs in the health sector.
        
//         Coupled with affiliation agreement with one of the prominent hospital unit in the country, Obafemi Awolowo University Teaching Hospitals Complex, Ile-Ife (then Ife University Teaching Hospitals Complex), the College has produced over 5000 empathetic medical practitioners, nurses and dentists who are effective health team players and can assume and play leadership roles in health care delivery through the efficient application of their expertise.
        
//         The College is undaunted in its aim to train health care personnel who can work as a team, provide comprehensive health care to individuals in any community (urban or rural); and be up-to-date on issues of global health relevance.
//         `
//     },
//     {
//         title:'Inception',
//         text:`
//         With the aim of improving health care delivery, the then Faculty of Health Sciences was formally instituted in the then University of Ife in 1971 after three years of careful planning and in response to the demand of the Government of the then Western State of Nigeria in 1967 for the establishment of a Medical School in the State.

// In 1967, a Planning Committee of the University of Ife Senate was constituted. This comprised of representatives from the Federal Ministry of Health, the Ministry of Health of the Western State Government, the Faculties of Medicine of the Universities of Ibadan and Lagos, and the Faculty of Sciences of the University of Ife. The objective of the Committee was to develop an educational programme for the medical school that could primarily address the health needs of the Western State.

// While the educational and administrative policy of the Faculty fully embraces the traditional tripod of education, research and service, it places more emphasis on service than is usual among other Nigerian Faculties of medicine.
//         `
//     },
//     {
//         title:'Establishment',
//         text:`
//         An International Working Party comprising representatives of the Inter-University Council and Nigerians who served on the Planning Committee of the University of Ife Senate met in January 1971 to prepare a final report for Senate which recommendations the University Senate endorsed. The Faculty was formally inauguration on the 8 May, 1972 with Professor T. A. I. Grillo, of blessed memory, as its foundation Dean.

// 			During a meeting in 1971, it was decided that the Faculty’s teaching hospitals would comprise a central primary hospital with a conglomerate of other state hospitals. With time, the clinical training facilities became consolidated in two main hospital – Ife Hospital Unit and Wesley Guild hospital, while community health services and training take place at Comprehensive Health Centre Imesi-Ile, Urban Comprehensive Health Centre, Ile-Ife and Multipurpose Health Centre, Ilesa, with the main dental hospital existing in the College.`
//     },
//     {
//         title:'First fruits',
//         text:`
//         Foundation students were admitted into the Faculty in September 1972, though a group of prospective Health Sciences students had been admitted for the University Preliminary Courses in the Faculty of Sciences in September 1971.`
//     },
//     {
//         title:'Management and administration',
//         text:`
//         Foundation students were admitted into the Faculty in September 1972, though a group of prospective Health Sciences students had been admitted for the University Preliminary Courses in the Faculty of Sciences in September 1971.`
//     },
//     {
//        links: [
//                     {
//                         link:'Grillo Lecture',
//                         to:'/grillo-lecture'
//                     },
//                     {
//                         link:'Fact about College',
//                         to:'/fact'
//                     },
//                     {
//                         link:'News',
//                         to:'/news'
//                     },
//                     {
//                         link:'FAQs',
//                         to:'/faqs'
//                     },
//         ],
//      tags: ["History", "CHS", "Establishment", "Inception"]

//     }
//     // [
//     //     {
//     //         link:'Grillo Lecture',
//     //         to:'/grillo-lecture'
//     //     },
//     //     {
//     //         link:'Fact about College',
//     //         to:'/fact'
//     //     },
//     //     {
//     //         link:'News',
//     //         to:'/news'
//     //     },
//     //     {
//     //         link:'FAQs',
//     //         to:'/faqs'
//     //     },
//     //     {
//     //        tags: ["History", "CHS", "Establishment", "Inception"]
//     //     }
//     // ]
  
// ]




const IFUMSA = [
   
    {
        title:'History About IFUMSA',
        text:`
       Ife University Medical Students' Association (now  Obafemi Awolowo University Students' Association), usually abbreviated as IFUMSA is the 
        umbrella body of the Medical Students in the Obafemi Awolowo University. IFUMSA was founded in September 1972,
        by the foundation students of the faculty of the Health Sciences (now College of Health Sciences)
        which was established May 8, 1972. The year 2022 makes it 50 years that association has been in existence.

        We are vibrant student body with over 700 memebers and an associated illustrious Alumni Body.
        The 44 seat IFUMSA Parliament, the leadership of the association popularly known as the Student Representative Body (SRB)
        of IFUMSA, comprises; 10 Executive Counsil members whose activities are coordinated by the President of 
            Association, 30 Students' Representative Body (SRB) members and; 4 Ex-Official members, is chaired
            by the Speaker of the House.
        `
    },
    {
        title:'Know Your Executives',
        text:<div className='student_executives_container'>
                <div className='student_executives_wrapper'>
                        <div className='president_section'>
                                <div className='president_image_wrapper'>
                                    <div data-aos="fade-left" className='president_image'>
                                         
                                        <img src={president} />
                                    </div>
                                   <h2 className='president_name'>Kolade James ADEGOKE </h2>
                                   <p>IFUMSA President</p>

                                </div>
                                <div className='welcome_speech'>
                                    <h2>From the President</h2>
                                    <p>
                                        Content Not Available
                                    </p>
                                </div>
                                
                        </div>

                        <div className='vp_image_wrapper'>
                            <div data-aos="fade-left" data-aos-delay={200} className='vp_image'>
                                <img src={vp} />
                            </div>
                            <div data-aos="fade-right" data-aos-delay={200}>
                            <h2   className='president_name'>Evelyn ATOYEBI </h2>
                            <p>Vice President</p>
                            </div>
                           

                        </div>

                        <div className='other_executives'>
                            <div>
                            <div data-aos="fade-down" data-aos-delay={800}  className='item'>
                                <img src={gen_sec}  />
                                <div>
                                <h2>Olaitan KOMOLAFE </h2>
                                <p>General Secretary</p>
                                </div>
                            </div>
                            <div data-aos="fade-down" data-aos-delay={700} className='item'>
                                <img src={ags}  />
                                <div>
                                <h2>Pelumi SOBAYO </h2>
                                <p>Asst. General Secretary</p>
                                </div>
                            </div>
                            <div data-aos="fade-down" data-aos-delay={600} className='item'>
                                <img src={pro}  />
                                <div>
                                <h2>Daniel BALOGUN </h2>
                                <p>P.R.O.</p>
                                </div>
                            </div>
                            <div data-aos="fade-down" data-aos-delay={500} className='item'>
                                <img src={finsec}  />
                                <div>
                                <h2>Gbeminiyi AJELETI  </h2>
                                <p>Financial Secretary</p>
                                </div>
                            </div>
                            <div data-aos="fade-down" data-aos-delay={400} className='item'>
                                <img src={treasurer}  />
                                <div>
                                <h2>Claire AKWUBA  </h2>
                                <p>Treasurer</p>
                                </div>
                            </div>
                            <div data-aos="fade-down" data-aos-delay={300} className='item'>
                                <img src={sportDir}  />
                                <div>
                                <h2>Victor ADEWUMI </h2>
                                <p>Director of Sports</p>
                                </div>
                            </div>
                            <div data-aos="fade-down" data-aos-delay={200} className='item'>
                                <img src={socialDIr}  />
                                <div>
                                <h2>Olamide OLAYADE</h2>
                                <p>Director of Socials</p>
                                </div>
                            </div>
                            <div data-aos="fade-down" data-aos-delay={100} className='item'>
                                <img src={welfare}  />
                                <div>
                                <h2>Tosin AJEWOLE </h2>
                                <p>Welfare Secretary</p>
                                </div>
                            </div>
                            </div>
                            
                        </div>
                </div>

        </div>
    },
   
    {
       links: [
                    {
                        link:'Grillo Lecture',
                        to:'/grillo-lecture'
                    },
                    {
                        link:'Fact about College',
                        to:'/fact'
                    },
                    {
                        link:'News',
                        to:'/news'
                    },
                    {
                        link:'FAQs',
                        to:'/faqs'
                    },
        ],
     tags: ["History", "CHS", "Establishment", "Inception"]

    }
]
export {Fact,History,IFUMSA}