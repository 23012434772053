import React,{useEffect} from 'react'
import BannerImage from '../../../assets/images/oau-building.jpg'
// import data from '../../../utilities/HistoryContent'
import { History as Data} from '../../../utilities/Data'




import Doc from '../../../assets/doc/history.pdf'
import Layout1 from '../../../components/College/Layout1'
export default function History() {

  useEffect(()=>{
    document.title="College of Health Sciences - History, Obafemi Awolwo University, Ile-Ife "
    window.scrollTo({
      top:0
    })
  },[])
  return (
    <div className='history_container '>
      <Layout1
        title="HISTORY ABOUT COLLEGE"
        image={BannerImage} 
        text="History"
        tag={Data[Data.length-1].tags}
        category={Data[Data.length-1].links} 
    
      >
        <h2>Brief History about College of Health Sciences </h2>
      {
   Data.map((item, index)=>{
     return(
       <div className='text_wrapper'>
           <h5>{item.title}</h5>
           <p>{item.text}</p>
       </div>

       
     )
   })
 }
      </Layout1>
    </div>
  )
}
