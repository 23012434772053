import React from 'react'
import { Routes, Route } from 'react-router-dom'
import History from './pages/History'
// import Home from './Home'
import '../../assets/college.css'
import CollegeNavbar from '../../components/College/CollegeNavbar'
import Home from './pages/Home'
import NoMatch from './pages/NoMatch'
import EventDetails from './pages/EventDetails'
import Fact from './pages/Fact'
import Past_Provost from './pages/Past_Provost'
import Past_Dean from './pages/Past_Dean'
import PopularTag from './pages/PopularTag'
import SearchResult from './pages/SearchResult'
import Academic_Prizes from './pages/Academic_Prizes'
import Academic_Calendar from './pages/Academic_Calendar'
import Courses_Offered from './pages/Courses_Offered'
import Education_Perspective from './pages/Education_Perspective'
import Distance_Learning from './pages/Distance_Learning'
import StudentLife from './pages/StudentLife'
import Staff_Profile from './pages/Staff_Profile'
import ViewAllStaff from './pages/ViewAllStaff'
import News from './pages/News'
import Basic_Medical_Sciences_Staff from './pages/Basic_Medical_Sciences_Staff'
import Clinical_Sciences_Staff from './pages/Clinical_Sciences_Staff'
import Dentistry_Staff from './pages/Dentistry_Staff'
import Iph_staff from './pages/Iph_staff'
import IFUMSA from './pages/IFUMSA'
import Student_Life from './pages/Student_Life'
import Prospective_Student from './pages/Prospective_Student'
import Medical_Illustration from './pages/Medical_Illustration'
import Biomedical_Engineering from './pages/Biomedical_Engineering'
import College_Secretary from './pages/College_Secretary'
import College_Library from './pages/College_Library'
import College_Handbook from './pages/College_Handbook'
import News_Letter from './pages/News_Letter'
import College_Journal from './pages/College_Journal'
import Public_Lecture from './pages/Public_Lecture'
import Grillo_Lecture from './pages/Grillo_Lecture'
import Downloads from './pages/Downloads'
import IFUTSA from './pages/IFUTSA'
import IFUDSA from './pages/IFUDSA'
import NUNSA from './pages/NUNSA'

import Donation from './pages/Donation'
import  Gallery from './pages/Gallery'

import FAQs from './pages/FAQs'
import Privacy_Policy from './pages/Privacy_Policy'
import UndergraduateProgram from './pages/UndergraduateProgram'
import PostgraduateProgram from './pages/PostgraduateProgram'


export default function CollegeRoutes() {
  return (
   
        <Route element={<CollegeNavbar />}>
           <Route index  path='/'  element={<Home />}/>
        <Route  path='/history'  element={<History />}/>
        <Route  path='/fact'  element={<Fact />}/>
        <Route  path='/past-provost'  element={<Past_Provost />}/>
        <Route  path='/past-dean'  element={<Past_Dean />}/>
        <Route  path='event/:id'  element={<EventDetails />}/>
        <Route  path='/academic-prize'  element={<Academic_Prizes />}/>
        <Route  path='/academic-calendar'  element={<Academic_Calendar/>}/>
        <Route  path='/course-offer'  element={<Courses_Offered/>}/>
        <Route  path='/education-perspective'  element={<Education_Perspective/>}/>
        <Route  path='/distance-learning'  element={<Distance_Learning/>}/>
        <Route  path='/std-life'  element={<Student_Life/>}/>
        <Route  path='/pros-std'  element={<Prospective_Student/>}/>
        <Route  path='/all-staff'  element={<ViewAllStaff/>}/>
        <Route  path='staff-profile/:id'  element={<Staff_Profile/>}/>
        <Route  path='news'  element={<News/>}/>
        <Route  path='bms'  element={<Basic_Medical_Sciences_Staff/>}/>
        <Route  path='cls'  element={<Clinical_Sciences_Staff/>}/>
        <Route  path='den'  element={<Dentistry_Staff/>}/>
        <Route  path='iph'  element={<Iph_staff/>}/>
        <Route  path='ifumsa'  element={<IFUMSA/>}/>
        <Route  path='ifutsa'  element={<IFUTSA/>}/>
        <Route  path='ifudsa'  element={<IFUDSA/>}/>
        <Route  path='nunsa'  element={<NUNSA/>}/>
        <Route  path='miu'  element={<Medical_Illustration/>}/>
        <Route  path='bme'  element={<Biomedical_Engineering/>}/>
        <Route  path='chs-sec'  element={<College_Secretary/>}/>
        <Route  path='chs-lib'  element={<College_Library/>}/>
        <Route  path='chs-handbook'  element={<College_Handbook/>}/>
        <Route  path='news-letter'  element={<News_Letter/>}/>
        <Route  path='chs-journal'  element={<College_Journal/>}/>
        <Route  path='public-lecture'  element={<Public_Lecture/>}/>
        <Route  path='grillo-lecture'  element={<Grillo_Lecture/>}/>
        <Route  path='download'  element={<Downloads/>}/>
        <Route  path='donation'  element={<Donation/>}/>
        <Route  path='gallery'  element={<Gallery/>}/>
        <Route  path='faqs'  element={<FAQs/>}/>
        <Route  path='policy'  element={<Privacy_Policy/>}/>
        <Route  path='undergraduate'  element={<UndergraduateProgram/>}/>
        <Route  path='postgraduate'  element={<PostgraduateProgram/>}/>
        <Route  path='distance_learning'  element={<Distance_Learning/>}/>
      

        <Route  path='popular-tag/:id'  element={<PopularTag />}/>
        <Route  path='search-result/:id'  element={<SearchResult />}/>
        {/* <Route  path='*'  element={<NoMatch />}/> */}

        </Route>

    
  )
}
