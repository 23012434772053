import DenDean1 from '../assets/images/past dean/dentistry/img9.jpg'
import DenDean2 from '../assets/images/past dean/dentistry/img10.jpg'
import DenDean3 from '../assets/images/past dean/dentistry/img7.jpg'
import DenDean4 from '../assets/images/past dean/dentistry/img1.jpg'
import DenDean5 from '../assets/images/past dean/dentistry/img3.jpg'
import DenDean6 from '../assets/images/past dean/dentistry/img2.jpg'
import DenDean7 from '../assets/images/past dean/dentistry/img5.jpg'
import DenDean8 from '../assets/images/past dean/dentistry/img4.jpg'
import DenDean9 from '../assets/images/past dean/dentistry/img6.jpg'
import DenDean10 from '../assets/images/past dean/dentistry/img11.jpg'




let Dentistry = [
    {
        image:DenDean1,
        name:'Prof. N. Hollist ',
        Year:'1983 - 1992',
        status:'Sub Dean'
     
    },
    {
        image:DenDean2,
        name:'Prof. S.A. Odunsanya ',
        Year:'1992 - 1995',
      
    },
    {
        image:DenDean3,
        name:'Prof. A.O Olusile ',
        Year:'1995 - 1999',
      
    },
    {
        image:DenDean4,
        name:'Prof. E.O. Ogunbodede',
        Year:'1999 - 2002',
      
    },
    {
        image:DenDean5,
        name:'Prof. O.D. Otuyemi ',
        Year:'2002 - 2006',
      
    },
    {
        image:DenDean6,
        name:'Dr. O.O. Fagade ',
        Year:'August 2006',
        status:'AG-Dean'
      
    },
    {
        image:DenDean7,
        name:'Prof. A.O. Fatusi',
        Year:'2006 - 2008',
        status:'AG-Dean'
      
    },
    {
        image:DenDean8,
        name:'Prof. V.I. Ugboko',
        Year:'2008 - 2012',
      
    },
    {
        image:DenDean7,
        name:'Prof. A.O. Fatusi',
        Year:'2012 - 2016',
      
    },
    {
        image:DenDean9,
        name:'Prof. F.J. Owotade ',
        Year:'2016 - 2020',
      
    },
    {
        image:DenDean10,
        name:'Prof. C.A. Adekoya Sofowora',
        Year:'2020 - 2021',
        status:'AG-Dean'
      
    },
].reverse()


// let LeftData = [
//     {
//         image:provost1,
//         name:'Prof. T.A.I Grillo ',
//         Year:'1972 - 1976',
//         status:'Founding Dean'
//     },
//     {
//         image:provost2,
//         name:'Prof. M.A. Bankole ',
//         Year:'1976 - 1980',
//         status:'Dean'
//     },
  
//     {
//         image:provost4,
//         name:'Prof. F.O. Adebonojo ',
//         Year:'1980 - 1982',
//         status:'Dean'
//     },
//     {
//         image:provost3,
//         name:'Prof. O.O. Soyinka ',
//         Year:'1982 - 1986',
//         status:'Dean'
//     },
//     {
//         image:provost5,
//         name:'Prof. S.O. Olusi ',
//         Year:'1986 - 1989',
//         status:'Dean'
//     },
//     {
//         image:provost6,
//         name:'Prof. W.O. Odesanmi',
//         Year:'1989 - 1990',
//         status:'Dean'
//     },
//     {
//         image:provost7,
//         name:'Prof. A.O. Adeyemo ',
//         Year:'1990 - 1991',
//         status:'Dean'
//     },
//     {
//         image:provost8,
//         name:'Prof. Kayode Adetugbo',
//         Year:'1991 - 1995'
//     },
//     {
//         image:provost9,
//         name:'Prof. G.A. Oyedeji ',
//         Year:'1995 - 1999'
//     },
//     {
//         image:provost10,
//         name:'Prof. O. Adejuyigbe ',
//         Year:'1999 - 2003'
//     },
//     {
//         image:provost11,
//         name:'Prof. M.O. Balogun',
//         Year:'2003 - 2007'
//     },
//     {
//         image:provost12,
//         name:'Prof. E.O. Ogunbodede ',
//         Year:'2007 - 2009'
//     },
//     {
//         image:provost13,
//         name:'Prof. S.O. Ogunniyi ',
//         Year:'2009 - 2013'
//     },
//     {
//         image:provost14,
//         name:'Prof. A.O. Fatusi',
//         Year:'2013 - 2017'
//     },
//     {
//         image:provost15,
//         name:'Prof. K.T. Ijadunola',
//         Year:'2017 - 2021'
//     },


// ].reverse()

let RightData = [
   
    {
        link:'Past provost',
        to:'/past-provost'
    },
    {
        link:'Past Deputy provost',
        to:'/past-deputy-provost'
    },
   
    {
        link:'Past IPH Director',
        to:'/past-director'
    },
    {
        link:'Past College Secretary',
        to:'/past-chs-secretary'
    },
    {
       tags: ["Past Dean", "Dean"]
    }
]

export {RightData, Dentistry}