import React from 'react'
import Layout1 from '../../../components/College/Layout1'

export default function Biomedical_Engineering() {
  return (
    <div>
      <Layout1 title="Biomedical Engineering Unit" text="Staff">

      </Layout1>
    </div>
  )
}
