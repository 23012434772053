import React,{useEffect} from 'react'
import Layout1 from '../../../components/College/Layout1'
import { IFUMSA as Data} from '../../../utilities/Data'

export default function IFUMSA() {
  useEffect(()=>{
    document.title="College of Health Sciences - IFUMSA, Obafemi Awolwo University, Ile-Ife "
    window.scrollTo({
      top:0
    })
  },[])
  return (
    <div>
      <Layout1 title="Ife University Medical Student Association (IFUMSA)" text="IFUMSA">
      {
   Data.map((item, index)=>{
     return(
       <div className='text_wrapper'>
           <h5>{item.title}</h5>
           <p>{item.text}</p>
       </div>

       
     )
   })
 }
      </Layout1>
    </div>
  )
}
