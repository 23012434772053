import React from 'react'
import Logo from '../../../assets/images/oau-logo.png'
import { Link } from 'react-router-dom'

export default function NoMatch() {
  return (
    
    <div className='error_page_container '>

      <div className="inner_wrapper">
          <div className="box left">
              <div className="img_wrapper">
                <img src={Logo} alt="" />
              </div>
              <h2>College of Health Sciences</h2>
              <h5>Obafemi Awolowo University, Ile-Ife</h5>
          </div>
          <div className="box right">
            <div className="content">

                <h3>Oops! :(</h3>
                <h1>404</h1>
                <h4>
                  The page you were looking for, couldn't be found.
                </h4>
                <p>
                  The page you are looking for might have been removed, had its name changed, or is temporarily unavailable
                </p>

                <div className="btn_wrapper">
                  <Link to="/">Back to Home</Link>
                </div>

            </div>
          </div>
      </div>
       
    </div>
  )
}
