const Links = [
    {
        link:'Staff',
        to:'/staff'
    },
    {
        link:'Academics',
        to:'/academic'
    },
    {
        link:'News',
        to:'/news'
    },
    {
        link:'Grillo Lecture',
        to:'/grillo-lecture'
    },
    {
        link:'News Letter',
        to:'/news-letter'
    },
    {
        link:'Gallery',
        to:'/gallery'
    }
]

export default Links