import React,{useEffect} from 'react'
import Banner from '../../../components/College/Banner'
import Footer from '../../../components/College/Footer'
import BannerImage from '../../../assets/images/oau-building.jpg'
import RightBarSearchBox from '../../../components/College/RightBarSearchBox'
import RightBarCategories from '../../../components/College/RightBarCategories'
import PopularTags from '../../../components/College/PopularTags'
import {RightData, Dentistry} from '../../../utilities/PastDeanContent'




export default function Past_Dean() {
    useEffect(()=>{
        document.title="College of Health Sciences - Past Dean, Obafemi Awolwo University, Ile-Ife "
        window.scrollTo({
          top:0
        })

        let btns = [...document.querySelectorAll('.button_wrapper > ul > li')]
        let contents = [...document.querySelectorAll('.faculty_content_wrapper > .faculty')]
       
        
        let findBtnIndex = btns.findIndex(el=>el.classList.contains('active'))
        let findContentsIndex = contents.findIndex(el=>el.classList.contains('active'))
        
        if(findBtnIndex< 0){
            let findBtnIndex = 0
            btns[findBtnIndex].classList.add('active')
        }
        if(findContentsIndex< 0){
            let findContentsIndex = 0
            contents[findContentsIndex].classList.add('active')
        }
     
        btns.forEach(item=>{
          
          item.addEventListener('click',(e)=>{
           
            let check = btns.find(item=>item.classList.contains('active'))
            let name = e.target.textContent

            if(check){
              check.classList.remove('active')
              e.target.classList.add('active')
            }
           
            if(name == 'Basic Sciences' && e.target.classList.contains('active')){
              
              let check =contents.find(item=>item.classList.contains('active'))
                check.classList.remove('active')
                contents[0].classList.add('active')
      
             
            }
            if(name == 'Clinical Sciences' && e.target.classList.contains('active')){
              let check =contents.find(item=>item.classList.contains('active'))
              check.classList.remove('active')
              contents[1].classList.add('active')
            }
            if(name == 'Dentistry' && e.target.classList.contains('active')){
              let check =contents.find(item=>item.classList.contains('active'))
              check.classList.remove('active')
              contents[2].classList.add('active')
            }
        
          
          })
        }) 
       
       
     
      },[])
  return (
    <div className='past_dean_container chs_top'>
        <Banner image={BannerImage} text="Dean" title="PAST DEAN"/>
      {/* <div className=' container'>
            <div className="button_wrapper">

            </div>
      </div> */}
          <div className=' wrapper'>
          <div className="content_container">
            <div className="content_wrapper container">

            <div className="left_bar">
            <div className="button_wrapper">
                <ul>
                  <li>Basic Sciences</li>
                  <li >Clinical Sciences</li>
                  <li >Dentistry</li>
                </ul>
            </div>

            <div className="faculty_content_wrapper">
                <div className="faculty Basic">
                <h3>Past Dean of Faculty of Basic Sciences</h3>
                <div className="faculty_inner_content_wrapper">
                    <h1>Content Not Available</h1>
                      
                    </div>
                </div>
                <div className="faculty Clinical">
                <h3>Past Dean of Faculty of Clinical Sciences</h3>
                <div className="faculty_inner_content_wrapper">
                    <h1>Content Not Available</h1>
                      
                    </div>
                </div>
                <div className="faculty Dentistry">
                  <h3>Past Dean of Faculty of Dentistry</h3>
                <div className="faculty_inner_content_wrapper">
                  {
                    Dentistry.map((item, index)=><div className="item">
                    <div className="image_wrapper">
                          <img src={item.image} alt="" />
                    </div>
                    <div className="name_wrapper">
                        <p>{item.name}</p>
                        {item.status&&<p>{item.status}</p>}
                        <p>({item.Year})</p>
                    </div>

                  </div>)
                  }
                      
                    </div>
                </div>
            </div>
              
                   
            </div>
             
                  <div className="right_bar">
                      <div className="right_bar_wrapper">
                          <RightBarSearchBox />
                          <RightBarCategories title="Related Links" links={RightData} />
                          <PopularTags link={RightData[RightData.length-1].tags}   />
                      </div>

                  </div>
            </div>
                
            </div>
    </div>
        <Footer />
    </div>
  )
}
