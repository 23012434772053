import React from 'react'
import Layout1 from '../../../components/College/Layout1'

export default function Public_Lecture() {
  return (
    <div>
      <Layout1 title="Public Lecture" text="Lecture">

      </Layout1>
    </div>
  )
}
