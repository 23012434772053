import React from 'react'
import Banner from '../../../components/College/Banner'
import Footer from '../../../components/College/Footer'
import BannerImage from '../../../assets/images/oau-building.jpg'
import { useEffect } from 'react'
import Layout1 from '../../../components/College/Layout1'

export default function Education_Perspective() {
  useEffect(()=>{
    document.title="College of Health Sciences - Education Perspective, Obafemi Awolwo University, Ile-Ife "
    window.scrollTo({
      top:0
    })
  })
  return (
    <div >
            <div>

        <Layout1 title="Education Perpective" text="Education">
        <div className="sub_title_text">
              <h2>Eduction Perspective</h2>
              <p>
        In pursuance of the educational goals and objectives of the College, it is expected that certain specific measurable knowledge, skill, performance, attitude and process objectives are acquired by medical graduates. These are highlighted below.
    </p>

  
             </div>

             <div className="education_perspective">
                <ul>
                    <li>
                      <h3>Self-education</h3>
                        <div>
                        <p>At the end of the clinical course, graduates must demonstrate the following:</p>
                    
                <ol >
                    <li><p>Graduates must appreciates that the medical training does not end with the attainment of a degree, and that they must accept responsibility for continuing self-education</p></li>
                    <li><p>They can consult both printed and electronic literatures, evaluate and form an opinion.</p></li>
                    <li><p>They can collect and analyse data.</p></li>
               
                </ol>
            
                        </div>
                    </li>
                    <li>
                      <h3>Knowledge and skill</h3>
                        <div>
                        <p>The combined intent of the pre-clinical and clinical course expects that graduates exhibit the followings:</p>
                    
                <ol >
                <li ><p>They have the knowledge of the medical culture, with particular reference to the beliefs and practices related to health and disease, including traditional medicine</p></li>
                    <li >

                      <div className="sub_list">
                      <p>They have acquired a definite body of knowledge and skills in the science and practice of medicine. This body of knowledge and skills must include:</p>
                      <ol>
                            <li><p>The normal structure, development and functions of the human body and mind and of families and communities;</p></li>
                            <li><p>The normal interaction between man and his social, biological, psychological and physical environment;</p></li>
                            <li><p>Aberrations imposed in (I) and (II) by disease-entities commonly encountered in the country</p></li>
                            <li><p>Psychomotor skills involved in the management of ailments commonly encountered in the state.</p></li>
                        </ol>
                      </div>

                      </li>

               <li >

               <div className="sub_list">
               <p>They can collect and record complete baseline data in respect of a patient:</p>
                        
                      <ol >
                            <li ><p>By taking a complete history of the patient;</p></li>
                            <li ><p>By conducting a complete physical examination using available contemporary scientific methods of investigations;</p></li>
                        </ol>
                      </div>
                
                    </li>
                    <li ><p>They can correctly evaluate the recorded baseline data</p></li>
                    <li ><p>They can reach an appropriate assessment of the problems by using the methods at their disposal</p></li>
                    <li ><p>They can undertake appropriate measures (promotion, preventive and curative) in the solution of health problems of individuals, families, and communities and to evaluate them according to accepted contemporary methods.</p></li>
               
                </ol>
            
                        </div>
                    </li>

                    <li>
                      <h3>Team work and community responsibility</h3>
                      <div >
                      <p>In line with the College’s aims and objectives, graduates must demonstrate that they can do the following:</p>
                      <ol >
                      <li ><p>Assess the health situation of a community, define the major problems, allocate priorities and formulate plans for dealing with them;</p></li>
                    <li ><p>Organize and direct a health team and operate an effective health care programme;</p></li>
                    <li ><p>Work in or lead a health team;</p></li>
                    <li ><p>Undertake community health education and accept the responsibility for doing this;</p></li>
                    <li ><p>Tailor their activities in respect to the health needs of the community so that they reflect the social, economic, psychological and ecological factors of the area;</p></li>
                    <li ><p>Maintain an objective view of the effectiveness of their work.</p></li>
               
                        </ol>
                      </div>

                      </li>

                      
                    <li>
                      <h3>Attitudes</h3>
                      <div >
                      <p>Each graduate must demonstrate that(s) he accepts the responsibility to:</p>
                      <ol >
                      <li ><p>Assess the health situation of a community, define the major problems, allocate priorities and formulate plans for dealing with them;</p></li>
                      <li ><p>Conduct his/her professional behaviour in accordance with the International Code of Medical Ethics as laid down by the World Medical Association;</p></li>
                    <li ><p>Allow nothing in his private life to reduce his effectiveness as a medical practitioner;</p></li>
                    <li ><p>Regard the health care of all as immensely worthwhile, placing emphasis on the service (s)he can give to individuals and society, rather than on the personal rewards (s)he can gain;</p></li>
                    <li ><p>Respect the dignity of his/her patients as individual human beings, making him/herself available to them and taking full responsibility for their medical care.</p></li>
               
                        </ol>
                      </div>

                      </li>
                </ul>
              </div>
        </Layout1>

        </div>
  </div>
  )
}
