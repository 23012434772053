import React from 'react'
import Layout1 from '../../../components/College/Layout1'

export default function Gallery() {
  return (
    <div>
    <Layout1 title="College Gallery" text="Gallery">

    </Layout1>
  </div>
  )
}
