import React,{useEffect} from 'react'
import Layout1 from '../../../components/College/Layout1'
import {btnToggler} from '../../../utilities/buttonToggler'

export default function UndergraduateProgram() {
  useEffect(()=>{
    document.title="College of Health Sciences - Undergraduate programme, Obafemi Awolwo University, Ile-Ife "
    window.scrollTo({
      top:0
    }) 

    let btns = [...document.querySelectorAll('.button_wrapper > ul > li')]
    let prizeContents = [...document.querySelectorAll('.content_wrapper > ul>li')]
    btnToggler(btns, prizeContents)
  },[])
  return (
    <Layout1 title='Undergraduate Degree Programme' text="Undergraduate">
         <div className='academic_content sub_title_text'>
            <h2>Undergraduate Degree Programmes</h2>
            <p >
            The minimum requirements for admission to the undergraduate degree programme are those for entry to the University. Candidates may be admitted:
            <br />(i)	Through the Joint Matriculation Examination (UME) entrance examination into Part I;
            <br />(ii)	Through the General Certificate of Education Advance Level or its equivalent qualification by direct entry into Part II.

		        </p>
          </div>
        <div className="toggler button_wrapper">
                <ul>
                  <li >Basic Sciences</li>
                  <li >Clinical Sciences</li>
                  <li >Dentistry</li>
                </ul>
            </div>

            <div className="page content_wrapper">
              <ul>

                {/* BASIC SCIENCES */}
                <li>
                  <div className="inner_content">
                    <h3>Undergraduate Degree Programmes into Faculty of Basic Medical  Sciences</h3>
                    <ul>
                      <li className='items'>
                      <h5>Bachelor of Medical Rhabilitation (BRM) Programme</h5>
                      {/* MEDICAL REHABILITATION */}
                    <ul className='sub_items'>
                      <li ><p> <span >Entry Requirement: </span> <br />
                      
                        <ul className='' style={{listStyleType:'lower-roman', marginTop:'0.8rem'}}>
                              <li style={{ marginBottom:'0.8rem'}}><p> <span >Admission Into Part I BMR Programme</span> <br />
                              
                              Candidates seeking admission into part I must satisfy the University minimum requirements of five credits at the Ordinary Level, which must include mathematics, Physics, Biology, Chemistry and English Language.
                              </p>
                            
                              </li>
                              <li style={{ marginBottom:'0.8rem'}}><p> <span >Admission into Part II BMR. Programme (Direct Entry)</span> <br />
                              
                              For direct entry admission into part II, in addition to the University minimum requirements for admission, canddates must possess the GCE Advanced Level Certificate or its equivalent, with a reasonable number of points. The qualifying subjects must include Pass in Biology, Physics and one other Science subject.
                              </p>
                            
                              </li>
                              </ul>
                      </p>
                      
                      
                      
                      </li>
                      <li><p> <span >Award of Degree</span> <br />
                      The degree shall be awarded to candidates who have satisfactorily completed all requirements for the degree of Bachelor of Medical Rehabilitation or Bachelor of Occupational Therapy  and have also complied with the general regulations of the university and the additional requirements of the BMR. degree programme. The following grades of degree will be applied to the result

                        <br /><span>A-	70% - 100%  - Pass with Distinction (First Class)</span>
                        <br /><span> B-	60% -69%     - Pass with Credit (Second class upper division) </span>
                        <br /><span>C-	50% - 59%    - Pass (Second class lower division)</span>

                       
                       </p>
              
                       </li>
                      <li><p> <span >Exit from the Programme</span> <br />
                      The exit programme option is to provide guidance and support to students who are unable to continue with the BNSc programme either due to lack of interest or poor performance. A three-man committee of the Faculty will study the result of all BNSc students from their first year through final year and provide guidance and support to those who are not coping academically. The committee will also facilitate the transfer of candidates who cannot cope from the Faculty to other Faculties in the University. 
                       
                       </p>
              
                       </li>
                      <li><p> <span >Transfer within the University and Length of Stay in the University</span> <br />
                      Candidates who are advised to withdraw from the BMR degree programme will be able to transfer to another department within the university, provided he/she fulfils the admission criteria to such a department. The duration of the degree programme is 5 (five) years including the preliminary year. However, the maximum allowable duration for the BMR degree programme is seven and half years, one and a half times the duration allowed for the programme.
                       </p>
              

              <br />
                    <a href='https://mrh.oauife.edu.ng/' target='_blank'>Visit their website</a>
                       </li>
                    </ul>
                      </li>


{/* NURSING */}
                      <li className='items'>
                      <h5>Bachelor of Nursing Science (B.N.Sc.) Programme</h5>
                      <ul className='sub_items'>
                      <li ><p> <span >Entry Requirement: </span> <br />
                      
                        <ul className='' style={{listStyleType:'lower-roman', marginTop:'0.8rem'}}>
                              <li style={{ marginBottom:'0.8rem'}}><p> <span >Admission into Part 1 B.N.Sc. Programme</span> <br />
                              
                              Candidates may be considered for admission into Part 1 BNSc degree programme after passing the University Matriculation Examionation (UME) of the Joint Admission and Matriculation Board (JAMB) and the University screening examination or any other examination as prescribed by the university authority. They are also to obtain a pass in the Senior Secondary School Certificate Examination (SSCE) of the West African Examination Council (WAEC) of the National Examination Council (NECO) or its approved equivalent at credit level in at least 5 subjects, including English language, Mathematics, Chemistry, Biology and Physics in not more tgan two sittings.
                              </p>
                            
                              </li>
                              <li style={{ marginBottom:'0.8rem'}}><p> <span >Admission into Part II B.N.Sc. Programme (Direct Entry)</span> <br />
                              Candidates for admission into Part II BNSc Programme by direct entry are those exempted from Part I BNSc courses and are in the following categories: 
                             
                                <br />i.	Candidates who have satisfied the SSCE for admission into this university (i.e. five credit passes as stated in A above, and who in addition obtain passes in at least three science subjects (with aggregare score not below 10) including Biology/Zoology, Chemistry and Physics at the Advanced General Certificate of Education or at any other equivalent examinations approved by the Senate of the Obafemi Awolowo University.

                                <br />ii.	Candidates who are holders of the Registered Nurse (RN) or Rehistered Midwife (RM) Certificates of the Nursing and Midwifery Council of Nigeria, or its equivalent  as approved by the Senate of the Obafemi Awolowo University who have passed at the required credit level in the SSCE of WAEC or NECO as stated in A above  

                                <br />iii.	Candidates who have successfully completed a BSc degree course in any biological science based programme in this or any other university, with a minimum of second class lower division may be considered for admission into the BNSc degree, but must have passed at the required credit level in the SSCE of WAEC or NECO as stated in A above

                              </p>
                            
                              </li>
                              </ul>
                      </p>
                      
                      
                      
                      </li>
                      <li><p> <span >Award of Degree</span> <br />
                      The degree shall be awarded to candidates who have satisfactorily completed all requirements for the degree of Bachelor of Nursing Science and have also complied with the general regulations of the university and the additional requirements of the BNSc degree programme. The following grades of degree will be applied to the result
                        <br /><span>A-	70% - 100%  - Pass with Distinction (First Class)</span>
                        <br /><span> B-	60% -69%     - Pass with Credit (Second class upper division) </span>
                        <br /><span>C-	50% - 59%    - Pass (Second class lower division)</span>

                       
                       </p>
              
                       </li>
                      <li><p> <span >Exit from the Programme</span> <br />
                      The exit programme option is to provide guidance and support to students who are unable to continue with the BNSc programme either due to lack of interest or poor performance. A three-man committee of the Faculty will study the result of all BNSc students from their first year through final year and provide guidance and support to those who are not coping academically. The committee will also facilitate the transfer of candidates who cannot cope from the Faculty to other Faculties in the University. 
                       
                       </p>
              
                       </li>
                      <li><p> <span >Transfer from other Faculties of this university or other universities</span> <br />
                   i.	Candidates who have passed the SSCE as stated in A above and are willing ro transfer from a science-based programme in this university must have a CGPA of not less than 3.5. Such candidate who may be admitted into part II BNSc programme must have passed all the required courses for Part I BNSc programme.
                    <br />ii.	Candidate can only transfer into Part II BNSc programme from NUC accredited university withi and outside Nigeria after satisfying the requirements as in A and 2(i) above.

                       </p>
              

              <br />
                    <a href='http://nursing.oauife.edu.ng/' target='_blank'>Visit their website</a>
                       </li>
                    </ul>
                      </li>
                    </ul>
                  
                  </div>
                </li>

                {/* CLINICAL SCIENCES */}
                <li>
                   <div className="inner_content">
                    <h3>Undergraduate Degree Programme into Faculty of Clinical  Sciences</h3>
                    <ul>
                      <li className='items'>
                      <h5>Bachelor of Medicine, Bachelor of Surgery (M.B., Ch.B.) Programme</h5>
                     
                    <ul className='sub_items'>
                      <li ><p> <span >Entry Requirement: </span> <br />
                      
                        <ul className='' style={{listStyleType:'lower-roman', marginTop:'0.8rem'}}>
                              <li style={{ marginBottom:'0.8rem'}}><p> <span >Admission Into Part I M.B., Ch.B. Programme</span> <br />
                              
                              1. The minimum requirements for admission to courses leading to the award of the M.B.Ch.B. degree are those for entry into the Obafemi Awolowo University Ile-Ife. The qualifying subjects must include credits in Physics, Chemistry, Biology, Mathematics and English Language in the West African School Certificate Examinations (WASCE) or National Examination Council (NECO) or equivalent; <span>provided all the subjects are taken and passed at a single sitting.</span>  
                             <br /><br /> 2. Candidates may be considered for admission into part one (pre-professional) of M.B.Ch.B. degree programme after passing the prerequisite subjects (English Language, Biology, Chemistry and Physics) in the Unified Tertiary Matriculation Examination (UTME) of the Joint Admission and Matriculation Board (JAMB) or any other examination that the University Senate may approve for the purpose. The College shall determine the appropriate minimum merit UTME marks for admission into the programme. 
                             
                              </p>
                            
                              </li>
                              <li style={{ marginBottom:'0.8rem'}}><p> <span >Admission into Part II M.B., Ch.B. Programme (Direct Entry)</span> <br />
                              
                              3. Candidates who seek direct admission into year two of the M.B.Ch.B. degree programme must satisfy the admission requirements of the University, and must, in addition, in the GCE Advanced Level examination or Joint University Preliminary Examination Board (JUPEB) have a minimum aggregate score of 12 and a minimum grade of C in all the subjects. The grades must be obtained in Chemistry, Physics, and Biology (or Zoology) at not more than one sitting. Candidate with First or Second Class Upper Degree in the Basic Medical Sciences, Biological, or Pharmaceutical Sciences, with a minimum average score of 60% from a recognized University may also be admitted directly into the Year Two M.B.Ch.B. degree programme. In either case, an interview for admission may be required. 
                              
                              <br /><br />4. Candidates who wish to transfer to the M.B. Ch.B. programme from other related Faculties must have a minimum GPA of 3.5 to be considered. In this case the candidate must meet the basic admission requirement in (1) above and must have taken courses in the first year similar to courses required for Year 1 medical students. 
                             
                             <br /><br />
                             <span>iii. Admission to Holders of the B.Ch.D degree of this university or its equivalent:</span> <br />
                             5. Holders of Dental (B.Ch.D) degree may be admitted into the 5th year of the M.B.Ch.B. Programme however:   

                             <br />i. Graduates of the Faculty of Dentistry, College of Health Sciences, Obafemi Awolowo University Ile-Ife with the B.Ch.D degree shall be given admission priority; 

                             <br />ii.(a). The College Admission Committee may consider graduates of Dentistry from any other University with similar programme to that of Obafemi Awolowo University; 

                             <br />(b). Admission will be determined based on the transcript of the graduates in ii (a) above. 

                             <br />iii. The College Admission Committee shall adjudge candidates in i or ii above capable of completing the training; Only candidates whose average scores are 55 percent and above and who did not fail any course or repeated any class.

                             
                              </p>
                            
                              </li>
                              </ul>
                      </p>
                      
                      
                      
                      </li>
                      <li><p> <span >Award of Degree</span> <br />
                      The M.B.Ch.B. (Bachelor of Medicine, Bachelor of Surgery) degree may be awarded to a candidate who has successfully completed the prescribed courses and passed the required examinations for the degree programme. The degree may be awarded as M.B.Ch.B. with Honours, to any candidate, who having passed all examinations in the first attempt, obtains Distinction Grades (70 percent or more) in at least three of the following core subjects; one of which must be in the examinations in each phase of the programme. (These core subjects are Anatomy, Physiology, and Biochemistry, Human Pathology, Clinical Pharmacology and Therapeutics, Obstetrics and Gynaecology, Paediatrics, Internal Medicine, Community Health and Surgery). While those with MBChB with Honours graduate with Pass with Honours, those that do not fulfill MBChB with Honours, graduate with Pass. There is no classification into first Class, Second Class or Third Class. These peculiarities of MBChB degree should be noted.  
                       
                       </p>
              
                       </li>
                      <li><p> <span >Exit from the Programme</span> <br />
                      
                      The exit programme is to provide guidance and support to students who are unable to continue with the M.B.Ch.B programme either due to lack of interest or poor performance. A three-man committee of the Faculty will study the result of all M.B.Ch. B students from their first year through final year and provide guidance and support to those who are not coping academically. The committee will also facilitate the transfer of candidates who cannot cope from the Faculty to other Faculties in the University. The establishment of single subject honours courses in the Basic Medical Sciences (such as Human Anatomy, Physiology,) where the students can spend 12-18 months after the first professional examination will ease the pain of withdrawals.
                       </p>
              
                       </li>
                      <li><p> <span >Transfer within the University and Length of Stay in the University</span> <br />
                      Candidates who are advised to withdraw from the MBChB degree programme will be able to transfer to another department within the university. The duration of the degree programme is 6 (six) years, including the preliminary year. However, the maximum allowable duration for the MBChB degree programme is nine years, i.e. one and a half times the normal duration.
                       </p>
              

              <br />
                    <a href='https://fcs.oauife.edu.ng/' target='_blank'>Visit their website</a>
                       </li>
                    </ul>
                      </li>



                    </ul>
                    </div>
                    
                  </ li>

                {/* DENTISTRY */}
                <li>
                <div className="inner_content">
                    <h3>Undergraduate Degree Programme into Faculty of Dentistry</h3>
                    <ul>
                      <li className='items'>
                      <h5>Bachelor of Dental Surgery (B.Ch.D) Programme</h5>
                     
                    <ul className='sub_items'>
                      <li ><p> <span >Entry Requirement: </span> <br />
                      
                        <ul className='' style={{listStyleType:'lower-roman', marginTop:'0.8rem'}}>
                              <li style={{ marginBottom:'0.8rem'}}><p> <span >Admission Into Part I B.Ch.D Programme</span> <br />
                              
                              Candidates may be considered for admission to Part I BChD degree course after passing at the required level of the Joint Admission Matriculation examination. Such candidates must have obtained a pass in the Senior School Certificate Examination or the General Certificate of Education (SSCE/GCE) at credit level in at least five subjects including English Language, Mathematics, Chemistry, Biology and Physics, at <span>not more than one sitting.</span> 
                              </p>
                            
                              </li>
                              <li style={{ marginBottom:'0.8rem'}}><p> <span >Admission into Part II B.Ch.D Programme (Direct Entry)</span> <br />
                              
                              Candidates seeking direct admission into Year 2 of BChD degree programmes must satisfy the matriculation requirements of the university, and must in addition have good Advanced Level passes in the subjects Chemistry, Physics and Biology (or Zoology) in the General Certificate of Education, <span>in not more than two sittings</span>; or must have qualification that the Faculty Board of Dentistry has assessed to be equivalent.  A candidate may also be considered for direct admission to the BChD programmes after successful completion of a B.Sc. degree course in this or other university.  Such candidates may be required to satisfy the admissions committee in an interview.
                             <br /><br />
                             <span>iii. Admission to Holders of the M.B., Ch.B. degree of this university or its equivalent:</span> <br />
                             Holders of the MBChB degree of this or other university are encouraged to work towards a double qualification.  Exemption from the appropriate BChD courses will be allowed.  Such candidates will in addition have to satisfy the admission committee after an interview.
                             
                              </p>
                            
                              </li>
                              </ul>
                      </p>
                      
                      
                      
                      </li>
                      <li><p> <span >Award of Degree</span> <br />
                      The B.Ch.D (Bachelor of Dental Surgery) degree may be awarded to candidates who have successfully completed all the prescribed courses and passed all the examinations for all the required phases of the programme. The degree may be awarded as B.Ch.D with Honours to any candidate who, having passed all examinations in the first attempt, obtains Distinction Grades (70 percent or more) in at least three core courses one of which must be in preclinical examinations and two in the clinical examinations. 
                       
                       </p>
              
                       </li>
                   
              <br />
                    <a href='https://dentistry.oauife.edu.ng/' target='_blank'>Visit their website</a>
                    </ul>
                      </li>



                    </ul>
                    </div>
                </li>
              </ul>
            </div>
</Layout1>
  )
}
