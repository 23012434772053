import React from 'react'
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode,Autoplay, Pagination,Navigation } from "swiper";
import Data from '../utilities/StaffGallery';

import {SlSocialFacebook} from 'react-icons/sl'
import {SiResearchgate} from 'react-icons/si'
import {RxTwitterLogo} from 'react-icons/rx'
import {HiArrowLongRight} from 'react-icons/hi2'
import { Link } from 'react-router-dom';




export default function StaffPics() {
  return (
    <div className='staff_pic_container'>
          <div className="staff_pic_wrapper container">
          <h3 className='heading_1 '>Our Academic Staff</h3> 
 
    <p className='sub_heading'>
    Our instructors are highly experienced and knowledgeable professionals who are committed to helping you reach your educational goals.
       </p>
    {/* <p className='sub_heading'>
       Connect with the best in your field
       </p> */}
            <Swiper 
        slidesPerView={1}
        spaceBetween={20}
        slidesPerGroup={1}
        // freeMode={true}
        loop={true}
        // centeredSlides={true}
        loopFillGroupWithBlank={true}
        pagination={{
          clickable: true,
        }}
        autoplay={{
            delay: 2500,
            // delay: 4500,
            disableOnInteraction: false,
          }}
        breakpoints={{
            640: {
              slidesPerView: 2,
              spaceBetween: 25,
            },
            768: {
            //   slidesPerView: 4,
            //   spaceBetween: 40,
              slidesPerView: 2,
              spaceBetween: 25,
            },
            1024: {
              slidesPerView: 4,
              spaceBetween: 10,
            },
            1280: {
              slidesPerView: 5,
              spaceBetween: 15,
            },
          }}
          navigation={true}
          modules={[Autoplay,Pagination,FreeMode, Navigation]}
        // modules={[FreeMode, Pagination]}
        className="mySwiper"
      >

        {
          Data.map((item, index)=>  <SwiperSlide>
          <div className="wrapper">
            <div className="image_staff_wrapper">
                  <img src={item.image} alt="" />
            </div>
            <div className="text">
                  {/* <Link to={`staff-profile/${item.link}`}>{item.name}</Link> */}
                  <Link to="#">{item.name}</Link>
                  <p>{item.dept}</p>
                  <div>
                    <a>
                      <SlSocialFacebook  />
                    </a>
                    <a>
                      <SiResearchgate size={18} />
                    </a>
                    <a>
                      <RxTwitterLogo />
                    </a>
                  </div>
            </div>
              
          </div>

    </SwiperSlide>)
        }
        
        <div className="view-all">
                 {/* <Link to="all-staff"><p>View all <span><HiArrowLongRight /></span></p></Link>  */}
                 <Link to="#"><p>View all <span><HiArrowLongRight /></span></p></Link> 
        </div>
       
      </Swiper>
      
          </div>
          
    </div>
  )
}
