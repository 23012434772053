// FACULTY OF BASIC MEDICAL STAFFS
import bmsImg1 from '../assets/images/staff gallery/non-academic/bms/secretary_bms.jpg'
import bmsImg2 from '../assets/images/staff gallery/non-academic/bms/bmsStaff1.jpg'
import bmsImg3 from '../assets/images/staff gallery/non-academic/bms/bmsStaff2.jpg'
import bmsImg4 from '../assets/images/staff gallery/non-academic/bms/bmsStaff3.jpg'
import bmsImg5 from '../assets/images/staff gallery/non-academic/bms/bmsStaff4.jpg'
import bmsImg6 from '../assets/images/staff gallery/non-academic/bms/bmsStaff5.jpg'
import bmsImg7 from '../assets/images/staff gallery/non-academic/bms/bmsStaff6.jpg'
import bmsImg8 from '../assets/images/staff gallery/non-academic/bms/bmsStaff7.jpg'
import bmsImg9 from '../assets/images/staff gallery/non-academic/bms/bmsStaff8.jpg'



// FACULTY OF CLINICAL STAFFS
import cliImg1 from '../assets/images/staff gallery/non-academic/clinical/cliImg1.jpg'
import cliImg2 from '../assets/images/staff gallery/non-academic/clinical/cliImg2.jpg'
import cliImg3 from '../assets/images/staff gallery/non-academic/clinical/cliImg3.jpg'
import cliImg4 from '../assets/images/staff gallery/non-academic/clinical/cliImg4.jpg'
import cliImg5 from '../assets/images/staff gallery/non-academic/clinical/cliImg5.jpg'
import cliImg6 from '../assets/images/staff gallery/non-academic/clinical/cliImg6.jpg'
import cliImg7 from '../assets/images/staff gallery/non-academic/clinical/cliImg7.jpg'
import cliImg8 from '../assets/images/staff gallery/non-academic/clinical/cliImg8.jpg'




import shuffler from './Shuffler'

const bmsNonAcademicStaff = shuffler([
    {
        name:'Mr. A.A. Omogbhein',
        image:bmsImg1,
        dept:'',
        socialLinks:'',
        phone:'',
        desig:'Faculty Secretary',
        faculty:'Basic Medical Sciences',
   
    },
    {
        name:'Mrs. M.S. Esan',
        image:bmsImg2,
        dept:'',
        socialLinks:'',
        phone:'',
        desig:'Chief Confidential Secretary',
        faculty:'Basic Medical Sciences',
   
    },
    {
        name:'Mrs. P.F. Olubanjo',
        image:bmsImg3,
        dept:'',
        socialLinks:'',
        phone:'',
        desig:'Chief Secritariat Asst.',
        faculty:'Basic Medical Sciences',
   
    },
    {
        name:'Mr. M.K. Fasanya',
        image:bmsImg4,
        dept:'',
        socialLinks:'',
        phone:'',
        desig:'Chief Medical Lab Scientist',
        faculty:'Basic Medical Sciences',
   
    },
    {
        name:'Mrs. E.M. Kolawole',
        image:bmsImg5,
        dept:'',
        socialLinks:'',
        phone:'',
        desig:'Chief Cleric Officer',
        faculty:'Basic Medical Sciences',
   
    },
   
    {
        name:'Mrs. D.O. Ibironke',
        image:bmsImg6,
        dept:'Medical Microbiology & Parasitology',
        socialLinks:'',
        phone:'',
        desig:'Higher Executive Officer',
        faculty:'Basic Medical Sciences',
   
    },
    {
        name:'Mrs. E.A. Ebemidayo',
        image:bmsImg7,
        dept:'Medical Microbiology & Parasitology',
        socialLinks:'',
        phone:'',
        desig:'Chief Secretariat Asst.',
        faculty:'Basic Medical Sciences',
   
    },
   
    {
        name:'Mrs. B.E. Boboye',
        image:bmsImg8,
        dept:'',
        socialLinks:'',
        phone:'',
        desig:'Cheif Secretariat Asst.',
        faculty:'Basic Medical Sciences',
   
    },
    {
        name:'Mrs. Hassan Olajokun',
        image:bmsImg9,
        dept:'',
        socialLinks:'',
        phone:'',
        desig:'Deputy Technologist',
        faculty:'Basic Medical Sciences',
   
    },
   
])












// FACULTY OF CLINICAL SCIENCES STAFFS

const cliNonAcademicStaff = [
    {
        name:'Mrs. O.L. Olayeni',
        image:cliImg1,
        dept:'',
        socialLinks:'',
        phone:'',
        desig:'Admin Officer I',
        faculty:'Clinical Sciences',
   
    },
    {
        name:'Mrs. V.O. Ojo',
        image:cliImg2,
        dept:'',
        socialLinks:'',
        phone:'',
        desig:'Secretary to Dean',
        faculty:'Clinical Sciences',
   
    },
    {
        name:'Mrs. F.A. Adeoti',
        image:cliImg3,
        dept:'',
        socialLinks:'',
        phone:'',
        desig:'Registry Staff',
        faculty:'Clinical Sciences',
   
    },
    {
        name:'Mr. M.T. Adeniyi',
        image:cliImg4,
        dept:'',
        socialLinks:'',
        phone:'',
        desig:'Registry',
        faculty:'Clinical Sciences',
   
    },
    {
        name:'Mr. M.I. Kolawole',
        image:cliImg5,
        dept:'',
        socialLinks:'',
        phone:'',
        desig:'Higher Excutive Officer',
        faculty:'Clinical Sciences',
   
    },
    {
        name:'Mrs. D.O. Ogunbiyi',
        image:cliImg6,
        dept:'',
        socialLinks:'',
        phone:'',
        desig:'Higher Excutive Officer',
        faculty:'Clinical Sciences',
   
    },
    {
        name:'Mr. M.T. Akinrodoye',
        image:cliImg7,
        dept:'',
        socialLinks:'',
        phone:'',
        desig:'Chief Clerical Officer',
        faculty:'Clinical Sciences',
   
    },
    {
        name:'Mrs. R.A. Baruwa',
        image:cliImg8,
        dept:'',
        socialLinks:'',
        phone:'',
        desig:'Principal Confidential Secretary',
        faculty:'Clinical Sciences',
   
    },
]






// FACULTY OF DENTISTRY STAFFS


const denNonAcademicStaff = [
    
]




export {bmsNonAcademicStaff, cliNonAcademicStaff, denNonAcademicStaff }

