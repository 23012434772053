import React from 'react'
import { Link } from 'react-router-dom'
import MiniHeaderBar from './MiniHeaderBar'


export default function RightBarCategories({title, links}) {
  return (
    <div className='right_bar_categories_wrapper'>
        <MiniHeaderBar text={title} />
{
  links? <ul>
     {
      links.map((item, index)=><li><Link to={item.to}>{item.link}</Link></li>)
     } 
            

    </ul> : <p>No Content Available</p>
}
        
            {/* {
              links?  links.map((item, index)=><li><Link to={item.to}>{item.link}</Link></li>)
            } */}
           
      

    </div>
  )
}
