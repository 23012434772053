import React from 'react'
import {FiPhone} from 'react-icons/fi'
import {AiOutlineMail} from 'react-icons/ai'
import {FaFacebookF} from 'react-icons/fa'
import {IoLogoTwitter} from 'react-icons/io'
import {AiOutlineInstagram} from 'react-icons/ai'
import {FaPinterestP} from 'react-icons/fa'
import {FaLinkedinIn} from 'react-icons/fa'
import {BsYoutube} from 'react-icons/bs'
import { Link } from 'react-router-dom'
export default function CollegeTopNavBar() {
  return (
    <div className="chs_top_navbar">
        <div className="container">
        <div className="left_links">
      <ul>
        <li> <FiPhone color='#fff' /> &nbsp; +2349034897594</li>
        <li><AiOutlineMail color="#fff" /> &nbsp;provostchs@oauife.edu.ng</li>
      </ul>
    </div>
    <div className="right_links">
    <ul>
        <li><a href="https://www.facebook.com/profile.php?id=100083298822865" target="_blank"><FaFacebookF /></a></li>
        <li><a href="https://twitter.com/chsoau" target="_blank"><IoLogoTwitter /></a></li>
        <li><a href="https://www.instagram.com/chsoau/" target="_blank"><AiOutlineInstagram /></a></li>
        <li><a href="#"><BsYoutube/></a></li>
        <li><a href="#"><FaPinterestP /></a></li>
        <li><a href="#"><FaLinkedinIn /></a></li>
        
      </ul>
    </div>
        </div>
    


  </div>
  )
}
