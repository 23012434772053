import React from 'react'
import Banner from '../../../components/College/Banner'
import Footer from '../../../components/College/Footer'
import BannerImage from '../../../assets/images/oau-building.jpg'
import { useEffect } from 'react'
import Layout1 from '../../../components/College/Layout1'
export default function Distance_Learning() {
  useEffect(()=>{
    document.title="College of Health Sciences - Education Perspective, Obafemi Awolwo University, Ile-Ife "
    window.scrollTo({
      top:0
    })
  })
  return (
    <div>
    <Layout1 title='Distance Learning' text="Learning">
        No Content Available Yet
    </Layout1>
    </div>
  )
}
