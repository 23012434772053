import React,{useEffect} from 'react'
import Video from '../../assets/videos/vid.mp4'
import ReactPlayer from 'react-player'

export default function HeroSection() {
    useEffect(()=>{
        let video = document.querySelector('video')
        video.addEventListener('loadedmetadata', function() {
            // this.currentTime =95;
            this.currentTime =20;
          }, true);
          window.addEventListener('scroll', ()=>{
            // const vid = document.querySelector('video')
            const vid = document.querySelector('.hero_text_wrapper')
            let val = window.scrollY
            vid.style.left = -val * 0.02 + "px"
            // vid.style.left = val * 1 + "px"
            // console.log(val)
          })
    },[])
  return (
    <div className='herosection_container'>

<div className="video_container">
<div className="video_wrapper">
<video loop muted  autoPlay={true}  >
                  <source src={Video} type="video/mp4" />
                </video>
</div>
</div>

<div className="hero_text_container">
<div className="hero_text_wrapper">
    <h4>College of Health Science,OAU</h4>
    {/* <p>Obafemi Awolowo University, Ile-Ife.</p> */}
    <p>Learn. Lead.  Succeed.</p>
    {/* <p>
    Here, you will find the latest news and information about the field, as well as resources and tools to help you further your education and career in college health sciences, OAU. We are dedicated to helping you reach your full potential in health sciences.
    </p> */}
</div>

</div>
       
        {/* <div className="video-container">
          <div className="video-wrapper">
              <video loop muted autoPlay >
                  <source src={Video} type="video/mp4" />
                </video>
          </div>
        </div> */}
           
           
    </div>
  )
}
